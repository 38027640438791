import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router";
import Loader from "react-js-loader";
// import categoryAction from "../../actions/categoryAction";
import regionAction from "../../actions/regionAction";
import vendorFavUnfavAction from "../../actions/vendorFavUnfavAction";
import vendorReelsAction from "../../actions/vendorReelsAction";
import Header from "../includes/header";
import Footer from "../includes/footer";
import { toast } from "react-toastify";
import { Waypoint } from "react-waypoint";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Sharethis from "../../components/ShareThis";
import ShowMoreText from "react-show-more-text";
// import searchVendorReelsAction from "../../actions/searchVendorReelsAction";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { getDatabase, ref, onValue } from "firebase/database";
import headerAction from "../../actions/headerAction";

const HomePage = () => {

  let timeoutId;
  const [categoryList, setCategoryList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [vendorsReel, setVendorsReel] = useState([]);
  const [vendorsReelAllData, setVendorsReelAllData] = useState([]);
  const [vendorsReelLabelLink, setVendorsReelLabelLink] = useState([]);
  const [likeDislike, setLikeDislike] = useState("");
  const [flag, setFlag] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [searchName, setSearchName] = useState("");
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);

  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [reportModalOpen2, setReportModalOpen2] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [openShareBox, setOpenShareBox] = useState(false);
  const [popPosition, setPopPosition] = useState({ top: 0, left: 0 });
  const [sharePopIndex, setSharePopIndex] = useState(null);
  const [shareReportIndex, setShareReportIndex] = useState(null);
  const [reelCountData, setReelCountData] = useState({});
  const [category_Id, setCategory_Id] = useState(null)
  const [unique_Id, setUnique_Id] = useState('')
  const homePageScrollTop = useRef(null);

  const isMobile = require("is-mobile");

  const fetchIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      const ipAddress = response.data.ip;
      localStorage.setItem("ipAddress", ipAddress);
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  const ipAddress = localStorage.getItem("ipAddress");
  useEffect(() => {
    if (Object.keys(reelCountData).length > 0) {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/api/submit-video-view`,
          reelCountData
        )
        .then((response) => {})
        .catch((err) => {});
    }
  }, [reelCountData]);

  function handleWaypointEnter(e, reel, index) {
    if (ipAddress) {
      let obj = {
        post_id: reel.id,
        type: "reel",
        ip_address: ipAddress,
        vendor_id: reel.vendor_id,
      };
      setReelCountData(obj);
    } else {
      fetchIpAddress();
    }
  }

  // const handleVideoEnded = (index) => {
  //     console.log('index===========', index);
  //     setPreviousVideoIndex(currentVideoIndex);
  //     setCurrentVideoIndex(index + 1);
  // };
  // when user clicks on number this function will exeuniqueIdscute
  // const paginate = (number) => {
  //     setCurrentPage(number);
  // };
  const handlePageChange = (e) => {
    clearTimeout(timeoutId);
    setFlag(true);
    const newPage = e.selected + 1;
    const requestData = {
      category_id: parseInt(categoryId) || undefined,
      region_id: locationId || undefined,
      search_name: searchName || undefined,
      uniqueIds: unique_Id
    };
    setLoading(true); 
    if (userInfo) {
      dispatch(vendorReelsAction(`/search-reel-with-auth?page=${newPage}`, requestData));
    } else {
      dispatch(vendorReelsAction(`/search-reel?page=${newPage}`, requestData));
    }
    setCurrentPage(newPage);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    // dispatch(categoryAction());
    dispatch(regionAction());
    getReportList();
  }, []);

  // set cat id in local for remove selected category at home
  useEffect(() => {
    const cat_item = window.localStorage.getItem('cat_item');
    getCategory(cat_item);
   
    setTimeout(() => {
      window.localStorage.removeItem('cat_item');
    }, 3000);

    return()=>{
      dispatch(headerAction(false));
    }
  }, []);

  // Category list start
  const category = useSelector((state) => state.category.category);
  const headerData = useSelector((state) => state.header);
  
  useEffect(() => {
    if (category && category.datasss && category.datasss.status === true) {
      setCategoryList(category.datasss.data);
    }
  }, [category]);

  // Region list start
  const region = useSelector((state) => state.region.region);
  useEffect(() => {
    if (region && region.datasss && region.datasss.status === true) {
      setRegionList(region.datasss.data);
    }
  }, [region]);

  // // redirect vendor detail start
  // const getVendorDetails = (vendorId) => {
  //   navigate("/vendor-detail/" + vendorId);
  // };

  const userInfo = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (flag) {
      const requestData = {
        category_id: parseInt(categoryId) || undefined,
        region_id: locationId || undefined,
        search_name: searchName || undefined,
      };

      if(categoryId || locationId || searchName){
        if (userInfo) {
          dispatch(vendorReelsAction(`/search-reel-with-auth`, requestData));
        } else {
            dispatch(vendorReelsAction(`/search-reel`, requestData));
        }
      }else{
        if (userInfo) {
          dispatch(vendorReelsAction('/search-reel-with-auth'))
        }else{
          dispatch(vendorReelsAction('/search-reel'));
        }
      }

      setFlag(false);
    }
  }, [categoryId, locationId, searchName]);

  const vendorReels = useSelector((state) => state.vendorReels.vendorReels);

  useEffect(() => {
    if (
      vendorReels &&
      vendorReels.datasss &&
      vendorReels.datasss.status === true
    ) {

      setUnique_Id(vendorReels.datasss.uniqueIds)
      setVendorsReel(vendorReels.datasss.data.data);
      setTotalPageCount(vendorReels.datasss.data.last_page);
      setVendorsReelAllData(vendorReels.datasss.data);
      setVendorsReelLabelLink(vendorReels.datasss.data.links);
      setLoading(false);
    }
  }, [vendorReels, vendorsReelAllData, vendorsReelLabelLink]);

  const vendorFavUnFav = (e, id) => {
    e.preventDefault();
    const updatedVendors = vendorsReel.map((vendor) => {
      if (vendor.vendor_id === id) {
        return {
          ...vendor,
          is_liked: vendor.is_liked === 1 ? 0 : 1,
        };
      }
      return vendor;
    });
    setVendorsReel(updatedVendors);

    const loggedInCheck = JSON.parse(localStorage.getItem("token"));
    if (loggedInCheck && loggedInCheck.api_token) {
      dispatch(vendorFavUnfavAction({ vendor_id: id }));
    } else {
      toast("Login Required.");
    }
  };

  const favUnfav = useSelector((state) => state.favUnfav.favUnfav);
  useEffect(() => {
    if (favUnfav && favUnfav.datasss) {
      setLikeDislike(favUnfav.datasss);
      if (flag) {
        setTimeout(() => {
          toast(favUnfav.datasss.message);
        }, 600);
      }
    }
  }, [favUnfav]);
  const executeOnClick = (isExpanded) => {
    // console.log(isExpanded);
  };

  // get vednor report action list
  const getReportList = async () => {
    let reportItem = [
      { id: 13, reason: "Inappropriate content" },
      { id: 9, reason: "Spam or misleading" },
      { id: 1, reason: "Copyright infringement" },
      { id: 12, reason: "Offensive material" },
      { id: 14, reason: "Other" },
    ];
    setReportList(reportItem);
  };

  // handle report change
  const handleReportChange = (event) => {
    const value = Number(event.target.value);
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((val) => val !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };
  function getCategory(category_id){
    setCategory_Id(category_id)
    setFlag(true); 
    setCategoryId(category_id)
  }

  useEffect(()=>{
    if(headerData?.status === true){
      setCategory_Id(null)
      setFlag(true); 
      setCategoryId(null)
    }
  
  },[headerData])

  // submit report on review
  const handleReportSubmit = (e, reels_id, vendor_id) => {
    e.preventDefault();
    if (userInfo) {
      let tokenObj = JSON.parse(localStorage.getItem("token"));
      const headers = {
        "content-type": "application/json",
        Authorization: `Bearer ${tokenObj.api_token}`,
      };
      let obj = {
        report_against: vendor_id,
        report_reasons: selectedValues,
        post_id: reels_id,
        report_type_id: "5",
      };
      if (selectedValues.length) {
        axios
          .post(
            `${process.env.REACT_APP_API_ENDPOINT}/api/submit_report`,
            obj,
            {
              headers,
            }
          )
          .then((response) => {
            toast.success(response.data.message);
            setReportModalOpen(false);
            setReportModalOpen2(false);
          })
          .catch((err) => {
            toast.warn("Something went wrong.");
          });
      } else {
        toast.warn("Please select a reason.");
      }
    } else {
      toast.warn("Please login first.");
    }
  };
  const renderHTMLContent = (id, vendor_id) => {
    return (
      <>
        {!reportModalOpen2 && reportModalOpen && shareReportIndex === id && (
          <div className="tipModal reviewer-review-report">
            <div className="sure-to-report">
              <div className="report-popup-wr report-popup-options">
                <div className="report-popup-content comn-para-white">
                  <div className="notification-popup-text">
                    <p>Report</p>
                  </div>
                  <div className="report-popup-type-btn">
                    {reportList &&
                      reportList.map((item, i) => {
                        return (
                          <div className="report-popup-type" key={item.id}>
                            <label>
                              {item.reason}
                              <input
                                type="checkbox"
                                name={`report-${item.id}`}
                                id={`report-${item.id}`}
                                value={item.id}
                                checked={selectedValues.includes(item.id)}
                                onChange={handleReportChange}
                              />
                              <span className="checkmark-report"></span>
                            </label>
                          </div>
                        );
                      })}
                    <div className="report-popup-btn">
                      <a
                        href="javascript:;"
                        className="cancel-btn"
                        onClick={() => {
                          setReportModalOpen(false);
                          setSelectedValues([]);
                        }}
                      >
                        Cancel
                      </a>
                      <input
                        type="submit"
                        name="report-submit"
                        onClick={(e) => handleReportSubmit(e, id, vendor_id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
          {!reportModalOpen && reportModalOpen2 && shareReportIndex === id && (
            <div className={`${window.innerWidth <= 767 ? "tipModal reviewer-review-report" : "displayNone" }`}>
            <div className="sure-to-report">
              <div className="report-popup-wr report-popup-options">
                <div className="report-popup-content comn-para-white">
                  <div className="notification-popup-text">
                    <p>Report</p>
                  </div>
                  <div className="report-popup-type-btn">
                    {reportList &&
                      reportList.map((item, i) => {
                        return (
                          <div className="report-popup-type" key={item.id}>
                            <label>
                              {item.reason}
                              <input
                                type="checkbox"
                                name={`report-${item.id}`}
                                id={`report-${item.id}`}
                                value={item.id}
                                checked={selectedValues.includes(item.id)}
                                onChange={handleReportChange}
                              />
                              <span className="checkmark-report"></span>
                            </label>
                          </div>
                        );
                      })}
                    <div className="report-popup-btn">
                      <a
                        href="#!"
                        className="cancel-btn"
                        onClick={() => {
                          setReportModalOpen2(false);
                          setSelectedValues([]);
                        }}
                      >
                        Cancel
                      </a>
                      <input
                        type="submit"
                        name="report-submit"
                        onClick={(e) => handleReportSubmit(e, id, vendor_id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest(".share-container")) {
      setOpenShareBox(false);
    }
  };

  const handleClick = (e, data) => {
    if (sharePopIndex !== data.id) {
      setOpenShareBox(true);
    } else {
      setOpenShareBox(!openShareBox);
    }
    setSharePopIndex(data.id);

    // Check if the device is a mobile
    const isMobile = window.innerWidth < 768;
    if (!isMobile) {
      const imageBounds = e.target.getBoundingClientRect();
      const { top, left } = imageBounds;
      setPopPosition({ top: top + window.pageYOffset, left });
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  // vendor favourite unfavourite end
  const [showControls, setShowControls] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const refVideoProperty = useRef(null);

  const toggleControls = () => {
    if (refVideoProperty.current) {
      setShowControls((prevShowControls) => !prevShowControls);
      refVideoProperty.current.controls = !showControls;
    }
  };

  const toggleMuted = (data) => {
    if (refVideoProperty.current) {
      refVideoProperty.current.muted = !refVideoProperty.current.muted;
      setIsMuted(refVideoProperty.current.muted);
    }
  };


  window.addEventListener("scroll", handleScroll);
  function handleScroll() {
    const videoPlayers = document.getElementsByClassName("videoPlayer");
    const viewportHeight = window.innerHeight;

    Array.from(videoPlayers).forEach((videoPlayer) => {
      const playerRect = videoPlayer.getBoundingClientRect();
      const isVisible =
        playerRect.top < viewportHeight / 2 &&
        playerRect.bottom > viewportHeight / 2;

        if (isVisible) {
          if (videoPlayer.paused) {
            videoPlayer.play().catch((error) => {
              // Handle the error (e.g., log it or display a message).
              console.error("Error playing video:", error);
            });
          }
        } else {
          if (!videoPlayer.paused) {
            videoPlayer.pause();
          }
        }
    });
  }

  let nodeData = '';
  const db = getDatabase();
  
  //check message node if exist then redirect or not then send new message from vendor details page..
  const handleRedirect = (data) => {
    const userData = userInfo?.data?.data?.user;
    const loggedInCheck = JSON.parse(localStorage.getItem('token'));
    if (loggedInCheck && loggedInCheck.api_token) {
        const chatNode = ref(db, 'messages/' + 0 + "_" + data.vendor_id + "_" + userData.id)
        onValue(chatNode, (snapshot) => {
            nodeData = snapshot.val();
            if (nodeData == null) {
                navigate("/vendor-detail/" + data.vendor_id,{
                  state: {
                    setMsgField:true,
                  }
                });
            } else {
                navigate('/consumer-chats', {
                    state: {
                        eventId: 0,
                        vendorId: data.vendor_id,
                        userId: userData.id,
                        name: data.first_name,
                        profile: data.profile ?? '',
                        deleted_at: null,
                    }
                });
            }
        });
    }
    else {
        toast.warning('Please login first.');
    }
  }

  return (
    <>
      {loading ? (
        <div className={"loader-item"}>
          <Loader
            type="spinner-circle"
            bgColor={"#FFFFFF"}
            color={"#FFFFFF"}
            size={150}
          />
        </div>
      ) : (
        <>
          <div className="wrapper" ref={homePageScrollTop}>
            <Header />
            <section className=" middle-section">
              <div className="banner-sec">
                <div className="container">
                  {/* <div className="banner-img common-inner-container fadeInUp wow">
                      <img src="./img/biglogo.png" className="big-logo" alt="" />
                      <img src="./img/bannerimg.png" className="logo-name" alt="" />
                  </div> */}
                  <div className="filter-section">
                    <div className="scroll-section common-inner-container fadeInUp wow">
                      <Link className="home-logo" to="/home">
                          <img src="./img/logo.svg" alt="ozgigs" />
                      </Link>
                    </div>
                    <div className="filter-section-inner common-inner-container fadeInUp wow">
                      <form action="">
                        <div className="filter-section-row desktop-view">
                          <div className="category-filter">
                            <div className="form-group common-select-sec category-sec">
                              {/* <img src="./img/More_Grid_Small.svg" alt="" /> */}
                              {/* <select
                                className="form-control"
                                name="categoryId"
                                id="categoryId"
                                onChange={(e) => {
                                  setFlag(true);
                                  setCategoryId(e.target.value);
                                }}
                              >
                                <option value="">Category</option>
                                {categoryList.length > 0 &&
                                  categoryList.map((list) => (
                                    <option key={list.id} value={list.id}>
                                      {list.category_name}
                                    </option>
                                  ))}
                              </select> */}
                              <select value={category_Id || ""} className="form-control" name="categoryId" id="categoryId" onChange={(e) => { setFlag(true); setCategoryId(e.target.value); setCategory_Id(e.target.value) }}>
                                  <option value="">Category</option>
                                  {categoryList.length > 0 && categoryList.map((list) => (
                                      <option key={list.id} value={list.id}>{list.category_name}</option>
                                  ))}
                              </select>
                              <span className="down-arrow">
                                <i className="fa fa-chevron-down"></i>
                              </span>
                            </div>
                          </div>
                          <div className="search-filter">
                            <div className="form-group ">
                              <div className="search-section">
                                <img src="/img/Search.svg" alt="" />
                                <input
                                  className="form-control"
                                  type="text"
                                  id="search_box"
                                  name="suburb"
                                  placeholder="Vendor Search"
                                  onChange={(e) => {
                                    setFlag(true);
                                    setSearchName(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="location-filter">
                            <div className="form-group common-select-sec location-sec category-sec">
                              {/* <img src="./img/Compass.svg" alt="" /> */}
                              <select
                                className="form-control"
                                name=""
                                id="locationId"
                                onChange={(e) => {
                                  setFlag(true);
                                  setLocationId(e.target.value);
                                }}
                              >
                                <option value="">Location</option>
                                {regionList.length > 0 &&
                                  regionList.map((list, index) => {
                                    return (
                                      <option key={list.id} value={list.id}>
                                        {list.name}
                                      </option>
                                    );
                                  })}
                              </select>
                              <span className="down-arrow">
                                <i className="fa fa-chevron-down"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="filter-section-row tablet-view">
                          <div className="search-filter">
                            <div className="form-group ">
                              <div className="search-section">
                                <img src="/img/Search.svg" alt="" />
                                <input
                                  className="form-control"
                                  type="text"
                                  id="search_box"
                                  name="suburb"
                                  placeholder="Search"
                                  onChange={(e) => {
                                    setFlag(true);
                                    setSearchName(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="searchandlocation">
                            <div className="category-filter">
                              <div className="form-group common-select-sec category-sec">
                                {/* <img src="./img/More_Grid_Small.svg" alt="" /> */}
                                <select
                                  className="form-control"
                                  name="categoryId"
                                  id="categoryId"
                                  onChange={(e) => {
                                    setFlag(true);
                                    setCategoryId(e.target.value);
                                  }}
                                >
                                  <option value="">Category</option>
                                  {categoryList.length > 0 &&
                                    categoryList.map((list) => (
                                      <option key={list.id} value={list.id}>
                                        {list.category_name}
                                      </option>
                                    ))}
                                </select>
                                <span className="down-arrow">
                                <i className="fa fa-chevron-down"></i>
                              </span>
                              </div>
                            </div>
                            <div className="location-filter">
                              <div className="form-group common-select-sec location-sec category-sec">
                                {/* <img src="./img/compass.png" alt="" /> */}
                                <select
                                  className="form-control"
                                  name=""
                                  id="locationId"
                                  onChange={(e) => {
                                    setFlag(true);
                                    setLocationId(e.target.value);
                                  }}
                                >
                                  <option value="">Location</option>
                                  {regionList.length > 0 &&
                                    regionList.map((list, index) => {
                                      return (
                                        <option key={list.id} value={list.id}>
                                          {list.name}
                                        </option>
                                      );
                                    })}
                                </select>
                                <span className="down-arrow">
                                <i className="fa fa-chevron-down"></i>
                              </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pro-vender-section" id="pro-sec">
                <div className="container">
                  <div className="common-inner-container">
                    <div className="pro-vendor-video-sec">
                      {vendorsReel && vendorsReel.length > 0 ? (
                        <>
                          {vendorsReel.map((reels, index) => {
                            let multipleReels = reels.reels.length;
                            // Filter the data and store in a separate variable
                            const filteredData = reels.reels.filter((data) => {
                              if (isMobile() && multipleReels > 1) {
                                return data.type === "portrait";
                              } else if (
                                data.type === "landscape" &&
                                multipleReels > 1
                              ) {
                                return true;
                              } else if (multipleReels === 1) {
                                return true;
                              }
                              return false;
                            });
                            // Render the filtered data
                            return filteredData.map((data) => (
                              <Waypoint
                                onEnter={(e) => {
                                  // Clear any existing timeout
                                  clearTimeout(timeoutId);
                                  timeoutId = setTimeout(() => {
                                    handleWaypointEnter(e, data, index);
                                  }, 5000);
                                }}
                                key={data.id}
                              >
                                <div key={data.id} className="video-wrap">
                                  <div className="pro-vender-profile fadeInUp wow">
                                    <div className="pro-vender-flex-row">
                                      <div className="pro-vender-name-sec">
                                        <span className="vender-profile">
                                          <img
                                            className="vendor-pic"
                                            src={
                                              reels && reels.profile
                                                ? reels.profile
                                                : "./img/profile.png"
                                            }
                                            alt="vendor-profile-image"
                                          />
                                        </span>
                                        <div className="vendor-pro">
                                          <h4>
                                            <Link
                                              to={"/vendor-detail/"+reels.vendor_id}
                                              title="Vendor Porfile"
                                              // onClick={() =>
                                              //   getVendorDetails(
                                              //     reels.vendor_id
                                              //   )
                                              // }
                                            >
                                              {reels
                                                ? reels.business_name
                                                : "N/A"}{" "}
                                            </Link>
                                          </h4>
                                          <span
                                            className="rating"
                                            title="Rating"
                                          >
                                            {reels && reels.avgRating
                                              ? reels.avgRating
                                              : 0}{" "}
                                            {/* <StarRating defaultValue={reels.avgRating} min={0} max={5} step={0.5} /> */}
                                            <span className="fa fa-star">
                                              {/* <img src="../img/rating-star.svg/" alt="" /> */}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <div>
                                        <ul className="user-profile-links list-unstyled  desktop-view-user-profile">
                                          <li>
                                            <img
                                              title="Share"
                                              className="share-container"
                                              onClick={(e) => {
                                                if (sharePopIndex !== data.id) {
                                                  setOpenShareBox(true);
                                                } else {
                                                  setOpenShareBox(
                                                    !openShareBox
                                                  );
                                                }
                                                setSharePopIndex(data.id);
                                                const imageBounds =
                                                  e.target.getBoundingClientRect();
                                                const { top, left } =
                                                  imageBounds;
                                                setPopPosition({
                                                  top: top + window.pageYOffset,
                                                  left,
                                                });
                                              }}
                                              style={{ cursor: "pointer" }}
                                              src="./img/share.svg"
                                              alt=""
                                            />
                                          </li>
                                          <li>
                                            <Link
                                              to={"/vendor-detail/"+reels.vendor_id}
                                              data-toggle="tooltip"
                                              title="Vendor Porfile"
                                              // onClick={() =>
                                              //   getVendorDetails(
                                              //     reels.vendor_id
                                              //   )
                                              // }
                                            >
                                              <img
                                                src="./img/user.svg"
                                                alt=""
                                              />
                                            </Link>
                                          </li>
                                          <li>
                                            <a href="#" title="Message" onClick={() => handleRedirect(reels)}>
                                              <img src="./img/message.svg" alt="" />
                                            </a>
                                          </li>

                                          {userInfo ? (
                                            <li>
                                              <a
                                                href="#"
                                                title="Like"
                                                onClick={(e) => {
                                                  setFlag(true);
                                                  vendorFavUnFav(
                                                    e,
                                                    reels.vendor_id
                                                  );
                                                }}
                                              >
                                                {reels.is_liked === 1 ? (
                                                  <>
                                                    <img
                                                      src="./img/after-favorite.png"
                                                      alt=""
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <img
                                                      src="./img/before-favorite.png"
                                                      alt=""
                                                    />
                                                  </>
                                                )}
                                              </a>
                                            </li>
                                          ) : (
                                            ""
                                          )}

                                          <li>
                                            <Tooltip
                                              className="review-tooltip"
                                              html={renderHTMLContent(
                                                data.id,
                                                reels.vendor_id
                                              )}
                                              position="top"
                                              trigger="click"
                                              interactive={true}
                                              open={reportModalOpen}
                                              distance={10}
                                              onRequestClose={() => {
                                                setReportModalOpen(false);
                                                setSelectedValues([]);
                                              }}
                                            >
                                              <img
                                                title="Report"
                                                onClick={(e) => {
                                                  if (
                                                    sharePopIndex !== data.id
                                                  ) {
                                                    setReportModalOpen(true);
                                                  } else {
                                                    setReportModalOpen(
                                                      !reportModalOpen
                                                    );
                                                  }
                                                  setSelectedValues([]);
                                                  setShareReportIndex(data.id);
                                                  // const imageBounds = e.target.getBoundingClientRect();
                                                  // const { top, left } = imageBounds;
                                                  // setModalPosition({ top: top + window.pageYOffset, left });
                                                }}
                                                style={{ cursor: "pointer" }}
                                                src="img/dots-menu.png"
                                                alt=""
                                              />
                                            </Tooltip>
                                          </li>
                                          <li>
                                          <a href="#!" onClick={() => toggleMuted(reels)}>
                                                {isMuted ? (
                                                  <img src="./img/mute.png" alt="Mute" />
                                                ) : (
                                                  <img src="./img/unmute.png" alt="Unmute" />
                                                )}
                                              </a>
                                          </li>
                                        </ul>
                                        {openShareBox &&
                                          sharePopIndex === data.id && (
                                            <div
                                              className={`divFixed${
                                                window.innerWidth >= 768
                                                  ? " hide-on-desktop"
                                                  : ""
                                              }`}
                                              style={{
                                                top:
                                                  popPosition.top - 80 + "px",
                                                left:
                                                  popPosition.left - 10 + "px",
                                                width: "345px",
                                                position: "absolute",
                                                zIndex: 111,
                                                display: "block",
                                                lineHeight: "60px",
                                              }}
                                            >
                                              <Sharethis
                                                reelsData={reels.vendor_id}
                                                dataId={index}
                                              />
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  
                                  <div className="vender-video fadeIn wow">
                                    <div className="video-sec">
                                      <video
                                        ref={(index === 0) ? refVideoProperty : null}
                                        className="videoPlayer"
                                        key={
                                          isMobile()
                                            ? data.reel_add.url_p
                                            : data.reel_add.url_w
                                        }
                                        preload="auto"
                                        controlsList="nodownload noplaybackrate"
                                        autoPlay={index === 0}
                                        playsInline
                                        muted={isMuted}
                                        controls={showControls}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          toggleControls();
                                        }}
                                        // poster={(reels.src_url) ? reels.src_url : './img/solid_black.webp'}                                        
                                      >
                                        <source
                                          src={
                                            data && data.reel_add
                                              ? isMobile()
                                                ? data.reel_add.url_p
                                                : data.reel_add.url_w
                                              : "./img/video-bg.png"
                                          }
                                          type="video/mp4"
                                        />
                                      </video>
                                    </div>
                                  </div>
                                  <div className="vender-data text-hidden">
                                    <div className="row">
                                      <div className="col-md-12 col-10 vendar-inner-data">
                                        <ShowMoreText
                                          /* Default options */
                                          lines={2}
                                          more="Read more"
                                          less="Read less"
                                          className="content-css"
                                          anchorClass="show-more-less-clickable"
                                          onClick={executeOnClick}
                                          expanded={false}
                                          // width={280}
                                          truncatedEndingComponent={"... "}
                                        >
                                          <a>{reels?.reel_caption}</a>
                                        </ShowMoreText>
                                      </div>
                                      <div className="col-md-12 col-2">
                                        <ul className="user-profile-links list-unstyled mobile-view-user-profile">
                                          <li>
                                            <img
                                              title="Share"
                                              className="share-container"
                                              onClick={(e) =>
                                                handleClick(e, data)
                                              }
                                              style={{ cursor: "pointer" }}
                                              src="./img/share.svg"
                                              alt=""
                                            />
                                          </li>
                                          <li>
                                            <Link
                                              to={"/vendor-detail/"+reels.vendor_id}
                                              // onClick={() =>
                                              //   getVendorDetails(
                                              //     reels.vendor_id
                                              //   )
                                              // }
                                            >
                                              <img
                                                src="./img/user.svg"
                                                alt=""
                                              />
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to={"/vendor-detail/"+reels.vendor_id}
                                              title="Message"
                                              // onClick={() =>
                                              //   getVendorDetails(
                                              //     reels.vendor_id
                                              //   )
                                              // }
                                            >
                                              <img
                                                src="./img/message.svg"
                                                alt=""
                                              />
                                            </Link>
                                          </li>
                                          {userInfo ? (
                                            <li>
                                              <a
                                                href="#"
                                                onClick={() => {
                                                  setFlag(true);
                                                  vendorFavUnFav(
                                                    reels.vendor_id
                                                  );
                                                }}
                                              >
                                                {likeDislike.fav ? (
                                                  <>
                                                    <img
                                                      src="./img/after-favorite.png"
                                                      alt=""
                                                    />
                                                  </>
                                                ) : (
                                                  <>
                                                    <img
                                                      src="./img/before-favorite.png"
                                                      alt=""
                                                    />
                                                  </>
                                                )}
                                              </a>
                                            </li>
                                          ) : (
                                            ""
                                          )}
                                          <li>
                                            {/* <img
                                              title="Report"
                                              onClick={(e) => {
                                                setReportModalOpen(true);
                                                setSelectedValues([]);
                                                const imageBounds =
                                                  e.target.getBoundingClientRect();
                                                const { top, left } =
                                                  imageBounds;
                                                setModalPosition({
                                                  top: top + window.pageYOffset,
                                                  left,
                                                });
                                              }}
                                              style={{ cursor: "pointer" }}
                                              src="img/dots-menu.png"
                                              alt=""
                                            /> */}
                                              <Tooltip
                                              className="review-tooltip"
                                              html={renderHTMLContent(
                                                data.id,
                                                reels.vendor_id
                                              )}
                                              position="top"
                                              trigger="click"
                                              interactive={true}
                                              open={reportModalOpen2}
                                              distance={10}
                                              onRequestClose={() => {
                                                setReportModalOpen2(false);
                                                setSelectedValues([]);
                                              }}
                                            >
                                              <img
                                                title="Report"
                                                onClick={(e) => {
                                                  if (
                                                    sharePopIndex !== data.id
                                                  ) {
                                                    setReportModalOpen2(true);
                                                  } else {
                                                    setReportModalOpen2(
                                                      !reportModalOpen2
                                                    );
                                                  }
                                                  setSelectedValues([]);
                                                  setShareReportIndex(data.id);
                                                  // const imageBounds = e.target.getBoundingClientRect();
                                                  // const { top, left } = imageBounds;
                                                  // setModalPosition({ top: top + window.pageYOffset, left });
                                                }}
                                                style={{ cursor: "pointer" }}
                                                src="img/dots-menu.png"
                                                alt=""
                                              />
                                            </Tooltip>
                                          </li>
                                          <li>
                                          <a href="#!" onClick={() => toggleMuted(reels)}>
                                                {isMuted ? (
                                                  <img src="./img/mute.png" alt="Mute" />
                                                ) : (
                                                  <img src="./img/unmute.png" alt="Unmute" />
                                                )}
                                              </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Waypoint>
                            ));
                          })}
                        </>
                      ) : (
                        <div className="text-center fadeIn wow">
                          <img className="w-25" src="./img/OZGIGS_Logo_Mark_PNG.png" alt=""/>
                          <h3 className="warning-text">There are currently no vendors that match your search criteria. Please try different keywords or category selections.</h3>
                        </div>
                      )}
                    </div>

                    {vendorsReel && vendorsReel.length > 0 && (
                      <div className="paginatin-sec fadeInUp wow">
                        <nav className="data-pagination">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel={
                              <FontAwesomeIcon icon={faChevronRight} />
                            }
                            onPageChange={handlePageChange}
                            pageRangeDisplayed={5}
                            pageCount={totalPageCount}
                            previousLabel={
                              <FontAwesomeIcon icon={faChevronLeft} />
                            }
                            forcePage={currentPage - 1}
                            renderOnZeroPageCount={null}
                          />
                        </nav>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="freq-asked-que-section">
                <div className="container">
                  <div className="freq-asked-section common-inner-container">
                    <div className="accordion-body-sec">
                      <div className="accordion" id="accordionExample">
                        <h1 className="fadeInUp wow">
                          Frequently Asked Questions
                        </h1>
                        <div className="accordion-item fadeInUp wow">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              What is OzGigs
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              Ozgigs is an online marketplace for the event’s
                              industry. It’s a free and fast way for you to find
                              services for your event.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item fadeInUp wow">
                          <h2 className="accordion-header" id="headingTwo">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Is it free to search and book vendors?
                            </button>
                          </h2>
                          <div
                            id="collapseTwo"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              Ozgigs is free to use. Simply create a free
                              account and start browsing!
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item fadeInUp wow">
                          <h2 className="accordion-header" id="headingThree">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              How do I use Ozgigs?
                            </button>
                          </h2>
                          <div
                            id="collapseThree"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              Browse the vendor showreels for services that you
                              need for your event. Add the vendors you like to
                              your favourites list, and/or message them directly
                              through their profile.
                            </div>
                          </div>
                        </div>

                        <div className="accordion-item fadeInUp wow">
                          <h2 className="accordion-header" id="headingFur">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFur"
                              aria-expanded="false"
                              aria-controls="collapseFur"
                            >
                              How do I book a vendor?
                            </button>
                          </h2>
                          <div
                            id="collapseFur"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFur"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              Once you’ve created your free account, find a
                              vendor you like, tap on the message button and
                              negotiate with them directly. Please note, Ozgigs
                              does not take payments. All payments and contracts
                              are negotiated between you and the vendor
                              directly.
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item fadeInUp wow">
                          <h2 className="accordion-header" id="headingFive">
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              Does OzGigs charge a booking fee?
                            </button>
                          </h2>
                          <div
                            id="collapseFive"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingFive"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              No! Ozgigs was created to eliminate the
                              “middleman”. Booking directly saves you time and
                              money.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer getCategory={getCategory} isHomePage={true}/>
        </>
      )}
    </>
  );
};
export default HomePage;
