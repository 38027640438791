import {
    userConstants,
    dashboardCount,
    forgetPassword,
    consumerData,
    vendorData,
    paymentData,
    reportData,
    feedbackData,
    subscriptionHistoryConst,
    userblockData,
    logoutData,
    detailsData,
    signupConstants,
    extraConstants,
    googleConstants,
    varificationConstants,
    resendEmailVerificationConstants,
    clearResendEmailVerificationData,
    resetPassword,
    clearLoginData,
    clearForgetData,
    clearVarificationData,
    clearSignupData,
    clearLogoutData,
    eventTypeConst,
    categoryData,
    RegionData,
    ChatListConst,
    CreateChatConst,
    SendChatNotification,

    // For Vendor Module's
    VendorHomeConst,
    vendorFavUnfavResponse,
    updateVendorConstants,
    clearUpdateVendorData,
    vendorDetailsData,
    vendorReelsData,
    searchVendorReelsData,
    vendorEventsConst,
    EventDetailsConst,
    VendorDashboardData,
    VendorProfileConstant,
    vendorSubscriptionConst,
    productConst,
    checkoutSessionConst,
    catCheckoutSessionConst,
    sessionRetriveConst,
    clearSessionData,
    vendorSubscriptionDetailsConst,
    CategoryRegionListConst,
    clearPasswordData,

    // For Consumer Module's
    ConsumerDashboardConst,
    ConsumerProfileConst,
    clearProfileData,
    UploadProfileConst,
    FavouriteVendorConst,
    ChangePasswordConst,
    RecentEventsConst,
    PastEventsConst,
    ViewEventConst,
    CreateEventConst,
    clearCreateEventData,
    EditEventConst,
    UpdateEventConst,
    DeleteEventConst,
    ConsumerReviewsConst,
    VisitCountConstant,
    WebsiteVisitCountConst,
    NotificationListConst,
    VendorReviewsConst,
    StaticDataListConst,
    // updateEventConstants,
    DeleteProfileConst,
    HeaderCategoryConst
} from "../constants/user.constants";


/************************** Reducer For Authentication *******************************/

export const authenticationReducer = (state = { authuser: [] }, action) => {
    switch (action.type) {
        case userConstants.GET_LOGIN_SUCCESS:
            return { authuser: action.payload };
        case userConstants.GET_LOGIN_FAILURE:
            return { errorsss: action.payload };
        case clearLoginData.CLEAR_LOGIN_MESSAGE:
            return { authuser: [], errorsss: "" }
        default:
            return state;
    }
};
export const registerReducer = (state = { authuser: [] }, action) => {
    switch (action.type) {
        case signupConstants.GET_SIGNUP_SUCCESS:
            return { authuser: action.payload };
        case signupConstants.GET_SIGNUP_FAILURE:
            return { errorsss: action.payload };
        case clearSignupData.CLEAR_SIGNUP_MESSAGE:
            return { authuser: [], errorsss: "" }
        default:
            return state;
    }
};


export const logoutReducer = (state = { logout: [] }, action) => {
    switch (action.type) {
        case logoutData.GET_USERLOGOUT_SUCCESS:
            return { logout: action.payload };
        case logoutData.GET_USERLOGOUT_FAILURE:
            return { errorsss: action.payload };
        case clearLogoutData.CLEAR_LOGOUT_MESSAGE:
            return { logout: [], errorsss: "" }
        default:
            return state;
    }
};

export const signupReducer = (state = { signup: "" }, action) => {
    switch (action.type) {
        case "GET_SIGNUP_SUCCESS":
            state = {
                ...state,
                signup: action.payload.data,
            };
            return state;
        case signupConstants.GET_SIGNUP_FAILURE:
            state = {
                ...state,
                error: action.payload.data,
            };
            break; // Add break statement here
        case clearSignupData.CLEAR_SIGNUP_MESSAGE:
            return { signup: [], errorsss: "" }
        default:
            return state
    }
    return state;
};

export const forgetReducer = (state = { forget: [] }, action) => {
    switch (action.type) {
        case forgetPassword.GET_FORGET_SUCCESS:
            return { forget: action.payload };
        case forgetPassword.GET_FORGET_FAILURE:
            return { errorsss: action.payload };
        case clearForgetData.CLEAR_FORGET_MESSAGE:
            return { forget: [], errorsss: "" }
        default:
            return state;
    }
};

export const consumerReducer = (state = { consumer: [] }, action) => {
    switch (action.type) {
        case consumerData.GET_CONSUMER_SUCCESS:
            return { consumer: action.payload };
        case consumerData.GET_CONSUMER_FAILURE:
            return { errorsss: action.payload };
        default:
            return state;
    }
};

export const vendorReducer = (state = { vendor: [] }, action) => {
    switch (action.type) {
        case vendorData.GET_VENDOR_SUCCESS:
            return { vendor: action.payload };
        case vendorData.GET_VENDOR_FAILURE:
            return { errorsss: action.payload };
        default:
            return state;
    }
};

export const dashboardReducer = (state = { dashboard: [] }, action) => {
    switch (action.type) {
        case dashboardCount.GET_DASHBOARD_SUCCESS:
            return { dashboard: action.payload };
        case dashboardCount.GET_DASHBOARD_FAILURE:
            return { errorsss: action.payload };
        default:
            return state;
    }
};

export const paymentReducer = (state = { payment: [] }, action) => {
    switch (action.type) {
        case paymentData.GET_PAYMENT_SUCCESS:
            return { payment: action.payload };
        case paymentData.GET_PAYMENT_FAILURE:
            return { errorsss: action.payload };
        default:
            return state;
    }
};

export const reportReducer = (state = { report: [] }, action) => {
    switch (action.type) {
        case reportData.GET_REPORT_SUCCESS:
            return { report: action.payload };
        case reportData.GET_REPORT_FAILURE:
            return { errorsss: action.payload };
        default:
            return state;
    }
};

export const feedbackReducer = (state = { feedback: [] }, action) => {
    switch (action.type) {
        case feedbackData.GET_FEEDBACK_SUCCESS:
            return { feedback: action.payload };
        case feedbackData.GET_FEEDBACK_FAILURE:
            return { errorsss: action.payload };
        default:
            return state;
    }
};

export const subscriptionHistoryReducer = (state = { subscriptionHistory: [] }, action) => {
    switch (action.type) {
        case subscriptionHistoryConst.GET_SUBSCRIPTIONHISTORY_SUCCESS:
            return { subscriptionHistory: action.payload };
        case subscriptionHistoryConst.GET_SUBSCRIPTIONHISTORY_FAILURE:
            return { errorsss: action.payload };
        default:
            return state;
    }
};

export const userblockReducer = (state = { userblock: [] }, action) => {
    switch (action.type) {
        case userblockData.GET_USERBLOCK_SUCCESS:
            return { userblock: action.payload };
        case userblockData.GET_USERBLOCK_FAILURE:
            return { errorsss: action.payload };
        default:
            return state;
    }
};


export const detailsReducer = (state = { details: [] }, action) => {
    switch (action.type) {
        case detailsData.GET_DETAILS_SUCCESS:
            return { details: action.payload };
        case detailsData.GET_DETAILS_FAILURE:
            return { errorsss: action.payload };
        default:
            return state;
    }
};

export const extraReducer = (state = { extra: [] }, action) => {
    switch (action.type) {
        case extraConstants.GET_EXTRA_SUCCESS:
            return { extra: action.payload };
        case extraConstants.GET_EXTRA_FAILURE:
            return { errorsss: action.payload };
        default:
            return state;
    }
};

export const googleReducer = (state = { google: [], error: "" }, action) => {
    switch (action.type) {
        case googleConstants.GET_GOOGLE_SUCCESS:
            return { google: action.payload };
        case googleConstants.GET_GOOGLE_FAILURE:
            return { error: action.payload };
        case googleConstants.CLEAR_GOOGLE_LOGIN:
            return { google: [], error: "" }
        default:
            return state;
    }
};

export const resendVerificationReducer = (state = { resendVerification: [], error: "" }, action) => {
    switch (action.type) {
        case resendEmailVerificationConstants.GET_RESENDVERIFICATION_SUCCESS:
            return { resendVerification: action.payload };
        case resendEmailVerificationConstants.GET_RESENDVERIFICATION_FAILURE:
            return { error: action.payload };
        case clearResendEmailVerificationData.CLEAR_RESENDEMAILVERIFICATION_MESSAGE:
            return { resendVerification: [], errorsss: "" }
        default:
            return state;
    }
};

export const varificationReducer = (state = { varification: [], error: "" }, action) => {
    switch (action.type) {
        case varificationConstants.GET_VARIFICATION_SUCCESS:
            return { varification: action.payload };
        case varificationConstants.GET_VARIFICATION_FAILURE:
            return { error: action.payload };
        case clearVarificationData.CLEAR_VARIFICATION_MESSAGE:
            return { varification: [], errorsss: "" }
        default:
            return state;
    }
};

export const resetReducer = (state = { reset: [], error: "" }, action) => {
    switch (action.type) {
        case resetPassword.GET_RESET_SUCCESS:
            return { reset: action.payload };
        case resetPassword.GET_RESET_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};


export const chatListReducer = (state = { chatList: [], error: "" }, action) => {
    switch (action.type) {
        case ChatListConst.GET_CHATLIST_SUCCESS:
            return { chatList: action.payload };
        case ChatListConst.GET_CHATLIST_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const createChatReducer = (state = { createChat: [], error: "" }, action) => {
    switch (action.type) {
        case CreateChatConst.GET_CREATECHAT_SUCCESS:
            return { createChat: action.payload };
        case CreateChatConst.GET_CREATECHAT_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const sendChatNotificationReducer = (state = { sendChatNotification: [], error: "" }, action) => {
    switch (action.type) {
        case SendChatNotification.GET_SENDCHATNOTIFICATION_SUCCESS:
            return { sendChatNotification: action.payload };
        case SendChatNotification.GET_SENDCHATNOTIFICATION_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

// /************************** Reducer For Vendor Side Module ******************************/

export const regionsCategoryListReducer = (state = { regionsCategory: [], error: "" }, action) => {
    switch (action.type) {
        case CategoryRegionListConst.CATEGORY_REGIONS_LIST_SUCCESS:
            return { regionsCategoryList: action.payload };
        case CategoryRegionListConst.CATEGORY_REGIONS_LIST_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const vendorHomeReducer = (state = { vendorHome: [], error: "" }, action) => {
    switch (action.type) {
        case VendorHomeConst.GET_VENDORHOME_SUCCESS:
            return { vendorHome: action.payload };
        case VendorHomeConst.GET_VENDORHOME_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const updateVendorReducer = (state = { updateVendor: [], error: "" }, action) => {
    switch (action.type) {
        case updateVendorConstants.UPDATEVENDOR_CLEAR : 
            return  { updateVendor : []};
        case updateVendorConstants.GET_UPDATEVENDOR_SUCCESS:
            return { updateVendor: action.payload };
        case updateVendorConstants.GET_UPDATEVENDOR_FAILURE:
            return { error: action.payload };
        case clearUpdateVendorData.CLEAR_UPDATEVENDOR_MESSAGE:
            return { updateVendor: [], error: "" };
        default:
            return state;
    }
};

export const categoryReducer = (state = { category: [], error: "" }, action) => {
    switch (action.type) {
        case categoryData.GET_CATEGORY_SUCCESS:
            return { category: action.payload };
        case categoryData.GET_CATEGORY_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const eventTypeReducer = (state = { eventType: [], error: "" }, action) => {
    switch (action.type) {
        case eventTypeConst.GET_EVENTTYPE_SUCCESS:
            return { eventType: action.payload };
        case eventTypeConst.GET_EVENTTYPE_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}


export const RegionReducer = (state = { region: [], error: "" }, action) => {
    switch (action.type) {
        case RegionData.GET_REGION_SUCCESS:
            return { region: action.payload };
        case RegionData.GET_REGION_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const vendorFavUnfavReducer = (state = { favUnfav: [], error: "" }, action) => {
    switch (action.type) {
        case vendorFavUnfavResponse.GET_FAVUNFAV_SUCCESS:
            return { favUnfav: action.payload };
        case vendorFavUnfavResponse.GET_FAVUNFAV_FAILURE:
            return { error: action.payload };
        case vendorFavUnfavResponse.GET_FAVUNFAV_RESTORE:
            return { favUnfav: [] };
        default:
            return state;
    }
}

export const vendorDetailsReducer = (state = { verdorDetails: [], error: "" }, action) => {
    switch (action.type) {
        case vendorDetailsData.GET_VENDORDETAIL_SUCCESS:
            return { verdorDetails: action.payload };
        case vendorDetailsData.GET_VENDORDETAIL_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

// export const vendorEventReducer = (state = { eventType: [], error: "" }, action) => {
//     switch (action.type) {
//         case updateEventConstants.GET_EVENTTYPE_SUCCESS:
//             return { eventType: action.payload };
//         case updateEventConstants.GET_EVENTTYPE_FAILURE:
//             return{ error: action.payload };
//         default:
//             return state;
//     }
// }


export const vendorReelsReducer = (state = { vendorReels: [], error: "" }, action) => {
    switch (action.type) {
        case vendorReelsData.GET_VENDORREELS_SUCCESS:
            return { vendorReels: action.payload };
        case vendorReelsData.GET_CATEGORY_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const searchVendorReelsReducer = (state = { searchReels: [], error: "" }, action) => {
    switch (action.type) {
        case searchVendorReelsData.GET_SEARCHVENDORREELS_SUCCESS:
            return { searchReels: action.payload };
        case searchVendorReelsData.GET_SEARCHVENDORREELS_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const vendorEventReducer = (state = { vendorEvents: [], error: "" }, action) => {
    switch (action.type) {
        case vendorEventsConst.GET_VENDOREVENTS_SUCCESS:
            return { vendorEvents: action.payload };
        case vendorEventsConst.GET_VENDOREVENTS_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const eventDetailsReducer = (state = {eventDetails: [], error: ""}, action) => {
    switch(action.type) {
        case EventDetailsConst.GET_EVENTDETAILS_SUCCESS:
            return { eventDetails: action.payload };
        case EventDetailsConst.GET_EVENTDETAILS_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const vendorDashboardReducer = (state = { vendorDashboard: [], error: "" }, action) => {
    switch (action.type) {
        case VendorDashboardData.GET_VENDORDASHBOARD_SUCCESS:
            return { vendorDashboard: action.payload };
        case VendorDashboardData.GET_VENDORDASHBOARD_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const vendorProfileReducer = (state = { vendorProfile: [], error: "" , loading:true  }, action) => {
    switch (action.type) {
        case VendorProfileConstant.GET_VENDORPROFILE_REQUEST:
            return {vendorProfile: null , loading: true }
        case VendorProfileConstant.GET_VENDORPROFILE_SUCCESS:
            return { vendorProfile: action.payload , loading: false };
        case VendorProfileConstant.GET_VENDORPROFILE_FAILURE:
            return { error: action.payload  , loading : false};
        default:
            return state;
    }
}

export const vendorSubscriptionReducer = (state = { vendorSubscription: [], error: "" }, action) => {
    switch (action.type) {
        case vendorSubscriptionConst.GET_VENDORSUBSCRIPTION_SUCCESS:
            return { vendorSubscription: action.payload };
        case vendorSubscriptionConst.GET_VENDORSUBSCRIPTION_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const productReducer = (state = { product: [], error: "" }, action) => {
    switch (action.type) {
        case productConst.GET_PRODUCT_SUCCESS:
            return { product: action.payload };
        case productConst.GET_PRODUCT_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const checkoutSessionReducer = (state = { checkoutSession: [], error: "" }, action) => {
    switch (action.type) {
        case checkoutSessionConst.GET_CHECKOUTSESSION_SUCCESS:
            return { checkoutSession: action.payload };
        case checkoutSessionConst.GET_CHECKOUTSESSION_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const catCheckoutSessionReducer = (state = { catCheckoutSession: [], error: "" }, action) => {
    switch (action.type) {
        case catCheckoutSessionConst.GET_CATCHECKOUTSESSION_SUCCESS:
            return { checkoutSession: action.payload };
        case catCheckoutSessionConst.GET_CATCHECKOUTSESSION_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const sessionRetriveReducer = (state = { sessionRetirve: [], error: "" }, action) => {
    switch (action.type) {
        case sessionRetriveConst.GET_SESSIONRETRIVE_SUCCESS:
            return { sessionRetrive : action.payload };
        case sessionRetriveConst.GET_SESSIONRETRIVE_FAILURE:
            return { error: action.payload };
        case clearSessionData.CLEAR_SESSION_MESSAGE:
            return { sessionRetrive : [], errorsss: ""}
        default:
            return state;
    }
};

export const vendorSubscriptionDetailsReducer = (state = { vendorSubscriptionDetails: [], error: "" }, action) => {
    switch (action.type) {
        case vendorSubscriptionDetailsConst.GET_VENDORSUBSCRIPTIONDETAILS_SUCCESS:
            return { vendorSubscriptionDetails: action.payload };
        case vendorSubscriptionDetailsConst.GET_VENDORSUBSCRIPTIONDETAILS_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const vendorReviewsReducer = (state = { vendorReviews: [], error: "" }, action) => {
    switch (action.type) {
        case VendorReviewsConst.GET_VENDORREVIEWS_SUCCESS:
            return { vendorReviews: action.payload };
        case VendorReviewsConst.GET_VENDORREVIEWS_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

// ************************** Reducer For Consumer Side Module *************************************

export const consumerDashboardReducer = (state = { consumerDashboard: [], error: "" }, action) => {
    switch (action.type) {
        case ConsumerDashboardConst.GET_CONSUMERDASHBOARD_SUCCESS:
            return { consumerDashboard: action.payload };
        case ConsumerDashboardConst.GET_CONSUMERDASHBOARD_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const consumerProfileReducer = (state = { consumerProfile: [], error: "" }, action) => {
    switch (action.type) {
        case ConsumerProfileConst.GET_CONSUMERPROFILE_SUCCESS:
            return { consumerProfile: action.payload };
        case ConsumerProfileConst.GET_CONSUMERPROFILE_FAILURE:
            return { error: action.payload };
        // case clearProfileData.CLEAR_PROFILE_MESSAGE:
        //     return { consumerProfile: [], errorsss: ""}
        default:
            return state;
    }
}

export const uploadProfileReducer = (state = { uploadProfile: [], error: "" }, action) => {
    switch (action.type) {
        case UploadProfileConst.GET_UPLOADPROFILE_SUCCESS:
            return { uploadProfile: action.payload };
        case UploadProfileConst.GET_UPLOADPROFILE_FAILURE:
            return { error: action.payload };
        case clearProfileData.CLEAR_PROFILE_MESSAGE:
            return { uploadProfile: [], errorsss: ""}
        default:
            return state;
    }
}

export const favouriteVendorReducer = (state = { favouriteVendor: [], error: "" }, action) => {
    switch (action.type) {
        case FavouriteVendorConst.GET_FAVOURITEVENDOR_SUCCESS:
            return { favouriteVendor: action.payload };
        case FavouriteVendorConst.GET_FAVOURITEVENDOR_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const changePasswordReducer = (state = { changePassword: [], error: "" }, action) => {
    switch (action.type) {
        case ChangePasswordConst.GET_CHANGEPASSWORD_SUCCESS:
            return { changePassword: action.payload };
        case ChangePasswordConst.GET_CHANGEPASSWORD_FAILURE:
            return { error: action.payload };
        case clearProfileData.CLEAR_PROFILE_MESSAGE:
            return { changePassword: [], errorsss: ""}
        case clearPasswordData.CLEAR_PASSWORD_MESSAGE:
            return { changePassword: [], errorsss: ""}
        default:
            return state;
    }
}

export const consumerReviewsReducer = (state = { consumerReviews: [], error: "" }, action) => {
    switch (action.type) {
        case ConsumerReviewsConst.GET_CONSUMERREVIEWS_SUCCESS:
            return { consumerReviews: action.payload };
        case ConsumerReviewsConst.GET_CONSUMERREVIEWS_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const deleteProfileReducer = (state = { deleteProfile: [], error: "" }, action) => {
    switch (action.type) {
        case DeleteProfileConst.GET_DELETEPROFILE_SUCCESS:
            return { deleteProfile: action.payload };
        case DeleteProfileConst.GET_DELETEPROFILE_FAILURE:
            return { error: action.payload };
        case DeleteProfileConst.CLEAR_DELETEPROFILE_MESSAGE:
            return { deleteProfile: [], errorsss: ""}
        default:
            return state;
    }
}
// ************************** Reducer For Events Module *************************************

export const recentEventsReducer = (state = { recentEvents: [], editEvent: [], error: "" }, action) => {
    switch (action.type) {
        case RecentEventsConst.GET_RECENTEVENTS_SUCCESS:
            return { recentEvents: action.payload, editEvent: []};
        case RecentEventsConst.GET_RECENTEVENTS_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const pastEventsReducer = (state = { pastEvents: [], error: "" }, action) => {
    switch (action.type) {
        case PastEventsConst.GET_PASTEVENTS_SUCCESS:
            return { pastEvents: action.payload};
        case PastEventsConst.GET_PASTEVENTS_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const createEventReducer = (state = {createEvent: [], error: ""}, action) => {
    switch(action.type) {
        case CreateEventConst.GET_CREATEEVENT_SUCCESS:
            return { createEvent: action.payload };
        case CreateEventConst.GET_CREATEEVENT_FAILURE:
            return { error: action.payload };
        case clearCreateEventData.CLEAR_CREATEEVENT_MESSAGE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const viewEventReducer = (state = {viewEvent: [], error: ""}, action) => {
    switch(action.type) {
        case ViewEventConst.GET_VIEWEVENT_SUCCESS:
            return { viewEvent: action.payload };
        case ViewEventConst.GET_VIEWEVENT_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const editEventReducer = (state = {editEvent: [], error: ""}, action) => {
    switch(action.type) {
        case EditEventConst.GET_EDITEVENT_SUCCESS:
            return { editEvent: action.payload };
        case EditEventConst.GET_EDITEVENT_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const updateEventReducer = (state = {updateEvent: [], error: ""}, action) => {
    switch(action.type) {
        case UpdateEventConst.UPDATE_EVENT_CLEAR : 
            return  { updateEvent : []};
        case UpdateEventConst.GET_UPDATEEVENT_SUCCESS:
            return { updateEvent: action.payload };
        case UpdateEventConst.GET_UPDATEEVENT_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const deleteEventReducer = (state = {deleteEvent: [], error: ""}, action) => {
    switch(action.type) {
        case DeleteEventConst.DELETEEVENT_CLEAR : 
            return  { deleteEvent : []};
        case DeleteEventConst.GET_DELETEEVENT_SUCCESS:
            return { deleteEvent: action.payload };
        case DeleteEventConst.GET_DELETEEVENT_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const visitCountReducer = (state = {visitCount: [], error: ""}, action) => {
    switch(action.type) {
        case VisitCountConstant.VISIT_COUNT_DELETE : 
            return  { visitCount : []};
        case VisitCountConstant.VISIT_COUNT_SUCCESS:
            return { visitCount: action.payload };
        case VisitCountConstant.VISIT_COUNT_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const websiteVisitCountReducer = (state = {websiteVisitCount: [], error: ""}, action) => {
    switch(action.type) {
        case WebsiteVisitCountConst.WEBSITE_VISIT_COUNT_DELETE : 
            return  { visitCount : []};
        case WebsiteVisitCountConst.WEBSITE_VISIT_COUNT_SUCCESS:
            return { visitCount: action.payload };
        case WebsiteVisitCountConst.WEBSITE_VISIT_COUNT_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
}

export const notificationReducer = (state = { notificationList: [] }, action) => {
    switch (action.type) {
        case NotificationListConst.GET_NOTIFICATION_LIST_SUCCESS:
            return { notification: action.payload };
        case NotificationListConst.GET_NOTIFICATION_LIST_FAILURE:
            return { errorsss: action.payload };
        default:
            return state;
    }
};

export const staticDataListReducer = (state = { staticDataList: [] }, action) => {
    switch (action.type) {
        case StaticDataListConst.GET_STATIC_DATA_LIST_SUCCESS:
            return { notification: action.payload };
        case StaticDataListConst.GET_STATIC_DATA_LIST_FAILURE:
            return { errorsss: action.payload };
        default:
            return state;
    }
};

export const headerReducer = (state = { status: false }, action) => {
    switch (action.type) {
        case HeaderCategoryConst.REMOVE_CATEGORY_SUCCESS:
            return { status: action.payload };
        case HeaderCategoryConst.REMOVE_CATEGORY_FAILURE:
            return { errorsss: action.payload };
        default:
            return state;
    }
};