import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from 'react-js-loader';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import Header from '../includes/header';
import Footer from '../includes/footer';
import createChatAction from "../../actions/eventChatAction";
import vendorDetailAction from "../../actions/vendorDetailAction";
import vendorFavUnfavAction from "../../actions/vendorFavUnfavAction";
import vendorLikeDislikeAction from "../../actions/vendorLikeDislikeAction";
import { VendorProfileVisitCount, WebsiteVisitCount } from "../../actions/visitCountAction";
import OwlCarousel from "react-owl-carousel";
import { getDatabase, ref, set, push, onValue } from "firebase/database";
import { getAuth } from "firebase/auth";
import ShowMoreText from "react-show-more-text";
import StarRating from 'react-star-ratings';
import Modal from 'react-modal';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import axios from 'axios';
import io from 'socket.io-client';
import usersChatAction from "../../actions/chatListAction";

Modal.setAppElement('#root');
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        position: 'fixed',
        width: '500px',
        maxWidth: '100%',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        borderRadius: '10px',
        padding: '20px',
        zIndex: '9999',
        color: '#000000'
    },
};


const VendorDetails = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [flag, setFlag] = useState(false)
    const [vendorDetails, setVendorDetails] = useState([]);
    const [likeDislike, setLikeDislike] = useState("");
    const [msgField, setMsgField] = useState(false);
    const [message, setMessage] = useState("");
    const [ipAddress, setIpAddress] = useState("");
    // const [isPlaying, setIsPlaying] = useState(false);
    const [openRatingModal, setOpenRatingModal] = useState(false);
    const [description, setDescription] = useState('');
    // const [vendorLikes, setVendorLikes] = useState(0);
    const [profileDecription, setProfileDescription] = useState([])
    const [rating, setRating] = useState(0);
    const [userReviewStatus, setUserReviewStatus] = useState(false)
    const [reportModalOpen, setReportModalOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [socketIO, setSocketIO] = useState(null);
    const [reportList, setReportList] = useState([])
    const [selectedValues, setSelectedValues] = useState([]);
    const [videoPlay, setVideoPlay] = useState(false)
    const videoRef = useRef([]);
    const reelRef = useRef([]);
    const videoRellRef = useRef([]);

    const optionPhotos = {
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            500: {
                items: 2,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            900: {
                items: 2,
            },
            1000: {
                items: 2,
            },
            1200: {
                items: 3,
            }
        }
    }
    const optionReel = {
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        }
    }

    const fetchIpAddress = async () => {
        try {
            const response = await axios.get("https://api.ipify.org?format=json");
            const ipAddress = response.data.ip;
            localStorage.setItem("ipAddress", ipAddress);
        } catch (error) {
            console.error("Error fetching IP address:", error);
        }
    };

    // const handlePlay = (event) => {
    //     // setIsPlaying(true);
    //     const video = event.target;
    //     const figcaption = video.parentElement.querySelector('figcaption');
    //     if (figcaption) {
    //         figcaption.remove();
    //     }
    // };

    // const handlePlayImg = () => {
    //     videoRellRef.current.play();
    // }

    // const handlePlayVideoImg = (event, id) => {
    //     videoRef.current.play();
    //     const ipAddress = localStorage.getItem('ipAddress')
    //     if (ipAddress && id) {
    //         let obj = {
    //             post_id: id,
    //             type: 'video',
    //             ip_address: ipAddress,
    //         };
    //         axios
    //             .post(`${process.env.REACT_APP_API_ENDPOINT}/api/submit-video-view`, obj)
    //             .then((response) => {
    //                 console.log('response==========', response);
    //             }).catch(err => {
    //                 console.log('errr==========', err);
    //             });
    //     }

    // }

    const handlePlay = ( event , ref) => {
        // setIsPlaying(true);
        var ind = videoRef.current.findIndex(ele => ele == ref);
        // videoRef.current[ind].play();
        videoRef.current.forEach((el) => {
            if(el != ref){
                el.pause();
            }  
        })
        reelRef.current.forEach((el) => {
            if(el != ref){
                el.pause();
            }  
        })
        const video = event.target;
        const figcaption = video.parentElement.querySelector('figcaption');
        if (figcaption) {
            figcaption.remove();
        }
        // videoRellRef.current.pause();
    };

    // const handlePlayReel = (event)=> {
    //     const video = event.target;
    //     videoRellRef.current.play();
    //     const figcaption = video.parentElement.querySelector('figcaption');
    //     if (figcaption) {
    //         figcaption.remove();
    //     }
    //     videoRef.current.forEach((el) => {
    //             el.pause();
    //     })
    // }

    // const handlePlayImg = () => {
    //     videoRellRef.current.play();
    // }

    const handlePlayVideoImg = (event, id , ref, type) => {
        if(type == 'reel'){
            var ind = reelRef.current.findIndex(ele => ele == ref);
            reelRef.current[ind].play();
        }else{
            var ind = videoRef.current.findIndex(ele => ele == ref);
            videoRef.current[ind].play();
        }
        const ipAddress = localStorage.getItem('ipAddress')
        if (type && ipAddress && id) {
            let obj = {
                post_id: id,
                type: type,
                ip_address: ipAddress,
            };
            axios
                .post(`${process.env.REACT_APP_API_ENDPOINT}/api/submit-video-view`, obj)
                .then((response) => {
                }).catch(err => {
                    console.log('errr==========', err);
                });
        }

    }

    // set loading on page start
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        // checkVendorLikeDislike(id);
        vendorFavUnFav(id);
        dispatch(vendorDetailAction(id))
        // dispatch(vendorLikeDislikeAction(id))
        dispatch({ type: 'GET_FAVUNFAV_RESTORE' })
        if(location?.state?.setMsgField == true){
            setMsgField(true);
        }
    }, []);


    useEffect(() => {
        axios.get("https://api.ipify.org?format=json").then((data) => {
            setIpAddress(data.data.ip);
        });

        getUserReview()
        // get vednor report action list
        let reportItem = [
            { id: 1, reason: 'Copyright infringement' },
            { id: 10, reason: 'Scam or Fraud' },
            { id: 11, reason: 'Contains False Information' },
            { id: 14, reason: 'Other' }
        ]
        setReportList(reportItem)
    }, []);

    useEffect(() => {
        let obj = {
            vendor_id: id,
            ip_address: ipAddress
        }
        dispatch(VendorProfileVisitCount(obj))
    }, [ipAddress])

    const vendorInfo = useSelector((state) => state.vendorDetails.verdorDetails);
    useEffect(() => {
        if (vendorInfo && vendorInfo.datasss && vendorInfo.datasss.status === true) {
            setVendorDetails(vendorInfo.datasss.data);
            const profileDescription = vendorInfo.datasss.data?.user_information?.profile_description;
            const descriptionLines = profileDescription ? profileDescription.split('\n') : [];
            setProfileDescription(descriptionLines)
        }
    }, [vendorInfo]);


    // get user review list
    const getUserReview = async () => {
        let tokenObj = JSON.parse(localStorage.getItem("token"));
        if (tokenObj) {
            const headers = {
                'content-type': 'application/json',
                Authorization: `Bearer ${tokenObj.api_token}`
            }
            let obj = {
                "to_id": id
            }
            const result = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/get-user-review`, obj, { headers })
            if (result.status === 200 && result.data.data === 1) {
                setUserReviewStatus(true)
            }

        }

    }

    // vendor like dislike start
    const checkVendorLikeDislike = (id) => {
        const loggedInCheck = JSON.parse(localStorage.getItem('token'));
        if (loggedInCheck && loggedInCheck.api_token) {
            dispatch(vendorFavUnfavAction({ vendor_id: id }));
        }
        else { toast.warning('Please login first.'); }
    }
    const writeRewiew = (e) => {
        e.preventDefault()
        const loggedInCheck = JSON.parse(localStorage.getItem('token'));
        if (loggedInCheck && loggedInCheck.api_token) {
            // dispatch(vendorFavUnfavAction({ vendor_id: id }));
            handleOpenPopUp()
        } else { toast.warning('Please login first.'); }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setModalLoading(true)
        const userInfo = JSON.parse(localStorage.getItem('userData'));
        const userData = userInfo?.data?.data?.user;
        // TODO: Handle submission logic
        let tokenObj = JSON.parse(localStorage.getItem("token"));
        const headers = {
            'content-type': 'application/json',
            Authorization: `Bearer ${tokenObj.api_token}`
        }
        let obj = {}
        if (userData.role === 2) {
            obj = {
                from_id: userData.id,
                to_id: id,
                review: description,
                rating: rating
            }
        }
        if (userData.role === 3) {
            obj = {
                from_id: userData.id,
                to_id: id,
                review: description,
                rating: rating
            }
        }
        axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/rating-review`, obj, { headers }).then(response => {
            if (response) {
                setModalLoading(false)
                toast(response.data.message)
            }
            setOpenRatingModal(!openRatingModal)

        }).catch(error => {
            setModalLoading(false)
            toast.warn(error.response.data.review[0])
        })

        // handleClose();
    };
    const handleRating = (rate) => {
        setRating(rate)
    }

    // open model for wright review
    const handleOpenPopUp = () => {
        setOpenRatingModal(!openRatingModal)
        setRating(0);
        setDescription("");
    }

    const vendorFavUnFav = (id) => {
        const loggedInCheck = JSON.parse(localStorage.getItem('token'));
        if (loggedInCheck && loggedInCheck.api_token) {
            dispatch(vendorLikeDislikeAction({ vendor_id: id }));
        }
    }

    const favUnfav = useSelector((state) => state.favUnfav.favUnfav);
    useEffect(() => {
        if (favUnfav && favUnfav.datasss) {
            setLikeDislike(favUnfav.datasss);
            // if (likeDislike) {
            //     setVendorLikes((prevLikes) => prevLikes - 1);
            // } else {
            //     setVendorLikes((prevLikes) => prevLikes + 1);
            // }
            if (flag) {
                dispatch(vendorDetailAction(id))
                setTimeout(() => {
                    toast(favUnfav.datasss.message);
                }, 100);
            }
        }
    }, [favUnfav]);
    // vendor like dislike end

    let nodeData = '';
    const db = getDatabase();
    const auth = getAuth();
    const user = auth.currentUser;
    const users = JSON.parse(localStorage.getItem("userData"));
    var logginUser = users?.data?.data?.user;

    //check node for redirect or send new message
    const OpenMessageBox = () => {
        const loggedInCheck = JSON.parse(localStorage.getItem('token'));
        if (loggedInCheck && loggedInCheck.api_token) {
            const chatNode = ref(db, 'messages/' + 0 + "_" + id + "_" + logginUser.id)
            onValue(chatNode, (snapshot) => {
                nodeData = snapshot.val();
                if (nodeData == null) {
                    setMsgField(true)
                } else {
                    navigate('/consumer-chats', {
                        state: {
                            eventId: 0,
                            vendorId: Number(id),
                            userId: logginUser.id,
                            name: vendorDetails.first_name,
                            profile: vendorDetails.profile ?? '',
                            deleted_at: vendorDetails.deleted_at,
                        }
                    });
                }
            });
        }
        else {
            toast.warning('Please login first.');
        }
    }

    // create chat node or save chat listing databasee
    const sendMessage = async () => {
        if (message) {
            if (id && logginUser.id) {
                const chatNode = ref(db, 'messages/' + 0 + "_" + id + "_" + logginUser.id)
                onValue(chatNode, (snapshot) => {
                    nodeData = snapshot.val();
                    if (nodeData === null) {
                        set(push(ref(db, 'messages/' + 0 + "_" + id + "_" + logginUser.id)), {
                            // uid       : user.uid,
                            message: message,
                            mesgType: 'TEXT',
                            sender_id: logginUser.id,
                            sender_name: logginUser.first_name,
                            sender_profile: logginUser.profile,
                            receiver_id: Number(id),
                            receiver_name: vendorDetails.first_name,
                            receiver_profile: vendorDetails.profile,
                            created_at: Date.now()
                        });

                        set(push(ref(db, 'chatlist/' + 0 + "_" + id + "_" + logginUser.id)), {
                            // uid       : user.uid,
                            message: message,
                            mesgType: 'TEXT',
                            sender_id: logginUser.id,
                            sender_name: logginUser.first_name,
                            sender_profile: logginUser.profile,
                            receiver_id: Number(id),
                            receiver_name: vendorDetails.first_name,
                            receiver_profile: vendorDetails.profile,
                            created_at: Date.now()
                        });

                        const res = dispatch(
                            createChatAction({
                                event_id: 0,
                                vendor_id: id,
                                user_id: logginUser.id,
                                chatType: 'individual'
                            })
                        );
                    }
                });

                setMsgField('');
            } else {
                toast('something went wrong');
            }
        }
    }

    // message send on press enter
    const sendOnEnter = (e) => {
        if (e.key === "Enter") {
            sendMessage()
        }
    }
    useEffect(() => {
        const videos = document.querySelectorAll('video');
        videos.forEach(video => {
            video.addEventListener('play', () => {
                const figcaption = video.parentElement.querySelector('figcaption');
                if (figcaption) {
                    figcaption.remove();
                }
            });
        });
    }, []);

    // to redirection from link
    const handleRedirect = (data) => {
        try {
            dispatch(WebsiteVisitCount({ id: data.id , type : data.type, vendor_id : data.vendor_id }));
            window.open(`${data.links}`, '_blank');// <- This is what makes it open in a new window. 
        } catch (error) {
            console.error('Error redirecting to link:', error);
        } 
    }
    // handle report change
    const handleReportChange = (event) => {
        const value = Number(event.target.value);
        if (selectedValues.includes(value)) {
            setSelectedValues(selectedValues.filter((val) => val !== value));
        } else {
            setSelectedValues([...selectedValues, value]);
        }
    };

    // submit report on review
    const handleReportSubmit = (e) => {
        e.preventDefault()
        const tokenObj = JSON.parse(localStorage.getItem('token'));
        if (tokenObj && tokenObj.api_token) {
            const headers = {
                "content-type": "application/json",
                Authorization: `Bearer ${tokenObj.api_token}`,
            };
            let obj = {
                report_against: id,
                report_reasons: selectedValues,
                post_id: id,
                report_type_id: '4'
            };
            if (selectedValues.length) {
                axios
                    .post(`${process.env.REACT_APP_API_ENDPOINT}/api/submit_report`, obj, {
                        headers,
                    })
                    .then((response) => {
                        toast.success(response.data.message);
                        setReportModalOpen(false)
                    });
            } else {
                toast.warn("Please select a reason.")
            }
        } else {
            toast.warn("Please login first.")
        }
    }

    const renderHTMLContent = () => {
        return (
            <div className="reviewer-review-report">
                <div className="sure-to-report">
                    <div className="report-popup-wr report-popup-options">
                        <div className="report-popup-content comn-para-white">
                            <div className="notification-popup-text">
                                <p>Report</p>
                            </div>
                            <div className="report-popup-type-btn">

                                {
                                    reportList && reportList.map((item, i) => {
                                        return (
                                            <div className="report-popup-type" key={i}>
                                                <label>
                                                    {item.reason}
                                                    <input
                                                        type="checkbox"
                                                        name={`report-${item.id}`}
                                                        id={`report-${item.id}`}
                                                        value={item.id}
                                                        checked={selectedValues.includes(item.id)}
                                                        onChange={handleReportChange}
                                                    />
                                                    <span className="checkmark-report"></span>
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                                <div className="report-popup-btn">
                                    <a
                                        href="#"
                                        className="cancel-btn"
                                        onClick={() => {
                                            setReportModalOpen(false)
                                            setSelectedValues([])
                                        }}
                                    >
                                        Cancel
                                    </a>
                                    <input
                                        type="submit"
                                        name="report-submit"
                                        onClick={handleReportSubmit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <>
            {loading ? (
                <div className={"loader-item"}>
                    <Loader type="spinner-circle" bgColor={"#FFFFFF"} color={'#FFFFFF'} size={150} />
                </div>
            ) : (
                <>
                    <div className="wrapper">
                        <Header />
                        <section className="vendor-banner">
                            <div className="vendor-banner-content">
                                <figure>
                                    <img src={(vendorDetails && vendorDetails.user_information) ? vendorDetails.user_information.cover_photo : "../img/default-cover.png"} alt="Vendor Profile View To Consumer" />
                                    {/* <img src={(vendorDetails && vendorDetails.user_information) ? vendorDetails.user_information.cover_photo : "../img/vandorbg.png"} alt="uhuhui" /> */}
                                </figure>
                            </div>
                        </section>
                        <section className="vendor-consumer-detail-wr">
                            <div className="container">
                                <div className="vendor-consumer-detail-content">
                                    <div className="vendor-consumer-img-name">
                                        <figure>
                                            <img src={(vendorDetails) ? vendorDetails.profile : "../img/default-profile-picture.png"} alt="profileImage" />
                                        </figure>
                                        {/* <h3 className="common-label">{(vendorDetails) ? vendorDetails.first_name + " " + vendorDetails.last_name : "Dummy Vendor"}</h3> */}
                                        <h3 className="common-label">{(vendorDetails) ? vendorDetails.business_name : "Vendor"}</h3>

                                        {vendorDetails?.vendor_category?.length > 0 && (
                                            <ul style={{ listStyleType: 'disc' }}>
                                                <li style={{ display: "none" }}></li>
                                                {vendorDetails?.vendor_category?.map((categoryList, index) => {
                                                    return (
                                                        // <>
                                                        <li key={index}>{categoryList?.category?.category_name}</li>
                                                        // </>
                                                    );
                                                })}
                                            </ul>
                                        )}

                                        <h4 className="event-title mt-5">Event Types</h4>
                                        {vendorDetails?.vendor_event_type?.length > 0 && (
                                            <ul>
                                                <li style={{ display: "none" }}></li>
                                                {vendorDetails?.vendor_event_type?.map((event) => {
                                                    return (
                                                        <>
                                                            <li> {event.event_type?.event_type} </li>
                                                        </>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </div>
                                    <div className="vendor-consumer-reviews-block">
                                        <div className="vendor-consumer-reviews-top">
                                            <div className="vendor-consumer-like-btn">
                                                {/* <a href="javascript:;" onClick={() => { setFlag(true); checkVendorLikeDislike(id) }}>Like</a> */}
                                                <a href="#" title="Likes" onClick={() => { setFlag(true); checkVendorLikeDislike(id) }}>
                                                    {(likeDislike.fav) ?
                                                        <><span>Liked</span> {vendorDetails.vendor_likes}</> :
                                                        <><span>Like </span> {vendorDetails.vendor_likes}</>
                                                    }
                                                </a>
                                            </div>
                                            <div className="vendor-total-views-block">
                                                <figure>
                                                    <img src="../img/view_eye_icon.svg" alt="img" />
                                                </figure>
                                                <div className="vendor-total-views">
                                                    <p>{(vendorDetails?.profile_views)}</p>
                                                </div>
                                            </div>
                                            <div className="vendor-review-send-btn">
                                                {
                                                    userReviewStatus ?
                                                        <button className="btn">Review Submitted</button>
                                                        :
                                                        <button onClick={writeRewiew} className="btn">Write a review</button>
                                                }
                                                <button className="btn" onClick={OpenMessageBox}>Send Message</button>
                                            </div>
                                        </div>
                                        {(msgField) ?
                                            <>
                                                <div className="chats-textarea-block">
                                                    <div className="chats-textarea-form">
                                                        <div className="chats-textarea">
                                                            <textarea placeholder="Write your messsage" type="text" className="form-control" onKeyPress={(e) => sendOnEnter(e)} onChange={(e) => setMessage(e.target.value)} aria-label="message…" />
                                                        </div>
                                                        <button className="chats-submit-btn" type="button" onClick={sendMessage}> </button>
                                                    </div>
                                                </div>
                                            </>
                                            : ''}
                                        <div className="vendor-consumer-reviews-bottom">
                                            <div className="vendor-consumer-rating">
                                                <p style={{ marginRight: "7px", marginBottom: "4px" }}>{(vendorDetails?.avgRating) ? vendorDetails?.avgRating : 0}</p>
                                                {/* <p> */}
                                                {/* <Rating readonly initialValue={(vendorDetails?.avgRating) ? vendorDetails?.avgRating : 0} /> */}
                                                <StarRating
                                                    rating={(vendorDetails?.avgRating) ? Number(vendorDetails?.avgRating) : 0}
                                                    starRatedColor="skyblue"
                                                    starEmptyColor="gray"
                                                    numberOfStars={5}
                                                    starSpacing="5px"
                                                    starDimension="20px"
                                                />
                                                {/* </p> */}
                                            </div>
                                            <div className="vendor-consumer-total-review">
                                                <p>{vendorDetails?.ratingCount} Reviews</p>
                                            </div>
                                            <div className="report-content">
                                                <Tooltip className="review-tooltip flag-content" html={renderHTMLContent()} position="top" trigger="click" interactive={true}
                                                    open={reportModalOpen} onRequestClose={() => {
                                                        setReportModalOpen(false);
                                                        setSelectedValues([]);
                                                    }}
                                                >
                                                    <img src='/img/icons8-flag-16.png' onClick={(e) => setReportModalOpen(!reportModalOpen)} />
                                                    <p onClick={(e) => setReportModalOpen(!reportModalOpen)} style={{ marginRight: "5px", marginBottom: "2px" }}>Report</p>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    {vendorDetails?.vendor_category?.length > 0 && vendorDetails?.vendor_category && (
                                        // <div className="vendor-child-friendly-block">
                                        <div className={(vendorDetails.vendor_category.findIndex(categoryData => categoryData.category.category_name === 'Venues' && categoryData?.extra_info?.length) !== -1) ? "vendor-child-friendly-block" : "vendor-child-friendly-inner"}>
                                            <ul>
                                                {vendorDetails.vendor_category.map((categoryData) => {
                                                    if (categoryData.category.category_name === 'Venues' && categoryData.extra_info !== null) {
                                                        return (
                                                            <>
                                                                {categoryData.extra_info && categoryData.extra_info.length > 0 && categoryData.extra_info.includes('1') &&
                                                                    <li>
                                                                        <img src="../img/Vegetarian.svg" alt="" />
                                                                        <span>Vegan option</span>
                                                                    </li>
                                                                }
                                                                {categoryData.extra_info && categoryData.extra_info.length > 0 && categoryData.extra_info.includes('2') &&
                                                                    <li>
                                                                        <img src="../img/Vegetarian.svg" alt="" />
                                                                        <span>Vegetarian option</span>
                                                                    </li>
                                                                }
                                                                {categoryData.extra_info && categoryData.extra_info.length > 0 && categoryData.extra_info.includes('3') &&
                                                                    <li>
                                                                        <img src="../img/Child-Friendly.svg" alt="" />
                                                                        <span>Child-friendly</span>
                                                                    </li>
                                                                }
                                                                {categoryData.extra_info && categoryData.extra_info.length > 0 && categoryData.extra_info.includes('4') &&
                                                                    <li>
                                                                        <img src="../img/Wheelchair_Access.svg" alt="" />
                                                                        <span>Wheelchair access</span>
                                                                    </li>
                                                                }
                                                                {categoryData.extra_info && categoryData.extra_info.length > 0 && categoryData.extra_info.includes('5') &&
                                                                    <li>
                                                                        <img src="../img/Vegetarian.svg" alt="" />
                                                                        <span>Dairy free option</span>
                                                                    </li>
                                                                }
                                                                {categoryData.extra_info && categoryData.extra_info.length > 0 && categoryData.extra_info.includes('6') &&
                                                                    <li>
                                                                        <img src="../img/Vegetarian.svg" alt="" />
                                                                        <span>Gluten free option</span>
                                                                    </li>
                                                                }
                                                                {categoryData && categoryData.min &&
                                                                    <li>
                                                                        <img src="../img/50_seats.svg" alt="" />
                                                                        <span>{categoryData.max} Seats</span>
                                                                    </li>
                                                                }
                                                            </>
                                                        );
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                    <div className="vendor-consumer-detail-para">
                                        {/* <p> */}
                                        <ShowMoreText
                                            /* Default options */
                                            lines={4}
                                            more="Read more"
                                            less="Read less"
                                            className="content-css"
                                            anchorclassName="show-more-less-clickable"
                                            expanded={false}
                                            // width={280}
                                            truncatedEndingComponent={"... "}
                                        >
                                            {profileDecription.map((line, index) => (
                                                <span key={index}>{line}<br /></span>
                                            ))}
                                        </ShowMoreText>
                                        {/* </p> */}
                                        {/* <p>{vendorDetails?.user_information?.profile_description}</p> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="area-operates-wr">
                            <div className="container">
                                <div className="area-operates-content">
                                    <div className="area-operates-heading">
                                        <h3 className="common-label">Areas where {(vendorDetails) ? vendorDetails.business_name : "Vendor"} operates</h3>
                                    </div>
                                    {vendorDetails?.vendor_regions?.length > 0 && (
                                        <div className="area-operates-list">
                                            <ul>
                                                {vendorDetails?.vendor_regions?.map((regions) => {
                                                    return (
                                                        <>
                                                            <li>{regions.name}</li>
                                                        </>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Photo's Section */}
                            <div className="area-operates-slider-block container my-5">
                                {vendorDetails?.vendor_photos?.length > 0 && (
                                    <div className="area-operates-slider-inner  mySwiper">
                                        <div className="area-operates-slider swiper-wrapper">
                                            <OwlCarousel className="area-operates-slider-content swiper-slide owl-carousel owl-theme owl-carousel-one owl-loaded owl-drag" loop margin={30} nav
                                                {...optionPhotos}
                                            >
                                                {vendorDetails?.vendor_photos?.map((photosMap) => {
                                                    if (photosMap.type === "image") {
                                                        return (
                                                            <>
                                                                <div className='item' key={photosMap.id} style={{backgroundImage: `url(${photosMap.photos})`}}>
                                                                    <img src={photosMap.photos} alt="vendor-image" className="vendor-photo-carousel" />
                                                                </div>
                                                            </>
                                                        );
                                                    }
                                                })}
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Video's Section */}
                            {(vendorDetails?.vendor_photos?.length > 0 && vendorDetails?.vendor_photos.find((ele) => ele.type === 'video')) &&
                                <section className="vendor-popup-slider-wr">
                                    <div className="container">
                                        <div className="vendor-popup-slider-inner">
                                            {vendorDetails?.vendor_photos?.length > 0 && (
                                                <OwlCarousel className="vendor-popup-slider owl-carousel owl-theme owl-carousel-two" loop items={1} nav>
                                                    {vendorDetails?.vendor_photos?.map((photosMap , i) => {
                                                        if (photosMap.type === "video") {
                                                            return (
                                                                <>
                                                                    <div className='vendor-popup-slider-content' key={photosMap.id}>
                                                                       <figure style={{ aspectRatio: '16/9' }}>
                                                                            <video ref={el => videoRef.current[i] = el} id={photosMap.id}  onPlay={(e) => handlePlay(e, videoRef.current[i],'video')} controls controlsList="nodownload noplaybackrate" disablePictureInPicture>
                                                                                <source src={(photosMap && photosMap.photos) ? photosMap.photos : "./img/video-bg.png"} type="video/mp4" />
                                                                            </video>

                                                                            <figcaption>
                                                                                <a href="#!" data-id={photosMap.photos} onClick={(e) => handlePlayVideoImg(e, photosMap.id , videoRef.current[i], 'video')}>
                                                                                    <img src="../img/Play_icon.svg" alt="Play icon" />
                                                                                </a>
                                                                            </figcaption>
                                                                            {/* <figcaption>
                                                                                <a href="#!" onClick={handlePlay}>
                                                                                    <img src="../img/Play_icon.svg" alt="Play icon" />
                                                                                </a>
                                                                            </figcaption> */}
                                                                        </figure>
                                                                    </div>
                                                                </>
                                                            );
                                                        }
                                                    })}
                                                </OwlCarousel>
                                            )}
                                        </div>
                                    </div>
                                </section>
                            }

                            {/* Reels */}
                            {vendorDetails?.reels?.length > 0 && (
                                vendorDetails?.reels?.map((reel, i) => {
                                    if (reel.type === "landscape") {
                                        return (
                                            <>
                                                <section className="vendor-popup-slider-wr container">
                                                    <div className="landscape-reels">
                                                        <h3 className="common-label mt-5">Landscape Reel</h3>
                                                        <div className="vendor-popup-slider-inner">
                                                            <div className='vendor-popup-slider-content vendor-popup-slider-content-reels' key={reel.id}>
                                                                <figure>
                                                                    <video ref={el => reelRef.current[i] = el} id={reel.id}  onPlay={(e) => handlePlay(e, reelRef.current[i],'reel')} controls controlsList="nodownload noplaybackrate" disablePictureInPicture>
                                                                        <source src={(reel && reel.reel_add) ? reel.reel_add : "./img/video-bg.png"} type="video/mp4" />
                                                                    </video>

                                                                    <figcaption>
                                                                        <a href="#!" data-id={reel.reel_add} onClick={(e) => handlePlayVideoImg(e, reel.id , reelRef.current[i], 'reel')}>
                                                                            <img src="../img/Play_icon.svg" alt="Play icon" />
                                                                        </a>
                                                                    </figcaption>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </>
                                        );
                                    }
                                    if (reel.type === "portrait") {
                                        return (
                                            <>
                                                <section className="vendor-popup-slider-wr container">
                                                    <div className="landscape-reels">
                                                        <h3 className="common-label mt-5">Portrait Reel</h3>
                                                        <div className="vendor-popup-slider-inner">
                                                            <div className='vendor-popup-slider-content vendor-popup-slider-content-reels' key={reel.id}>
                                                                <figure>
                                                                    <video ref={el => reelRef.current[i] = el} id={reel.id}  onPlay={(e) => handlePlay(e, reelRef.current[i],'reel')} controls controlsList="nodownload noplaybackrate" disablePictureInPicture>
                                                                        <source src={(reel && reel.reel_add) ? reel.reel_add : "./img/video-bg.png"} type="video/mp4" />
                                                                    </video>

                                                                    <figcaption>
                                                                        <a href="#!" data-id={reel.reel_add} onClick={(e) => handlePlayVideoImg(e, reel.id , reelRef.current[i], 'reel')}>
                                                                            <img src="../img/Play_icon.svg" alt="Play icon" />
                                                                        </a>
                                                                    </figcaption>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </>
                                        );
                                    }
                                })
                            )}

                            {/* Landscape Reel */}
                            {/* {vendorDetails?.reels?.length > 0 && (
                                vendorDetails?.reels?.map((reel, i) => {
                                    if (reel.type === "portrait") {
                                        return (
                                            <>
                                                <section className="vendor-popup-slider-wr container">
                                                    <div className="landscape-reels">
                                                        <h3 className="common-label mt-5">Portrait Reel</h3>
                                                        <div className="vendor-popup-slider-inner">
                                                            <div className='vendor-popup-slider-content vendor-popup-slider-content-reels' key={reel.id}>
                                                                <figure>
                                                                    <video ref={el => videoRef.current[i] = el} id={reel.id}  onPlay={(e) => handlePlay(e, videoRef.current[i])} controls controlsList="nodownload noplaybackrate" disablePictureInPicture>
                                                                        <source src={(reel && reel.reel_add) ? reel.reel_add : "./img/video-bg.png"} type="video/mp4" />
                                                                    </video>

                                                                    <figcaption>
                                                                        <a href="#!" data-id={reel.reel_add} onClick={handlePlayImg}>
                                                                            <img src="../img/Play_icon.svg" alt="Play icon" />
                                                                        </a>
                                                                    </figcaption>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </>
                                        );
                                    }
                                })
                            )} */}

                            {/* review section start */}
                            {vendorDetails?.user_review?.length > 0 && (
                                <div className="review-sec vendor-video-carousel fadeIn wow">
                                    <div className="container">
                                        <div className="review-inner common-inner-container">
                                            <h3>REVIEWS</h3>
                                            <div className="review-carousel">
                                                <OwlCarousel className="owl-carousel owl-theme owl-carousel-three"
                                                    {...optionReel}
                                                >
                                                    {vendorDetails?.user_review?.map((review) => {
                                                        return (
                                                            <>
                                                                <div className="item">
                                                                    <div className="review-card">
                                                                        <div className="review-card-head">
                                                                            <span className="review-card-img">
                                                                                <img className="reviewer-profile" src={(review?.user_details) ? review?.user_details?.profile : "./img/v-profile.png"} />
                                                                            </span>
                                                                        </div>
                                                                        <div className="review-card-body">
                                                                            <p className="name">{review?.user_details?.first_name} {review?.user_details?.last_name}</p>
                                                                            <ul>
                                                                                <li>
                                                                                    {/* <Rating readonly initialValue={(review?.rating) ? review?.rating : 0} /> */}
                                                                                    <StarRating
                                                                                        rating={(review?.rating) ? Number(review?.rating) : 0}
                                                                                        starRatedColor="skyblue"
                                                                                        starEmptyColor="gray"
                                                                                        numberOfStars={5}
                                                                                        starSpacing="5px"
                                                                                        starDimension="20px"
                                                                                    />
                                                                                </li>
                                                                            </ul>
                                                                            <span>Review</span> <p className="desc"> {review?.review} </p>
                                                                            <span>Reply</span>  <p className="desc"> {(review?.reply) ? review?.reply : 'N/A'} </p>
                                                                            {/* <p className="desc">management companies (Planners) with event service providers (Vendors)</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                                </OwlCarousel>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* review section End */}
                        </section>
                        {vendorDetails?.vendor_link && vendorDetails?.vendor_link.length > 0 && (
                            <section className="vendor-consumer-links">
                                <div className="container">
                                    <div className="vendor-consumer-links-inner">
                                        <div className="vendor-consumer-links-heading">
                                            <h3 className="common-label">links</h3>
                                        </div>
                                        <div className="vendor-consumer-social-links">
                                            {vendorDetails?.vendor_link?.map((links) => {
                                                return (
                                                    <>
                                                        {(links.type === 'website') ?
                                                            <Link onClick={() => handleRedirect(links)}>
                                                                <img src="../img/vendor_website.svg" alt="Instagram logo" />
                                                            </Link>
                                                            : ''}
                                                        {(links.type === 'instagram') ?
                                                            <Link onClick={() => handleRedirect(links)}>
                                                                <img src="../img/vendor_Instagram.svg" alt="Tik Tok logo" />
                                                            </Link>
                                                            : ''}
                                                        {(links.type === 'tiktok') ?
                                                            <Link onClick={() => handleRedirect(links)}>
                                                                <img src="../img/vendor_tik_tok.svg" alt="Facebook logo" />
                                                            </Link>
                                                            : ''}
                                                        {(links.type === 'facebook') ?
                                                            <Link onClick={() => handleRedirect(links)}>
                                                                <img src="../img/vendor_Facebook.svg" alt="Youtube logo" />
                                                            </Link>
                                                            : ''}
                                                        {(links.type === 'youtube') ?
                                                            <Link onClick={() => handleRedirect(links)}>
                                                                <img src="../img/vendor_Youtube.svg" alt="Website" />
                                                            </Link>
                                                            : ''}
                                                    </>)
                                            })}
                                        </div>
                                    </div>
                                </div>

                            </section>
                        )}
                    </div>
                    {/* modal for rating */}
                    <div>
                        <Modal
                            isOpen={openRatingModal}
                            style={customStyles}
                            contentLabel="User Rating Modal"
                        >
                            <button onClick={handleOpenPopUp} className="custom-close-btn">X</button>
                            <h2>Rate your experience</h2>
                            <form className='ratingPopup' onSubmit={(e) => handleSubmit(e, id)}>
                                <div className="form-group">
                                    <label htmlFor="rating">Rating:</label>
                                    <StarRating
                                        rating={rating}
                                        starRatedColor="blue"
                                        starEmptyColor="gray"
                                        changeRating={handleRating}
                                        numberOfStars={5}
                                        starSpacing="5px"
                                        starDimension="30px"
                                        step={0.5}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="description">Give review:</label>
                                    <textarea id="description" name="description" onChange={(e) => setDescription(e.target.value)} rows="4" cols="50" className="form-control"></textarea>
                                </div>
                                {
                                    modalLoading ? <>
                                        <button type="submit" className="btn" disabled >
                                            <div className={"btn-loader"}>
                                                <img src="/img/loader.svg" className="loader-svg-img" />
                                            </div>
                                        </button>
                                    </> : <button type="submit" className="btn btn-primary">Submit</button>
                                }

                            </form>
                        </Modal>
                    </div>
                    <Footer />
                </>
            )}
        </>
    )
}

export default VendorDetails;
