import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import Loader from 'react-js-loader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import VendorHeader from "./includes/header";
import Footer from "../includes/footer";
import vendorDetailAction from "../../actions/vendorDetailAction";
import OwlCarousel from "react-owl-carousel";
import ShowMoreText from "react-show-more-text";
import StarRating from 'react-star-ratings';
import Modal from 'react-modal';
import 'react-tippy/dist/tippy.css';
import axios from 'axios';

Modal.setAppElement('#root');

const   VendorPreview = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [vendorDetails, setVendorDetails] = useState([]);
    const [description, setDescription] = useState([]);
    const [profileDecription, setProfileDescription] = useState([]);
    const [vendorReels, setVendorReels] = useState([]);
    const [portraitReel, setPortraitReel] = useState("");
    const [landscapeReel, setLandscapeReel] = useState("");
    const videoRef = useRef([]);
    const landscapeRef = useRef([]);
    const portraitRef = useRef([]);

    const optionPhotos = {
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            500: {
                items: 2,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            900: {
                items: 2,
            },
            1000: {
                items: 2,
            },
            1200: {
                items: 3,
            }
        }
    }
    const optionReel = {
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        }
    }

    const handlePlay = ( event , ref, type) => {
        if(type == 'landscapeReel'){
            if(portraitReel.length > 0){
                portraitRef.current.pause();
            }
            videoRef.current.forEach((el) => {
                if(el != ref){
                    el.pause();
                }  
            })
        }else if(type == 'portraitReel'){
            if(landscapeReel.length > 0){
                landscapeRef.current.pause();
            }
            videoRef.current.forEach((el) => {
                if(el != ref){
                    el.pause();
                }  
            })
        }else{
            if(landscapeReel.length > 0){
                landscapeRef.current.pause();
            }
            if(portraitReel.length > 0){
                portraitRef.current.pause();
            }
            videoRef.current.forEach((el) => {
                if(el != ref){
                    el.pause();
                }  
            })
        }
        const video = event.target;
        const figcaption = video.parentElement.querySelector('figcaption');
        if (figcaption) {
            figcaption.remove();
        }
    };

    // const handlePlayReel = (event,ref) => {
    //     videoRef.current.forEach((el) => {
    //         if(el != ref){
    //             el.pause();
    //         }  
    //     })
    //     // setIsPlaying(true);
    //     const video = event.target;
    //     const figcaption = video.parentElement.querySelector('figcaption');
    //     if (figcaption) {
    //         figcaption.remove();
    //     }
    //     videoRef.current.forEach((el) => {
    //             el.pause();
    //     })
    //     videoRellRef.current.pause();
    // };

    // const handlePlayImg = () => {
    //     videoRellRef.current.play();
    // }

    const handlePlayVideoImg = (event, id,ref,type) => {
        if(type == 'landscapeReel'){
            landscapeRef.current.play();
        }
        if(type == 'portraitReel'){
            portraitRef.current.play();
        }
        if(type == 'video'){
            var ind = videoRef.current.findIndex(ele => ele == ref);
            videoRef.current[ind].play();
        }
    }

    // set loading on page start
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        dispatch(vendorDetailAction(id))
    }, []);

    const vendorInfo = useSelector((state) => state.vendorDetails.verdorDetails);
    useEffect(() => {
        if (vendorInfo && vendorInfo.datasss && vendorInfo.datasss.status === true) {
            setVendorDetails(vendorInfo.datasss.data);
            const profileDescription = vendorInfo.datasss.data?.user_information?.profile_description;
            setDescription(profileDescription)
            const descriptionLines = profileDescription ? profileDescription.split('\n') : [];
            setProfileDescription(descriptionLines)
            setVendorReels(vendorInfo?.datasss?.data?.reels);
            
            // need to set index of landscape or portrait reel in state to view.            
            if (vendorInfo.datasss?.data?.reels.length > 0) {
                let landscapeIndex = vendorReels?.findIndex(
                    (reel) => reel?.type === "landscape"
                );
                let portraitIndex = vendorReels?.findIndex(
                    (reel) => reel?.type === "portrait"
                );
                let landscapeData = [];
                if (landscapeIndex > -1) {
                    landscapeData.push({
                        id: vendorReels[landscapeIndex]?.id,
                        reel_add: vendorReels[landscapeIndex]?.reel_add,
                        type: vendorReels[landscapeIndex]?.type,
                    });
                }
                setLandscapeReel(landscapeData);
                let portraitData = [];
                if (portraitIndex > -1) {
                    portraitData.push({
                        id: vendorReels[portraitIndex]?.id,
                        reel_add: vendorReels[portraitIndex]?.reel_add,
                        type: vendorReels[portraitIndex]?.type,
                    });
                }
                setPortraitReel(portraitData);
            }
        }
    }, [vendorInfo,vendorReels]);

    useEffect(() => {
        const videos = document.querySelectorAll('video');
        videos.forEach(video => {
            video.addEventListener('play', () => {
                const figcaption = video.parentElement.querySelector('figcaption');
                if (figcaption) {
                    figcaption.remove();
                }
            });
        });
    }, []);

    return (
        <>
            {loading ? (
                <div className={"loader-item"}>
                    <Loader type="spinner-circle" bgColor={"#FFFFFF"} color={'#FFFFFF'} size={150} />
                </div>
            ) : (
                <>
                    <div className="wrapper">
                        <VendorHeader />
                        <section className="vendor-banner">
                            <div className="vendor-banner-content">
                                <figure>
                                    <img src={(vendorDetails && vendorDetails.user_information) ? vendorDetails.user_information.cover_photo : "../img/default-cover.png"} alt="Vendor Profile View To Consumer" />
                                </figure>
                            </div>
                        </section>
                        <section className="vendor-consumer-detail-wr">
                            <div className="container">
                                <div className="vendor-consumer-detail-content">
                                    <div className="vendor-consumer-img-name">
                                        <figure>
                                            <img src={(vendorDetails) ? vendorDetails.profile : "../img/default-profile-picture.png"} alt="profileImage" />
                                        </figure>
                                        
                                        <h3 className="common-label">{(vendorDetails.business_name) ? vendorDetails.business_name : "VENDOR BUSINESS NAME"}</h3>

                                        {vendorDetails?.vendor_category?.length > 0 ? (
                                            <ul style={{ listStyleType: 'disc' }}>
                                                <li style={{ display: "none" }}></li>
                                                {vendorDetails?.vendor_category?.map((categoryList, index) => {
                                                    return (
                                                        <li key={index}>{categoryList?.category?.category_name}</li>
                                                    );
                                                })}
                                            </ul>
                                        ) : (
                                            <ul style={{ listStyleType: 'disc' }}>
                                                <li style={{ display: "none" }}></li>
                                                <li>PHOTOGRAPHY AND VIDEOGRAPHY</li>
                                            </ul>
                                        )}

                                        <h4 className="event-title mt-5">Event Types</h4>
                                        {vendorDetails?.vendor_event_type?.length > 0 ? (
                                            <ul>
                                                <li style={{ display: "none" }}></li>
                                                {vendorDetails?.vendor_event_type?.map((event,index) => {
                                                    return (
                                                        <>
                                                            <li key={index}> {event.event_type?.event_type} </li>
                                                        </>
                                                    );
                                                })}
                                            </ul>
                                        ) : (
                                            <ul>
                                                <li style={{ display: "none" }}></li>
                                                <li>Wedding</li>
                                                <li style={{ display: "none" }}></li>
                                                <li>Corporate</li>
                                            </ul>
                                        )}
                                    </div>
                                    <div className="vendor-consumer-reviews-block">
                                        <div className="vendor-consumer-reviews-top">
                                            <div className="vendor-consumer-like-btn">
                                                <a href="#!" title="Likes" >
                                                    <><span>Like </span> 0</>
                                                </a>
                                            </div>
                                            <div className="vendor-total-views-block">
                                                <figure>
                                                    <img src="../img/view_eye_icon.svg" alt="img" />
                                                </figure>
                                                <div className="vendor-total-views">
                                                    <p>0</p>
                                                </div>
                                            </div>
                                            <div className="vendor-review-send-btn">
                                                <button className="btn">Write a review</button>
                                                <button className="btn" >Send Message</button>
                                            </div>
                                        </div>
                                        <div className="vendor-consumer-reviews-bottom">
                                            <div className="vendor-consumer-rating">
                                                <p style={{ marginRight: "7px", marginBottom: "4px" }}>0</p>
                                                {/* <p> */}
                                                {/* <Rating readonly initialValue={(vendorDetails?.avgRating) ? vendorDetails?.avgRating : 0} /> */}
                                                <StarRating
                                                    rating={0}
                                                    starRatedColor="skyblue"
                                                    starEmptyColor="gray"
                                                    numberOfStars={5}
                                                    starSpacing="5px"
                                                    starDimension="20px"
                                                />
                                                {/* </p> */}
                                            </div>
                                            <div className="vendor-consumer-total-review">
                                                <p>0 Reviews</p>
                                            </div>
                                            <div className="report-content">
                                                <div className="review-tooltip flag-content">
                                                    <img src='/img/icons8-flag-16.png' alt="new" />
                                                    <p style={{ marginRight: "5px", marginBottom: "2px" }}>Report</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {vendorDetails?.vendor_category?.length > 0 && vendorDetails?.vendor_category && (
                                        // <div className="vendor-child-friendly-block">
                                        <div className={(vendorDetails.vendor_category.findIndex(categoryData => categoryData.category.category_name === 'Venues' && categoryData?.extra_info?.length) !== -1) ? "vendor-child-friendly-block" : "vendor-child-friendly-inner"}>
                                            <ul>
                                                {vendorDetails.vendor_category.map((categoryData,index) => {
                                                    if (categoryData.category.category_name === 'Venues' && categoryData.extra_info !== null) {
                                                        return (
                                                            <>
                                                                {categoryData.extra_info && categoryData.extra_info.length > 0 && categoryData.extra_info.includes('1') &&
                                                                    <li key={index}>
                                                                        <img src="../img/Vegetarian.svg" alt="" />
                                                                        <span>Vegan option</span>
                                                                    </li>
                                                                }
                                                                {categoryData.extra_info && categoryData.extra_info.length > 0 && categoryData.extra_info.includes('2') &&
                                                                    <li>
                                                                        <img src="../img/Vegetarian.svg" alt="" />
                                                                        <span>Vegetarian option</span>
                                                                    </li>
                                                                }
                                                                {categoryData.extra_info && categoryData.extra_info.length > 0 && categoryData.extra_info.includes('3') &&
                                                                    <li>
                                                                        <img src="../img/Child-Friendly.svg" alt="" />
                                                                        <span>Child-friendly</span>
                                                                    </li>
                                                                }
                                                                {categoryData.extra_info && categoryData.extra_info.length > 0 && categoryData.extra_info.includes('4') &&
                                                                    <li>
                                                                        <img src="../img/Wheelchair_Access.svg" alt="" />
                                                                        <span>Wheelchair access</span>
                                                                    </li>
                                                                }
                                                                {categoryData.extra_info && categoryData.extra_info.length > 0 && categoryData.extra_info.includes('5') &&
                                                                    <li>
                                                                        <img src="../img/Vegetarian.svg" alt="" />
                                                                        <span>Dairy free option</span>
                                                                    </li>
                                                                }
                                                                {categoryData.extra_info && categoryData.extra_info.length > 0 && categoryData.extra_info.includes('6') &&
                                                                    <li>
                                                                        <img src="../img/Vegetarian.svg" alt="" />
                                                                        <span>Gluten free option</span>
                                                                    </li>
                                                                }
                                                                {categoryData && categoryData.min &&
                                                                    <li>
                                                                        <img src="../img/50_seats.svg" alt="" />
                                                                        <span>{categoryData.max} Seats</span>
                                                                    </li>
                                                                }
                                                            </>
                                                        );
                                                    } else {
                                                        return null
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                    <div className="vendor-consumer-detail-para">
                                        <ShowMoreText
                                            /* Default options */
                                            lines={4}
                                            more="Read more"
                                            less="Read less"
                                            className="content-css"
                                            anchorclassName="show-more-less-clickable"
                                            expanded={false}
                                            // width={280}
                                            truncatedEndingComponent={"... "}
                                        >
                                        {(description) ? 
                                            <>
                                                {profileDecription.map((line, index) => (
                                                    <span key={index}>{line}<br /></span>
                                                ))}
                                            </>
                                        : 
                                            <span>
                                                Headquartered in Sydney Australia, OzGigs is the brainchild of Cavan Te Ratana, a professional singer with over 30 years of experience in the Australian events industry.
                                                As a service provider Cavan felt the need for a dedicated event supplier marketplace. One that will offer affordability to vendors listing their services, combined with a convenient and easy way for consumers to search, find and connect.
                                            </span>
                                        }
                                        </ShowMoreText>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="area-operates-wr">
                            <div className="container">
                                <div className="area-operates-content">
                                    <div className="area-operates-heading">
                                        <h3 className="common-label">Areas where {(vendorDetails.business_name) ? vendorDetails.business_name : "BUSINESS NAME"} operates</h3>
                                    </div>
                                    {vendorDetails?.vendor_regions?.length > 0 ? (
                                        <div className="area-operates-list">
                                            <ul>
                                                {vendorDetails?.vendor_regions?.map((regions,index) => {
                                                    return (
                                                        <>
                                                            <li key={index}>{regions.name}</li>
                                                        </>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    ) : (
                                        <div className="area-operates-list">
                                            <ul>
                                                <li>GREATER SYDNEY AREA</li>
                                                <li>HUNTER</li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Photo's Section */}
                            <div className="area-operates-slider-block container my-5">
                                {vendorDetails?.vendor_photos && vendorDetails.vendor_photos.length > 0 && vendorDetails.vendor_photos.find(photo => photo.type === 'image') ? (
                                    <div className="area-operates-slider-inner mySwiper" style={{ display: vendorDetails?.vendor_photos && vendorDetails.vendor_photos[0]?.type === 'image' ? 'block' : 'none' }}>
                                        <div className="area-operates-slider swiper-wrapper">
                                            <OwlCarousel className="area-operates-slider-content swiper-slide owl-carousel owl-theme owl-carousel-one owl-loaded owl-drag"
                                                loop
                                                margin={30}
                                                nav
                                                {...optionPhotos}
                                            >
                                                {vendorDetails.vendor_photos.map((photo) => {
                                                    if (photo.type === "image") {
                                                        return (
                                                            <div className='item' key={photo.id} style={{backgroundImage: `url(${photo.photos})`}}>
                                                                <img src={photo.photos} alt="vendorimage" className="vendor-photo-carousel" />
                                                            </div>
                                                        );
                                                    }
                                                    return null; // Ignore non-image photos
                                                })}
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="area-operates-slider-inner mySwiper">
                                        <div className="area-operates-slider swiper-wrapper">
                                            <OwlCarousel
                                                className="area-operates-slider-content swiper-slide owl-carousel owl-theme owl-carousel-one owl-loaded owl-drag"
                                                loop
                                                margin={30}
                                                nav
                                                {...optionPhotos}
                                            >
                                                <div className='item'>
                                                    <img src="../img/Photo1-Sample.jpg" alt="vendorProfile1" className="vendor-photo-carousel" />
                                                </div>
                                                <div className='item'>
                                                    <img src="../img/Photo2Square-Sample.jpg" alt="vendorProfile2" className="vendor-photo-carousel" />
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Video's Section */}
                            {vendorDetails?.vendor_photos?.length > 0 && vendorDetails?.vendor_photos.find((ele) => ele.type === 'video') ? (
                                <section className="vendor-popup-slider-wr">
                                    <div className="container">
                                        <div className="vendor-popup-slider-inner">
                                            {vendorDetails?.vendor_photos?.length > 0 && (
                                                <OwlCarousel className="vendor-popup-slider owl-carousel owl-theme owl-carousel-two" loop items={1} nav>
                                                    {vendorDetails?.vendor_photos?.map((photosMap, i) => {
                                                        if (photosMap.type === "video") {
                                                            return (
                                                                <>
                                                                    <div className='vendor-popup-slider-content' key={photosMap.id}>
                                                                        <figure style={{ aspectRatio: '16/9' }}>
                                                                            <video ref={el => videoRef.current[i] = el} id={photosMap.id}  onPlay={(e) => handlePlay(e, videoRef.current[i])} controls controlsList="nodownload noplaybackrate" disablePictureInPicture>
                                                                                <source src={(photosMap && photosMap.photos) ? photosMap.photos : "./img/video-bg.png"} type="video/mp4" />
                                                                            </video>
                                                                            <figcaption>
                                                                                <a href="#!" data-id={photosMap.photos} onClick={(e) => handlePlayVideoImg(e, photosMap.id , videoRef.current[i],'video')}>
                                                                                    <img src="../img/Play_icon.svg" alt="Play icon" />
                                                                                </a>
                                                                            </figcaption>
                                                                        </figure>
                                                                    </div>
                                                                </>
                                                            );
                                                        }
                                                    })}
                                                </OwlCarousel>
                                            )}
                                        </div>
                                    </div>
                                </section>
                            ) : (
                                <section className="vendor-popup-slider-wr">
                                    <div className="container">
                                        <div className="vendor-popup-slider-inner">
                                            <OwlCarousel className="vendor-popup-slider owl-carousel owl-theme owl-carousel-two" loop items={1} nav>
                                                <div className='vendor-popup-slider-content' >
                                                    <figure>
                                                        <img src="../img/PromoVideo-Sample.jpg" alt="PopupVideo" />
                                                        <figcaption>
                                                            <a href="#!" >
                                                                <img src="../img/Play_icon.svg" alt="Play icon" />
                                                            </a>
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                </section>
                            )}

                            {/* Landscape Reel */}
                            <section className="vendor-popup-slider-wr container">
                                <div className="landscape-reels">
                                    <h3 className="common-label mt-5">Landscape Reel</h3>
                                    <div className="vendor-popup-slider-inner">
                                    {(landscapeReel && landscapeReel.length > 0) ?    
                                        <div className='vendor-popup-slider-content vendor-popup-slider-content-reels' key={landscapeReel[0]?.id}>
                                            <figure>
                                                {/* <video ref={videoRellRef} onPlay={handlePlayReel} controls controlsList="nodownload noplaybackrate" disablePictureInPicture>
                                                    <source src={(landscapeReel && landscapeReel[0]?.reel_add) ? landscapeReel[0]?.reel_add : "./img/Landscape-Reel-Sample.jpg"} type="video/mp4" />
                                                </video>

                                                <figcaption>
                                                    <a href="#!" data-id={landscapeReel[0]?.reel_add} onClick={handlePlayImg}>
                                                        <img src="../img/Play_icon.svg" alt="Play icon" />
                                                    </a>
                                                </figcaption> */}

                                                <video ref={el => landscapeRef.current = el} id={landscapeReel[0]?.id}  onPlay={(e) => handlePlay(e, landscapeRef.current,'landscapeReel')} controls controlsList="nodownload noplaybackrate" disablePictureInPicture>
                                                    <source src={(landscapeReel[0] && landscapeReel[0]?.reel_add) ? landscapeReel[0]?.reel_add : "./img/video-bg.png"} type="video/mp4" />
                                                </video>

                                                <figcaption>
                                                    <a href="#!" data-id={landscapeReel[0]?.reel_add} onClick={(e) => handlePlayVideoImg(e, landscapeReel[0]?.id , landscapeRef.current, 'landscapeReel')}>
                                                        <img src="../img/Play_icon.svg" alt="Play icon" />
                                                    </a>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    :  
                                        <div className='vendor-popup-slider-content vendor-popup-slider-content-reels' >
                                            <figure>
                                                <img src="../img/Landscape-Reel-Sample.jpg" alt="PopupVideo" />
                                                <figcaption>
                                                    <a href="#!" >
                                                        <img src="../img/Play_icon.svg" alt="Play icon" />
                                                    </a>
                                                </figcaption>
                                            </figure>
                                        </div>
                                    }                
                                    </div>
                                </div>
                            </section>

                            {/* Portrait Reel */}
                            <section className="vendor-popup-slider-wr container">
                                <div className="landscape-reels">
                                    <h3 className="common-label mt-5">Portrait Reel</h3>
                                    <div className="vendor-popup-slider-inner">
                                        {(portraitReel && portraitReel.length > 0) ? 
                                            <div className='vendor-popup-slider-content vendor-popup-slider-content-reels' key={portraitReel[0]?.id}>
                                                <figure>
                                                    {/* <video ref={videoRellRef} onPlay={handlePlayReel} controls controlsList="nodownload noplaybackrate" disablePictureInPicture>
                                                        <source src={(portraitReel && portraitReel[0]?.reel_add) ? portraitReel[0]?.reel_add : "./img/PortraitVideo-sample.jpg"} type="video/mp4" />
                                                    </video>

                                                    <figcaption>
                                                        <a href="#!" data-id={portraitReel[0]?.reel_add} onClick={handlePlayImg}>
                                                            <img src="../img/Play_icon.svg" alt="Play icon" />
                                                        </a>
                                                    </figcaption> */}

                                                    <video ref={el => portraitRef.current = el} id={portraitReel[0]?.id}  onPlay={(e) => handlePlay(e, portraitRef.current,'portraitReel')} controls controlsList="nodownload noplaybackrate" disablePictureInPicture>
                                                        <source src={(portraitReel[0] && portraitReel[0]?.reel_add) ? portraitReel[0]?.reel_add : "./img/video-bg.png"} type="video/mp4" />
                                                    </video>

                                                    <figcaption>
                                                        <a href="#!" data-id={portraitReel[0]?.reel_add} onClick={(e) => handlePlayVideoImg(e, portraitReel[0]?.id , portraitRef.current, 'portraitReel')}>
                                                            <img src="../img/Play_icon.svg" alt="Play icon" />
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        :
                                            <div className='vendor-popup-slider-content vendor-popup-slider-content-reels' >
                                                <figure>
                                                    <img className="portraitSample" src="../img/PortraitVideo-sample.jpg" alt="PopupVideo" />
                                                    <figcaption>
                                                        <a href="#!" >
                                                            <img src="../img/Play_icon.svg" alt="Play icon" />
                                                        </a>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </section> 
                            
                            {/* review section start */}
                            <div className="review-sec vendor-video-carousel fadeIn wow">
                                <div className="container">
                                    <div className="review-inner common-inner-container">
                                        <h3>REVIEWS</h3>
                                        <div className="review-carousel">
                                            <OwlCarousel className="owl-carousel owl-theme owl-carousel-three"
                                                {...optionReel}
                                            >
                                                {vendorDetails?.user_review?.length > 0 ? (
                                                    vendorDetails.user_review.map((review,index) => {
                                                    return (
                                                        <>
                                                            <div className="item" key={index}>
                                                                <div className="review-card">
                                                                    <div className="review-card-head">
                                                                        <span className="review-card-img">
                                                                            <img className="reviewer-profile" src={(review?.user_details) ? review?.user_details?.profile : "./img/v-profile.png"} />
                                                                        </span>
                                                                    </div>
                                                                    <div className="review-card-body">
                                                                        <p className="name">{review?.user_details?.first_name} {review?.user_details?.last_name}</p>
                                                                        <ul>
                                                                            <li>
                                                                                {/* <Rating readonly initialValue={(review?.rating) ? review?.rating : 0} /> */}
                                                                                <StarRating
                                                                                    rating={(review?.rating) ? Number(review?.rating) : 0}
                                                                                    starRatedColor="skyblue"
                                                                                    starEmptyColor="gray"
                                                                                    numberOfStars={5}
                                                                                    starSpacing="5px"
                                                                                    starDimension="20px"
                                                                                />
                                                                            </li>
                                                                        </ul>
                                                                        <span>Review</span> <p className="desc"> {review?.review} </p>
                                                                        <span>Reply</span>  <p className="desc"> {(review?.reply) ? review?.reply : 'N/A'} </p>
                                                                        {/* <p className="desc">management companies (Planners) with event service providers (Vendors)</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                })
                                                
                                                ): (
                                                    <>
                                                        <div className="item">
                                                            <div className="review-card">
                                                                <div className="review-card-head my-3">
                                                                    <span className="review-card-img">
                                                                        <img className="reviewer-profile" src="../img/v-profile.png" alt="review" />
                                                                    </span>
                                                                </div>
                                                                <div className="review-card-body">
                                                                    <p className="name">User 1</p>
                                                                    <ul>
                                                                        <li>
                                                                            {/* <Rating readonly initialValue={(review?.rating) ? review?.rating : 0} /> */}
                                                                            <StarRating
                                                                                rating={3}
                                                                                starRatedColor="skyblue"
                                                                                starEmptyColor="gray"
                                                                                numberOfStars={5}
                                                                                starSpacing="5px"
                                                                                starDimension="20px"
                                                                            />
                                                                        </li>
                                                                    </ul>
                                                                    <span>Review</span> <p className="desc"> Nice Profile </p>
                                                                    <span>Reply</span>  <p className="desc"> Thanks for your review </p>
                                                                    {/* <p className="desc">management companies (Planners) with event service providers (Vendors)</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="item">
                                                            <div className="review-card">
                                                                <div className="review-card-head my-3">
                                                                    <span className="review-card-img">
                                                                        <img className="reviewer-profile" src="../img/vendor_consumer_proifile_img.png" alt="review" />
                                                                    </span>
                                                                </div>
                                                                <div className="review-card-body">
                                                                    <p className="name">User 2</p>
                                                                    <ul>
                                                                        <li>
                                                                            {/* <Rating readonly initialValue={(review?.rating) ? review?.rating : 0} /> */}
                                                                            <StarRating
                                                                                rating={5}
                                                                                starRatedColor="skyblue"
                                                                                starEmptyColor="gray"
                                                                                numberOfStars={5}
                                                                                starSpacing="5px"
                                                                                starDimension="20px"
                                                                            />
                                                                        </li>
                                                                    </ul>
                                                                    <span>Review</span> <p className="desc"> Great </p>
                                                                    <span>Reply</span>  <p className="desc"> Thanks for your review </p>
                                                                    {/* <p className="desc">management companies (Planners) with event service providers (Vendors)</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="item">
                                                            <div className="review-card">
                                                                <div className="review-card-head my-3">
                                                                    <span className="review-card-img">
                                                                        <img className="reviewer-profile" src="../img/profileDashboard.png" alt="review" />
                                                                    </span>
                                                                </div>
                                                                <div className="review-card-body">
                                                                    <p className="name">User 3</p>
                                                                    <ul>
                                                                        <li>
                                                                            {/* <Rating readonly initialValue={(review?.rating) ? review?.rating : 0} /> */}
                                                                            <StarRating
                                                                                rating={4}
                                                                                starRatedColor="skyblue"
                                                                                starEmptyColor="gray"
                                                                                numberOfStars={5}
                                                                                starSpacing="5px"
                                                                                starDimension="20px"
                                                                            />
                                                                        </li>
                                                                    </ul>
                                                                    <span>Review</span> <p className="desc"> Absolutely fabulous! </p>
                                                                    <span>Reply</span>  <p className="desc"> Thanks for your review </p>
                                                                    {/* <p className="desc">management companies (Planners) with event service providers (Vendors)</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </OwlCarousel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="vendor-consumer-links">
                            <div className="container">
                                <div className="vendor-consumer-links-inner">
                                    <div className="vendor-consumer-links-heading">
                                        <h3 className="common-label">links</h3>
                                    </div>
                                    <div className="vendor-consumer-social-links">
                                        {vendorDetails?.vendor_link?.length > 0 ? (
                                            vendorDetails.vendor_link.map((links,index) => {
                                                return (
                                                    <>
                                                        {(links.type === 'website') ?
                                                            <Link>
                                                            <img src="../img/vendor_website.svg" alt="Instagram logo" key={index}/>
                                                            </Link>
                                                        : ''}
                                                        {(links.type === 'instagram') ?
                                                            <Link>
                                                            <img src="../img/vendor_Instagram.svg" alt="Tik Tok logo" key={index}/>
                                                            </Link>
                                                        : ''}
                                                        {(links.type === 'tiktok') ?
                                                            <Link>
                                                            <img src="../img/vendor_tik_tok.svg" alt="Facebook logo" key={index}/>
                                                            </Link>
                                                        : ''}
                                                        {(links.type === 'facebook') ?
                                                            <Link>
                                                            <img src="../img/vendor_Facebook.svg" alt="Youtube logo" key={index}/>
                                                            </Link>
                                                        : ''}
                                                        {(links.type === 'youtube') ?
                                                            <Link>
                                                            <img src="../img/vendor_Youtube.svg" alt="Website" key={index}/>
                                                            </Link>
                                                        : ''}
                                                    </>)
                                            })) : (
                                            <p>There are no social media links. You can visit the vendor's profile to update their links.</p>
                                        )}
                                    </div>
                                </div>
                            </div>

                        </section>
                        <div className="bottom-btn fadeInUp">
                            <Link className="btn" to={'/vendor-profile'}>
                                Continue Profile Setup
                            </Link>
                        </div>
                    </div>

                    <Footer />
                </>
            )}
        </>
    )
}

export default VendorPreview;