import React, { useState, useEffect } from "react";
import categoryAction from "../../actions/categoryAction";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';

const Footer = ({getCategory, isHomePage}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categoryList8, setCategory8] = useState([]);
  const [categoryList7, setCategory7] = useState([]);
  const [isVendorMenuOpen, setIsVendorMenuOpen] = useState(false);
  const [isCompanyMenuOpen, setIsCompanyMenuOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  const toggleVendorAccordion = () => {
    setIsCompanyMenuOpen(false)
    setIsVendorMenuOpen(prevState => !prevState);
  };
  const toggleCompanyAccordion = () => {
    setIsVendorMenuOpen(false)
    setIsCompanyMenuOpen(prevState => !prevState);
  };

  useEffect(() => {
    dispatch(categoryAction());
    const userInfo = JSON.parse(localStorage.getItem("userData"));
    setUserInfo(userInfo?.data?.data)
  }, []);
 

  // get alll the category list with there subcategories
  const category = useSelector((state) => state.category.category);
  useEffect(() => {
    if (category && category.datasss && category.datasss.status === true) {
       // Split the array into two columns
      const column1Items = category.datasss.data.slice(0, 9);
      setCategory8(column1Items)
      const column2Items = category.datasss.data.slice(9, 17);
      setCategory7(column2Items)
    }
  }, [category]);

  return (
    <>
      <div className="wrapper zindex_set">
        <div className="footer-upper fadeIn wow">
          <div className="footer">
            <div className="container">
              <div className="row footer-row">
                <div className="col-md-4">
                  <div className="footer-brand-sec">
                    <a className="footer-brand " href="#;">
                      <img src="/img/logo.svg" alt="ozgigs" />
                    </a>
                    <p className="footer-data">
                      Ozgigs is an online marketplace for the event’s industry. It’s a free and fast way for you to find services for your event.
                      <br/>
                      <br/>
                      We acknowledge the Traditional Custodians of the lands we live on. We pay our respects to all Elders, past and present, of all Aboriginal and Torres Strait Islander nations.
                    </p>
                    
                    <ul className="list-unstyled social-links-sec">
                      <li>
                        <a target="_blank" href="https://instagram.com/ozgigsevents/">
                          <img src="/img/insta.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://tiktok.com/@ozgigs">
                          <img src="/img/tiktok.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://facebook.com/ozgigs">
                          <img src="/img/fb.svg" alt="" />
                        </a>
                      </li>
                      <li>
                        <a target="_blank" href="https://youtube.com/@ozgigs">
                          <img src="/img/yt.svg" alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="footer-list-sec footer-accordion">
                    <div className="acc-container">
                      <h5 className="footer-list-heading" onClick={toggleVendorAccordion}>VENDORS</h5>
                      <div className={isVendorMenuOpen ? 'content show' : 'content hide'}>
                        <ul className="list-unstyled">
                            {categoryList8.length > 0 && categoryList8.map((list,index) => (
                              <li key={index}>
                                  <a href="#" onClick={()=> {
                                    if(isHomePage){
                                      getCategory(list.id)
                                    }
                                    if(window.location.pathname !== "/consumer-home"){
                                      if(userInfo && userInfo?.user){
                                        navigate("/consumer-home");
                                      }else{
                                        navigate("/home");
                                      }
                                      window.localStorage.setItem('cat_item', JSON.stringify(list.id))
                                    }
                                    
                                    }}>{list.category_name}</a>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mt-4">
                  <div className="footer-list-sec footer-accordion">
                    <div className="acc-container">
                      {/* <h5 className="footer-list-heading"></h5> */}
                      <div className={isVendorMenuOpen ? 'content show' : 'content hide'}>
                        <ul className="list-unstyled py-0" >
                            {categoryList7.length > 0 && categoryList7.map((list, index) => (
                              <li key={index}>
                                  <a href="#" onClick={()=> {
                                    if(isHomePage){
                                      getCategory(list.id)
                                    }
                                    if(window.location.pathname !== "/consumer-home"){
                                      if(userInfo && userInfo?.user){
                                        navigate("/consumer-home");
                                      }else{
                                        navigate("/home");
                                      }
                                      window.localStorage.setItem('cat_item', JSON.stringify(list.id))
                                    }
                                    
                                  }}>{list.category_name}</a>
                                  {/* <HomeComponent categoryName={list.category_name} /> */}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="footer-list-sec footer-accordion">
                    <div className="acc-container">
                      <h5 className="footer-list-heading" onClick={toggleCompanyAccordion}>Company</h5>
                      <div className={isCompanyMenuOpen ? 'content show' : 'content hide'}>
                        <ul className="list-unstyled">
                          <li>
                          <Link target="_blank" to="/faq#AboutUs">About us</Link>
                          </li>
                          <li>
                          <Link target="_blank" to="/faq#Support">Support</Link>
                          </li>
                          <li>
                          <Link target="_blank" to="/faq#Faq">FAQ</Link>
                          </li>
                          <li>
                          <Link target="_blank" to="/vendor-information-page">Vendor Information</Link>
                          </li>
                          <li>
                          <Link target="_blank" to="https://www.ozgigsblog.com.au">BLOG</Link>
                          </li>
                          <li>
                          <Link target="_blank" to="/faq#Support" >Contact us </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div className="copyright text-center">
                {/* Copyright &copy; {new Date().getFullYear()}, made with{" "}
                <i className="fa fa-heart heart"></i> by{" "}
                <a href="https://intuitionsoftech.com.au/">Intuition SofTech</a> */}
                © 2023 Ozgigs Global Pty Ltd<br/>
                <a target="_blank" href="/terms-and-conditions"> Terms and Conditions | </a><a target="_blank" href="/privacy-policy">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
