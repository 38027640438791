import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';


const ShareButtons = (props) => {
  const shareButtonsRef = useRef(null);
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  const port = window.location.port;
  const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

  useEffect(() => {
    if (shareButtonsRef.current) {
      window.__sharethis__.load('inline-share-buttons', {
        alignment: 'left',
        id: `my-inline-buttons${props.dataId}`,
        enabled: true,
        font_size: 11,
        padding: 8,
        radius: 0,
        networks: ['facebook', 'whatsapp', 'sms','email'],
        size: 32,
        show_mobile_buttons: true,
        spacing: 0,
        url: baseUrl + "/vendor-detail/" + props.reelsData, // custom url
        title: "My Custom Title",
        image: "https://18955-presscdn-pagely.netdna-ssl.com/wp-content/uploads/2016/12/ShareThisLogo2x.png", // useful for pinterest sharing buttons
        description: "My Custom Description",
        username: "ShareThis" // custom @username for twitter sharing
      });
    }
  }, [shareButtonsRef]);

  const handleCopyURL = () => {
    navigator.clipboard.writeText(baseUrl + "/vendor-detail/" + props.reelsData)
    toast.success('Copied Successfully')
  };

  return (
    <div style={{ display: 'flex' }}>
      <div ref={shareButtonsRef} id={"my-inline-buttons"+props.dataId}></div>
      <div className='copy-st-btn'>
        <img style={{width:'15px'}} onClick={handleCopyURL} src='./img/copy-25.png' />
      </div>
    </div>
  );
}

export default ShareButtons;