import $, { get } from "jquery";
import Loader from "react-js-loader";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Link, useParams } from "react-router-dom";
import VendorHeader from "./includes/header";
import VendorSidebar from "./includes/sidebar";
import VendorFooter from "../includes/footer";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import VendorProfileAction from "../../actions/VendorProfileAction";
import eventTypeAction from "../../actions/eventTypeAction";
import categoryAction from "../../actions/categoryAction";
import sessionRetrive from "../../actions/sessionRetrive";
import regionAction from "../../actions/regionAction";
import updateVendorAction from "../../actions/updateVendor";
import clearUpdateVendorMsg from "../../actions/updateVendor";
import catCheckoutSession from "../../actions/categoryCheckoutSessionAction";
import Select from "react-select";
import OwlCarousel from "react-owl-carousel";
import AWS from "aws-sdk";
import axios from "axios";

const VendorUpdate = () => {
    const updateVendor = useSelector((state) => state.updateVendor.updateVendor);
    const vendorProfile = useSelector(
        (state) => state.vendorProfile?.vendorProfile
    );

    const [profilePicture, setProfilePicture] = useState("");
    const [cover_photo, setCoverPhoto] = useState("");
    const [vendor_event_type, setVendorEventType] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [vendor_photos, setVendorPhotos] = useState([]);
    const [delete_photos, setDeletePhotos] = useState([]);

    const [videos, setVideos] = useState([]);
    const [portraitReel, setPortraitReel] = useState("");
    const [landscapeReel, setLandscapeReel] = useState("");
    const [reels, setReels] = useState([]);
    const [vendor_reels, setVendorReels] = useState([]);
    const [deletedReels, setDeletedReels] = useState([]);

    const [minimum, setMinimum] = useState([]);
    const [maximum, setMaximum] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [error, setError] = useState(false);
    const [vendorInfo, setVendorInfo] = useState([]);
    const [eventTypeList, setEventType] = useState([]);
    const [categoryList, setCategory] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [regionFlag, setRegionFlag] = useState(true);

    const [cat, setCat] = useState([]);
    const [extraInfo, setExtraInfo] = useState([]);
    const [checkedSubcat, setCheckedSubCat] = useState([]);

    const [vendorData, setVendorData] = useState({});

    const [loading, setLoading] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [selectedVideos, setSelectedVideos] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const [websiteErr, setWebsiteErr] = useState("");
    const [instaErr, setInstaErr] = useState("");
    const [youtubeErr, setYoutubeErr] = useState("");
    const [tiktokErr, setTikTokErr] = useState("");
    const [facebookErr, setFacebookErr] = useState("");
    const checkboxesRef = useRef([]);
    const [showPayment, setShowPayment] = useState(false);
    const [payementCatId, setPayementCatId] = useState(null);
    const [selectExtraCat, setSelectExtraCat] = useState(false);
    const [catLength, setCatLength] = useState(false);
    const [disableCheck, setDisableCheck] = useState(false);
    const [selectDisabledCat, setSelectDisabledCat] = useState([]);
    const [profileStep, setProfileStep] = useState(null);
    // const [vendor_links, setVendorlinks] = useState([])
    const [promoVideoCount, setPromoVideoCount] = useState(0);
    const [promoPhotoCount, setPromoPhotoCount] = useState(0);
    const [actionType, setActionType] = useState("");

    const [videoProgress, setVideoProgress] = useState(0);
    const [landscapeReelProgress, setLandscapeReelProgress] = useState(0);
    const [portraitReelProgress, setPortraitReelProgress] = useState(0);

    const [loadingVideoFile, setLoadingVideoFile] = useState(false);
    const [loadingPotrateReelFile, setLoadingPotrateReelFile] = useState(false);
    const [loadingLandReelFile, setLoadingLandReelFile] = useState(false);

    const [socialError, setSocialError] = useState({});
    const [isIos, setIsIos] = useState(false);
    const [isUnsaved, setIsUnsaved] = useState(false);

    const [intervalState, setIntervalState] = useState(null);
    const [loaderNew, setLoaderNew] = useState(0);    
    
    const containerRef = useRef(null);
    const difContainerRef = useRef(null);
    const locationContainerRef = useRef(null);
    const eventTypeContainerRef = useRef(null);
    const catContainerRef = useRef(null);

    const search = location.search;
    // const params = new URLSearchParams(search);
    const { id } = useParams();
    // const session_id = params.get('session_id');
    const [vendorLinks, setVendorLinks] = useState([
        {
            type: "website",
            links: "",
            pictureURL: "./img/web-svgrepo.svg",
            placeholder: "Example: ozgigs.com.au",
        },
        {
            type: "instagram",
            links: "",
            pictureURL: "./img/insta.svg",
            placeholder: "Example: instagram.com/ozgigsevents",
        },
        {
            type: "facebook",
            links: "",
            pictureURL: "./img/fb.svg",
            placeholder: "Example: facebook.com/ozgigs",
        },
        {
            type: "youtube",
            links: "",
            pictureURL: "./img/yt.svg",
            placeholder: "Example: youtube.com/@ozgigs",
        },
        {
            type: "tiktok",
            links: "",
            pictureURL: "./img/tiktok.svg",
            placeholder: "Example: tiktok.com/@ozgigs",
        },
    ]);

    const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_YOUR_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_YOUR_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_YOUR_REGION,
    });
    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    useEffect(() => {
        if (id) {
            let tokenObj = JSON.parse(localStorage.getItem("token"));
            const headers = {
                "content-type": "application/json",
                Authorization: `Bearer ${tokenObj.api_token}`,
            };
            let obj = {
                session_id: id,
            };
            axios
                .get(
                    `${process.env.REACT_APP_API_ENDPOINT}/api/session-retrive-category/${id}`,
                    { headers }
                )
                .then((response) => {
                    navigate("/vendor-profile");
                });
        }
    }, []);

    const initAll = () => {
        setCat([]);
        setVendorData({});
        setRegionList([]);
        setSelectedRegions([]);
        setRegionFlag(true);
        setVideos([]);
        setPortraitReel("");
        setLandscapeReel("");
        setReels([]);
        setVendorReels([]);
        setSelectedFile([]);
    };

    useEffect(() => {
        let tempVendorData = { ...vendorData };
        if (vendorProfile && vendorProfile.datasss) {
            tempVendorData.id = vendorProfile.datasss.data.id;
            tempVendorData.first_name = vendorProfile.datasss.data.first_name || "";
            tempVendorData.last_name = vendorProfile.datasss.data.last_name || "";
            tempVendorData.business_name = vendorProfile.datasss.data.business_name;
            tempVendorData.vendor_description =
                vendorProfile.datasss.data.user_information.profile_description;
            tempVendorData.vendor_regions = vendorProfile.datasss.data.vendor_regions;
            tempVendorData.starting_price =
                vendorProfile.datasss.data.user_information.starting_price;
            tempVendorData.reel_caption =
                vendorProfile.datasss.data.user_information.reel_caption;
        }
        if (
            vendorProfile &&
            vendorProfile.datasss &&
            vendorProfile.datasss.data.vendor_link
        ) {
            tempVendorData.vendor_links = vendorProfile?.datasss?.data?.vendor_link;
            let temp = [...vendorLinks];
            tempVendorData.vendor_links.map((value) => {
                const check = vendorLinks.findIndex((x) => x.type === value.type);
                if (check > -1) {
                    temp[check].links = value.links;
                }
            });
            setVendorLinks(temp);
        }
        setVendorData(tempVendorData);
    }, [vendorProfile]);

    useEffect(() => {
        if (regionFlag && vendorData && vendorData.vendor_regions) {
            let temp = [];
            vendorProfile?.datasss?.data?.vendor_regions.map((data) => {
                let obj = {
                    label: data.name,
                    value: data.region_id,
                };
                temp.push(obj);
            });
            setSelectedRegions(temp);
            setRegionFlag(false);
        }
    }, [vendorData]);

    useEffect(() => {
        initAll();
        dispatch(VendorProfileAction());
        dispatch(eventTypeAction());
        // dispatch(categoryAction());
        dispatch(regionAction());
    }, [dispatch]);

    //function for check category of subcatgeory and update it

    // const handleClick = (catid, subid) => {
    //     let obj = [...cat];
    //     let allCheckboxes = $('input[type=checkbox]');
    //     if (subid) {
    //         let data = cat.find((ele) => ele.category_id === catid);
    //         if (data === undefined) {
    //             obj.push({ category_id: catid });
    //         }
    //         $('#checkbox' + catid).prop('checked', true);

    //         let index = obj.findIndex(item => item.category_id === catid);

    //         if (obj[index].subcategory && obj[index].subcategory.length > 0) {
    //             if (!obj[index].subcategory.includes(subid)) {
    //                 obj[index].subcategory.push(subid);
    //             } else {
    //                 obj[index].subcategory.splice(obj[index].subcategory.indexOf(subid), 1);
    //                 checkedSubcat.splice(checkedSubcat.indexOf(subid));
    //             }
    //         } else {
    //             obj[index].subcategory = [];
    //             obj[index].subcategory.push(subid);
    //         }
    //         handleCategory(obj);
    //         allCheckboxes.not('#checkbox' + catid).prop('disabled', true);
    //     } else {
    //         let data = cat.find((ele) => ele.category_id === catid);
    //         if (data === undefined) {
    //             obj.push({ category_id: catid });
    //             $('#collapse' + catid + ' input:checkbox').prop('checked', true);

    //             $("#collapse" + catid).find('.subcategory_check').each(function () {
    //                 let subid = $(this).val();
    //                 let index = obj.findIndex(item => item.category_id === catid)
    //                 if (obj[index].subcategory && obj[index].subcategory.length > 0) {
    //                     if (!obj[index].subcategory.includes(subid)) {
    //                         obj[index].subcategory.push(parseInt(subid));
    //                     } else {
    //                         obj[index].subcategory.splice(obj[index].subcategory.indexOf(subid), 1);
    //                         checkedSubcat.splice(checkedSubcat.indexOf(subid));
    //                     }
    //                 } else {
    //                     obj[index].subcategory = [];
    //                     obj[index].subcategory.push(parseInt(subid));
    //                 }
    //                 handleCategory(obj);
    //             });
    //         } else {
    //             $('#collapse' + catid + ' input:checkbox').prop('checked', false);
    //             obj.splice(obj.findIndex(item => item.category_id === catid), 1);
    //         }

    //         handleCategory(obj);

    //         // Enable all categories
    //         allCheckboxes.prop('disabled', false);
    //     }
    // }
    
    // function to category subscription
    const handleCategoryPlan = (e) => {
        e.preventDefault();
        dispatch(catCheckoutSession({ category_id: payementCatId }));
    };
    const handleClick = (catid, subid, chn = 0) => {
        if (disableCheck) {
            return;
        }
        let updatedCategories = [...cat];
        let selectedCategory = updatedCategories.find(
            (ele) => ele.category_id === catid
        );
        if (selectedCategory) {
            // Category is already selected, unselect it
            updatedCategories = updatedCategories.filter(
                (ele) => ele.category_id !== catid
            );
        } else {
            if (!selectExtraCat && updatedCategories.length === 1) {
                setShowPayment(true); // Show payment button if it's the second category selection
                return;
            }
            if (updatedCategories.length >= 2) {
                return; // Maximum 2 categories can be selected
            }
            updatedCategories.push({ category_id: catid });
        }
        if(chn && (!selectedCategory || !updatedCategories.length))
            setIsUnsaved(true);
        setCat(updatedCategories);
    };
    const handleSelectSubCategory = (catid, subid) => {
        if (disableCheck) {
            return;
        }
        let updatedCategories = [...cat];
        let selectedCategory = updatedCategories.find(
            (ele) => ele.category_id === catid
        );
        // Check if extra category is already selected
        if (!selectExtraCat && selectedCategory === undefined && updatedCategories.length === 1) {
            return;
        }
        if (selectedCategory === undefined) {
            return;
        }
        let index = updatedCategories.findIndex(
            (item) => item.category_id === catid
        );

        if (selectedCategory.subcategory && selectedCategory.subcategory.length > 0) {
            if (!selectedCategory.subcategory.includes(subid)) {
                selectedCategory.subcategory.push(subid);
            } else {
                selectedCategory.subcategory = selectedCategory.subcategory.filter(
                    (sub) => sub !== subid
                );
            }
        } else {
            selectedCategory.subcategory = [];
            selectedCategory.subcategory.push(subid);
        }
        setCat(updatedCategories);
    };

    const handleCategory = (obj) => {
        setCat(obj);
    };

    // set vendor minimun maximum value for extra category
    const handleMinimum = (val, id) => {
        setMinimum(val);
        var obj = [...cat];
        var index = obj.findIndex((item) => item.category_id === 1);
        if (index > -1) {
            if (obj[index].min && obj[index].min !== "") {
                obj[index].min = val;
            } else {
                obj[index].min = val;
            }
        }
        handleCategory(obj);
    };
    const handleMaximum = (val, id) => {
        setMaximum(val);
        var obj = [...cat];
        var index = obj.findIndex((item) => item.category_id === 1);
        if (index > -1) {
            if (obj[index].max && obj[index].max !== "") {
                obj[index].max = val;
            } else {
                obj[index].max = val;
            }
        }
        handleCategory(obj);
    };

    // set vendor extra information of category
    const handleExtraInfo = (extraInfoId, value, id) => {
        // Create new copies of the arrays to avoid state mutation
        let obj = [...cat];
        let info = [...extraInfo];

        info.push(extraInfoId);
        let index = obj.findIndex((item) => item.category_id === id);
        if (index > -1) {
            if (obj[index].extra_info) {
                if (obj[index].extra_info.includes(extraInfoId)) {
                    let tempIndex = obj[index].extra_info.findIndex(
                        (x) => x === extraInfoId
                    );
                    // Create a new copy of the extra_info array without the removed element
                    obj[index].extra_info = obj[index].extra_info.filter(
                        (x) => x !== extraInfoId
                    );
                } else {
                    // Create a new copy of the extra_info array with the additional info
                    obj[index].extra_info = [...obj[index].extra_info, ...info];
                }
            } else {
                // Create a new copy of the extra_info array with the info
                obj[index].extra_info = info;
            }
        }
        setCat(obj);
    };

    const handleSelectCategoryId = (e, catId, checked) => {
        if (disableCheck) {
            return;
        }
        e.preventDefault();
        handleClick(catId, null,1);
        if (catId && checked === undefined) {
            // setShowPayment(true)
            setPayementCatId(catId);
        }
    };
    //  Set vendor event type
    const eventTypeClick = (id) => {
        var obj = [...vendor_event_type];
        if (id) {
            var data = vendor_event_type.find((ele) => ele.event_type === id);
            if (data === undefined) {
                obj.push({ event_type: id });
            } else {
                $(id + " input:checkbox").prop("checked", false);
                obj.splice(
                    obj.findIndex((item) => item.event_type === id),
                    1
                );
            }
            handleEventType(obj);
        }
    };
    const handleEventType = (obj) => {
        setVendorEventType(obj);
    };
    // check eventType onChange for isUnsaved flag
    const checkEvent = () => {
        setIsUnsaved(true);
    }
    // get all the event type for vendor
    const eventType = useSelector((state) => state.eventType.eventType);
    useEffect(() => {
        if (eventType && eventType.datasss && eventType.datasss.status === true) {
            setEventType(eventType.datasss.data);
        }
    }, [eventType]);

    // get alll the category list with there subcategories
    const category = useSelector((state) => state.category.category);
    useEffect(() => {
        if (category && category.datasss && category.datasss.status === true) {
            setCategory(category.datasss.data);
        }
    }, [category]);

    // get all the regions
    const regionsDtl = useSelector((state) => state?.region?.region);
    useEffect(() => {
        if (
            regionsDtl &&
            regionsDtl.datasss &&
            regionsDtl.datasss.status === true
        ) {
            setRegionList(regionsDtl.datasss.data);
        }
    }, [regionsDtl]);

    // Get vendor all information
    useEffect(() => {
        if (
            vendorProfile &&
            vendorProfile.datasss &&
            vendorProfile.datasss.status === true
        ) {
            setVendorInfo(vendorProfile.datasss.data);
            // set vendor event type ids in state
            let vendor_cat_subscription =
                vendorProfile.datasss.data.category_subscription;
            if (vendor_cat_subscription) {
                setShowPayment(false);
                setSelectExtraCat(true);
            }

            setProfileStep(vendorProfile.datasss.data?.step);

            let evntTypArr = vendorProfile.datasss.data.vendor_event_type;
            let arry = [];
            evntTypArr.map((x) => {
                arry.push({ event_type: x.event_type.id });
                eventTypeClick(x.event_type.id);
            });
            setVendorEventType(arry);

            // set vendor category in state
            let catArr = vendorProfile?.datasss?.data?.vendor_category;
            let tempArr = [];
            catArr.map((x) => {
                if (
                    x.vendor_subcategory &&
                    Array.isArray(x.vendor_subcategory) &&
                    x.vendor_subcategory.length > 0
                ) {
                    tempArr.push({
                        category_id: x.category_id,
                        subcategory: x.vendor_subcategory.map((y) => y.subcategory_id),
                        min: x.min,
                        max: x.max,
                        extra_info: x.extra_info,
                    });
                    if (x.category_id === 1) {
                        setMinimum(x.min);
                        setMaximum(x.max);
                    }
                    x.vendor_subcategory.map(async (y) =>
                        handleClick(x.category_id, y.subcategory_id)
                    );
                } else {
                    tempArr.push({
                        category_id: x.category_id,
                        min: x.min,
                        max: x.max,
                        extra_info: x.extra_info,
                    });
                    if (x.category_id === 1) {
                        setMinimum(x.min);
                        setMaximum(x.max);
                    }
                    handleClick(x.category_id);
                }
            });
            if (tempArr.length === 2) {
                setCatLength(true);
            }
            if(vendorInfo?.step === 0){
                setSelectDisabledCat(tempArr);
            }else{
                let newTempArr = [];
                newTempArr.push({
                    category_id: 0
                });
                setSelectDisabledCat(newTempArr);
            }
            setCat(tempArr);
            if (tempArr.length === 2) {
                setDisableCheck(true);
            }
            setVendorPhotos(vendorProfile?.datasss?.data?.vendor_photos);
            const promoPhoto = vendorProfile?.datasss?.data?.vendor_photos.filter(
                (x) => x.type === "image"
            );
            setPromoPhotoCount(promoPhoto.length);

            setVendorReels(vendorProfile?.datasss?.data?.reels);
            if (
                vendor_reels &&
                vendor_reels.length > 0 &&
                vendorProfile.datasss?.data?.reels.length > 0
            ) {
                let landscapeIndex = vendor_reels?.findIndex(
                    (reel) => reel?.type === "landscape"
                );
                let portraitIndex = vendor_reels?.findIndex(
                    (reel) => reel?.type === "portrait"
                );
                let landscapeData = [];
                if (landscapeIndex > -1) {
                    landscapeData.push({
                        id: vendor_reels[landscapeIndex]?.id,
                        file: vendor_reels[landscapeIndex]?.reel_add,
                        type: vendor_reels[landscapeIndex]?.type,
                    });
                }
                setLandscapeReel(landscapeData);
                let portraitData = [];
                if (portraitIndex > -1) {
                    portraitData.push({
                        id: vendor_reels[portraitIndex]?.id,
                        file: vendor_reels[portraitIndex]?.reel_add,
                        type: vendor_reels[portraitIndex]?.type,
                    });
                }
                setPortraitReel(portraitData);
                setReels([...landscapeData, ...portraitData]);
            } else {
                setPortraitReel("");
                setLandscapeReel("");
                setReels([]);
            }
            setIsUnsaved(false);
        }
    }, [vendorProfile, categoryList]);

    // update and setup vendor profile
    const handleUpdateVendor = async (e) => {
        // containerRef.current.scrollTop = containerRef.current.offsetTop;
        e.preventDefault();
        setLoadingForm(true);
        setIntervalState(setInterval(() => {
            setLoaderNew((count)=> count+1)
        }, 5000))
        if(actionType == 'live') {
            if (!vendorData.business_name || !vendorData.vendor_description || selectedRegions.length <= 0 || vendor_event_type.length <= 0 || cat.length <= 0 || reels.length <= 0 ) {
                setTimeout(() => {
                    if (containerRef && containerRef.current) {
                        containerRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        });
                    } else if (difContainerRef && difContainerRef.current) {
                        difContainerRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        });
                    } else if (locationContainerRef && locationContainerRef.current) {
                        locationContainerRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        });
                    } else if (eventTypeContainerRef && eventTypeContainerRef.current) {
                        eventTypeContainerRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        });
                    } else if (catContainerRef && catContainerRef.current) {
                        catContainerRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "start",
                        });
                    }
                }, 0);
                setLoadingForm(false);
                setError(true);
                return false;
            }
        }
        let tempFile = [];
        let tempReel = [];
        if (checkUrlValidation()) {
            if (selectedFile) {
                for (const file of selectedFile) {
                    const ext = file.file.name.split(".").pop(); // Get the original extension of the file
                    const uniqueString = `${Math.random()
                        .toString(36)
                        .substring(2, 14)}${Math.random().toString(36).substring(2, 14)}`;
                    const filename = `v_${uniqueString}.${ext}`;
                    const params = {
                        Bucket: process.env.REACT_APP_S3_BUCKET,
    
                        Key: `inputs/${filename}`,
                        Body: file.file,
                        ContentType: file.type,
                    };
                    try {
                        const data = await s3.upload(params).promise();
                        //videoArr.push(data.Location);
                        let obj = {
                            type: file.type,
                            file: data.Location,
                            size: file.size,
                        };
                        tempFile.push(obj);
                    } catch (err) {
                        toast.warn('Something went wrong. Please try again');
                        console.log("Error uploading file:", err);
                        setLoadingForm(false);
                        return;
                    }
                }
            }
            if (reels) {
                for (const file of reels) {
                    if (!file.id) {
                        const ext = file.file.name.split(".").pop(); // Get the original extension of the file
                        const uniqueString = `${Math.random()
                            .toString(36)
                            .substring(2, 14)}${Math.random()
                                .toString(36)
                                .substring(2, 14)}`;
                        const filename = `r_${uniqueString}.${ext}`;
                        const params = {
                            Bucket: process.env.REACT_APP_S3_BUCKET,
                            Key: `inputs/${filename}`,
                            Body: file.file,
                            ContentType: file.file.type,
                        };
    
                        try {
                            const data = await s3.upload(params).promise();
                            let obj = {
                                type: file.type,
                                file: data.Location,
                                size: file.size
                            };
                            tempReel.push(obj);
                        } catch (err) {
                            toast.warn('Something went wrong. Please try again');
                            console.log("Error uploading file:", err);
                            setLoadingForm(false);
                            return;
                        }
                    }
                }
            }
            // for vendor social links
            let tempArray = [...vendorLinks];
            const vendorUrlData = tempArray.filter((data) => data.links !== "");
            tempArray.forEach((element) => delete element.pictureURL);
            if (Object.keys(socialError).length > 0) {
                // toast.warn("Please enter valid social links")
            } else {
                dispatch(
                    updateVendorAction({
                        vendor_id: vendorData.id,
                        profile_picture: profilePicture,
                        cover_photo: cover_photo,
                        // first_name: vendorData.first_name,
                        // last_name: vendorData.last_name,
                        business_name: vendorData.business_name,
                        vendor_description: vendorData.vendor_description,
                        vendor_regions: selectedRegions,
                        starting_price: vendorData.starting_price,
                        vendor_event_type: vendor_event_type,
                        vendor_category: cat,
                        vendor_file: tempFile,
                        deleteFile: delete_photos,
                        reels: tempReel,
                        reel_caption: vendorData.reel_caption,
                        deleted_reels: deletedReels,
                        vendor_links: vendorUrlData,
                        actionType: actionType,
                    })
                );
                setTimeout(() => {
                    dispatch(VendorProfileAction());
                }, 2000);
            }
        } else {
            setLoadingForm(false);
            setError(true);
        }
    };
    
    // To set loading percentage count at final stage 
    useEffect(()=> {
        if(loaderNew >= 98 && loaderNew != 100){
            clearInterval(intervalState);
            setLoaderNew(99);
        }
    }, [loaderNew])
    
    useEffect(() => {
        var isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);
        setIsIos(isOnIOS);
        var key = localStorage.getItem("isunsaved")
        if(key){
            localStorage.removeItem("isunsaved") 
        }
    },[])

    const handleBeforeUnload = (e) => {
        if (isUnsaved) {
            e.preventDefault();
            e.returnValue = ''; // Required for some browsers
        }
    };
    var isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);
    // function for when we have unsaved changes to show warning popup
    const handlePageChange = (e = "") => {
        if(vendorInfo.step === 1){
            //save isUnsaved key in local.s as well for check Iphone
            localStorage.setItem("isunsaved" , "true");
            if(isOnIOS){
                if (isUnsaved && e != "") {
                    const confirmNavigation = window.confirm(
                        "Changes that you made may not be saved. Are you sure you want to leave?"
                    );
                    if (!confirmNavigation) {
                        return;
                    }else{
                        window.location.href = e; // Replace with your desired route
                    }
                }
            } else{
                window.addEventListener('beforeunload', handleBeforeUnload);
            }
        }
    }

    // Add event listener to confirm navigation away
    useEffect(() => {
        if(!isOnIOS && vendorInfo.step === 1 && isUnsaved == true ){
            window.addEventListener('beforeunload', handleBeforeUnload);
        }
        if(isUnsaved == true){
            handlePageChange();
        } 
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [isUnsaved]);


    useEffect(() => {
        if (
            updateVendor &&
            updateVendor?.datasss &&
            updateVendor?.datasss?.status === true
        ) {
            setLoaderNew(100);
            clearInterval(intervalState);
            dispatch(clearUpdateVendorMsg());
            if(actionType === 'preview') {
                navigate("/vendor-preview");
            }
            else {
                // for first time redirect while profile being live becouse it takes mussch time to redirect
                if(vendorInfo.step === 1 && actionType == 'live'){
                    setTimeout(() => {
                        setLoadingForm(false);
                        toast.success(updateVendor?.datasss?.message);
                        navigate("/vendor-dashboard"); 
                    }, 3000); 
                }else{
                    setTimeout(() => {
                        setLoadingForm(false);
                        toast.success(updateVendor?.datasss?.message);
                        navigate("/vendor-dashboard"); 
                    }, 1000); 
                }
            }
        }
    }, [updateVendor]);

    // if user redirect on vendor preview
    const previewRedirect = () => {
        navigate('/vendor-preview')
    }

    // use rejex form for urls
    const checkUrlValidation = () => {
        const regex =
            /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
        const tempArray = [];
        // setError(true);
        if (vendorData.website) {
            let urlValue = regex.test(vendorData.website);
            !urlValue ? setWebsiteErr("website") : setWebsiteErr("");
            tempArray.push(urlValue);
        } else {
            tempArray.push(true);
        }
        if (vendorData.facebook) {
            let urlValue = regex.test(vendorData.facebook);
            !urlValue ? setFacebookErr("facebook") : setFacebookErr("");
            tempArray.push(urlValue);
        } else {
            tempArray.push(true);
        }
        if (vendorData.instagram) {
            let urlValue = regex.test(vendorData.instagram);

            !urlValue ? setInstaErr("instagram") : setInstaErr("");

            tempArray.push(urlValue);
        } else {
            tempArray.push(true);
        }
        if (vendorData.youtube) {
            let urlValue = regex.test(vendorData.youtube);
            !urlValue ? setYoutubeErr("youtube") : setYoutubeErr("");

            tempArray.push(urlValue);
        } else {
            tempArray.push(true);
        }
        if (vendorData.tiktok) {
            let urlValue = regex.test(vendorData.tiktok);
            !urlValue ? setTikTokErr("tiktok") : setTikTokErr("");
            tempArray.push(urlValue);
        } else {
            tempArray.push(true);
        }
        return !tempArray.includes(false);
    };

    // selet and set vendor cover photo and convert into base64
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handelProfilePhoto = async (e) => {
        // for vendor profile picture
        let file = e.target.files[0];
        let base64 = await convertToBase64(file);
        if (base64) {
            let base64Split = base64.split(";");
            let splitExtension = base64Split[0].split("/");
            if (
                splitExtension[1].toLowerCase() === "png" ||
                splitExtension[1].toLowerCase() === "jpg" ||
                splitExtension[1].toLowerCase() === "jpeg"
            ) {
                setProfilePicture(base64);
                setIsUnsaved(true);
            } else {
                toast.warn("The accepted file formats are: jpeg, png, or jpg.");
            }
        }
    };

    const handelCoverPhoto = async (e) => {
        // for vendor cover photo
        let file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const img = new Image();
            img.onload = async () => {
                const width = img.width;
                const height = img.height;

                if (width >= 900 && height >= 400) {
                    let base64 = await convertToBase64(file);
                    if (base64) {
                        let base64Split = base64.split(";");
                        let splitExtension = base64Split[0].split("/");
                        if (
                            splitExtension[1].toLowerCase() === "png" ||
                            splitExtension[1].toLowerCase() === "jpg" ||
                            splitExtension[1].toLowerCase() === "jpeg"
                        ) {
                            setCoverPhoto(base64);
                            setIsUnsaved(true);
                        } else {
                            toast.warn("The accepted file formats are: jpeg, png, or jpg.");
                        }
                    }
                } else {
                    toast.warn("Please upload an image with dimensions 900x400.");
                }
            };
            img.src = event.target.result;
        };
        reader.readAsDataURL(file);
    };

    const handleVendorPhotos = async (e) => {
        // for vendor multi photos
        let files = Array.prototype.slice.call(e.target.files);
        const newArr = vendor_photos.filter((x) => {
            return x.type === "image";
        });
        let fileCount = newArr.length + files.length + selectedPhotos.length;

        if (fileCount <= 10) {
            const fileArr = [...selectedPhotos];
            const tempUrl = [...photos];

            for (let file of files) {
                let type = file.type;
                let splitExtension = type.split("/");
                if (
                    splitExtension[1].toLowerCase() === "png" ||
                    splitExtension[1].toLowerCase() === "jpg" ||
                    splitExtension[1].toLowerCase() === "jpeg"
                ) {
                    let obj = {
                        type: "image",
                        file: file,
                        size: (file?.size) ? file.size : 0, // bytes
                    };
                    fileArr.push(obj);
                    setSelectedPhotos(fileArr);
                    let url = URL.createObjectURL(obj.file);

                    tempUrl.push(url);
                    setPhotos(tempUrl);
                    setPromoPhotoCount(tempUrl.length);
                    setIsUnsaved(true);
                } else {
                    toast.warn("The accepted file formats are: jpeg, png, or jpg.");
                }
            }
        } else {
            toast.warn("You can't submit more than 10 photos.");
        }
    };

    const deleteImage = (photo, index) => {
        // delete image
        let temp = [...vendor_photos];
        let deleteImg = [...delete_photos];
        let tempSelect = [...selectedPhotos];
        temp.splice(index, 1);
        tempSelect.splice(index, 1);
        setVendorPhotos(temp);
        setSelectedPhotos(tempSelect);
        setPromoPhotoCount(promoPhotoCount - 1);
        if (photo.id) {
            deleteImg.push(photo.id);
            setDeletePhotos(deleteImg);
            setIsUnsaved(true);
        }
    };

    const deleteCurrentImage = (index) => {
        // remove selected image
        let temp = [...selectedPhotos];
        temp.splice(index, 1);
        setSelectedPhotos(temp);
        let newArr = photos.filter((id, idx) => idx !== index);
        setPhotos(newArr);
        setPromoPhotoCount(promoPhotoCount - 1);
    };

    const handleVendorVideos = async (e) => {
        // for vendor videos
        let files = Array.prototype.slice.call(e.target.files);
        const newArr = vendor_photos.filter((x) => {
            return x.type === "video";
        });
        let fileCount = newArr.length + videos.length;
        const videoArr = [...selectedVideos];
        const tempUrl = [...videos];
        for (let video of files) {
            const file = video;
            let type = video.type;
            let extension = file.name.split(".").pop().toLowerCase();
            // check video extension
            if (extension === "mp4" || extension === "mov") {
                // check video count
                if (fileCount >= 2) {
                    toast.warn("You can't submit more than 2 videos.");
                } else {
                    setLoadingFile(true);
                    const videoI = document.createElement("video");
                    const reader = new FileReader();
                    if (videoI) {
                        reader.addEventListener("load", async function () {
                            videoI.src = reader.result;
                            const reelDuration = await getVideoDuration(file);
                            // videoI.addEventListener('loadedmetadata', async function () {
                            //check videos duration
                            if (reelDuration <= 300) {
                                let obj = {
                                    type: "video",
                                    file: file,
                                    size: (file?.size) ? file.size : 0, // bytes
                                };
                                videoArr.push(obj);
                                setSelectedVideos(videoArr);
                                let url = URL.createObjectURL(obj.file);

                                tempUrl.push({ url: url });
                                setVideos(tempUrl);
                                setPromoVideoCount(promoVideoCount + 1);
                                setLoadingFile(false);
                                setIsUnsaved(true);
                            } else {
                                toast.warn("Video duration should be a maximum of 5 minutes.");
                                setLoadingFile(false);
                            }
                            // });
                        });
                    }
                    // for checking uploadding persentage
                    reader.addEventListener("progress", function (event) {
                        if (event.lengthComputable) {
                            setLoadingVideoFile(true);
                            const percentLoaded = Math.round((event.loaded / event.total) * 100);
                            setVideoProgress(`${percentLoaded}% complete`);
                            if (percentLoaded === 100) {
                                setTimeout(() => {     
                                    setLoadingVideoFile(false);
                                }, 1000);
                            }
                        }
                    });
                    reader.readAsDataURL(file);
                }
            } else {
                toast.warn("The accepted video formats are: mp4.");
            }
        }
    };

    const deleteVideo = (photo, index) => {
        // delete image
        let temp = [...vendor_photos];
        if (temp) {
            temp.splice(index, 1);
        }
        let deleteImg = [...delete_photos];
        let tempSelect = [...selectedVideos];
        tempSelect.splice(index, 1);
        setVendorPhotos(temp);
        setSelectedVideos(tempSelect);
        if (photo.id) {
            deleteImg.push(photo.id);
            setDeletePhotos(deleteImg);
            setIsUnsaved(true);
        }
    };

    const deleteCurrentVideo = (url, index) => {
        const tempSelectedVideos = [...selectedVideos];

        tempSelectedVideos.splice(index, 1);
        setSelectedVideos(tempSelectedVideos);

        const videoIndexToDelete = videos.findIndex((video) => video.url === url);

        if (videoIndexToDelete !== -1) {
            const tempVideos = [...videos];
            tempVideos.splice(videoIndexToDelete, 1);
            setVideos(tempVideos);
        }
        setPromoVideoCount(promoVideoCount - 1);
    };

    useEffect(() => {
        setSelectedFile([...selectedPhotos, ...selectedVideos]);
    }, [selectedVideos, selectedPhotos]);

    // get video duration
    const getVideoDuration = async (file) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement("video");
            video.preload = "metadata";
            video.onloadedmetadata = function () {
                resolve(video.duration);
            };
            video.onerror = function () {
                reject("Error occurred while loading the video.");
            };
            video.src = URL.createObjectURL(file);
        });
    };

    // function to update reel and get there duration or extension
    const handleReels = async (e, type) => {
        let reel = e.target.files[0];
        let reelExtension = reel.type;
        let splitExtension = reelExtension.split("/");
        let extension = reel.name.split(".").pop().toLowerCase();
        // check video extension
        if (extension === "mp4" || extension === "mov") {
            const videoI = document.createElement("video");
            const reader = new FileReader();
            if (videoI) {
                reader.addEventListener("load", async function () {
                    videoI.src = reader.result;
                    const reelDuration = await getVideoDuration(reel);
                    //   videoI.addEventListener('durationchange', function () {
                    if (reelDuration <= 242) {
                        let reelsArr = [...reels];
                        let obj = {
                            type: type,
                            file: reel,
                            size: (reel?.size) ? reel.size : 0,
                        };
                        let url = URL.createObjectURL(obj.file);
                        let newReel = {
                            type: type,
                            file: url,
                        };

                        if (obj.type === "landscape") {
                            setLandscapeReel([newReel]);
                        } else {
                            setPortraitReel([newReel]);
                        }

                        reelsArr.push(obj);
                        setReels(reelsArr);
                        setIsUnsaved(true);
                    } else {
                        toast.warn("Reel duration should be a maximum of 4 minutes.");
                    }
                    //   });

                    videoI.addEventListener("error", function (e) {
                        console.log("Error occurred:", e);
                    });
                });
                // for checking uploadding persentage
                reader.addEventListener("progress", function (event) {
                    if (event.lengthComputable) {

                      const percentLoaded = Math.round((event.loaded / event.total) * 100);
                      if (type === "landscape") {
                        setLoadingLandReelFile(true);
                        setLandscapeReelProgress(`${percentLoaded}% complete`);
                      } else {
                        setLoadingPotrateReelFile(true);
                        setPortraitReelProgress(`${percentLoaded}% complete`);
                      }
            
                      if (percentLoaded === 100) {
                        // Loading is complete, set loading to false
                        if (type === "landscape") {
                            setTimeout(() => {     
                                setLoadingLandReelFile(false);
                            }, 1000);
                        } else {
                            setTimeout(() => {     
                                setLoadingPotrateReelFile(false);
                            }, 1000);
                        }
                      }
                    }
                });
                reader.readAsDataURL(reel);
            }
        } else {
            toast.warn("The reel format must be mp4 or mov.");
        }
    };

    // function to remove or delete reel
    const deleteCurrentReel = (type, reel) => {
        let temp = {};
        if (type === "landscape") {
            setLandscapeReel("");
            let tempArr = [...reels];
            let tempIndex = tempArr.findIndex((item) => {
                return item.type === type;
            });
            tempArr.splice(tempIndex, 1);
            setReels(tempArr);
            temp = { ...reel, type: type };
            temp?.id && setDeletedReels([...deletedReels, temp]);
        }
        if (type === "portrait") {
            setPortraitReel("");
            let tempArr = [...reels];
            let tempIndex = tempArr.findIndex((item) => {
                return item.type === type;
            });
            tempArr.splice(tempIndex, 1);
            setReels(tempArr);
            temp = { ...reel, type: type };
            temp?.id && setDeletedReels([...deletedReels, temp]);
        }
        setIsUnsaved(true);
    };

    const handleChange = (e) => {
        let tempVendorData = { ...vendorData };
        tempVendorData[e.target.name] = e.target.value;
        if (tempVendorData[e.target.name].length <= 4000) {
            setVendorData(tempVendorData);
            setIsUnsaved(true);
        }
    };

    const handleChangeDropdown = (e) => {
        setSelectedRegions(e);
        setIsUnsaved(true);
    };

    // validate proper rejex for websites url
    const handleChangeURl = (e, index) => {
        e.preventDefault();
        var regexUrl =
            /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?$/;
        let instaRegex =
            /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/gim;
        let facebookRegex =
            /^(?:https?:\/\/)?(?:www\.)?[Ff]acebook\.com\/(?:[^\s\/]+\/\bpages\b\/|[^\/\s]+\/\b(?:profile|pg)\b\/)?(?:[^\s\/]+)$/;
        let youtubeRegex =
            /^(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|channel\/|user\/|c\/|playlist\?list=|@)?([\w-]+)$/;
        let tiktokRegex =
            /^(?:https?:\/\/)?(?:www\.)?tiktok\.com\/(?:@(?:[a-zA-Z0-9_]+)|v(?:\/\w+)?\/[a-zA-Z0-9_]+|share\/(?:video|music)\/\d+)$/;

        const tempUrl = [...vendorLinks];
        const inputValue = e.target.value;

        let errorMessages = { ...socialError };

        if (tempUrl[index].type === "website") {
            tempUrl[index].links = inputValue;
            setVendorLinks(tempUrl);

            if (inputValue && !regexUrl.test(inputValue)) {
                errorMessages[index] = "Please enter a valid website URL";
            } else {
                delete errorMessages[index];
            }
        } else if (tempUrl[index].type === "instagram") {
            tempUrl[index].links = inputValue;
            setVendorLinks(tempUrl);

            if (inputValue && !instaRegex.test(inputValue)) {
                errorMessages[index] = "Please enter a valid Instagram URL";
            } else {
                delete errorMessages[index];
            }
        } else if (tempUrl[index].type === "facebook") {
            tempUrl[index].links = inputValue;
            setVendorLinks(tempUrl);

            if (inputValue && !facebookRegex.test(inputValue)) {
                errorMessages[index] = "Please enter a valid Facebook URL";
            } else {
                delete errorMessages[index];
            }
        } else if (tempUrl[index].type === "youtube") {
            tempUrl[index].links = inputValue;
            setVendorLinks(tempUrl);

            if (inputValue && !youtubeRegex.test(inputValue)) {
                errorMessages[index] = "Please enter a valid YouTube URL";
            } else {
                delete errorMessages[index];
            }
        } else if (tempUrl[index].type === "tiktok") {
            tempUrl[index].links = inputValue;
            setVendorLinks(tempUrl);

            if (inputValue && !tiktokRegex.test(inputValue)) {
                errorMessages[index] = "Please enter a valid TikTok URL";
            } else {
                delete errorMessages[index];
            }
        }

        setSocialError(errorMessages);
        setIsUnsaved(true);
    };

    // function to validate price
    const handleChangePrice = (e) => {
        let tempVendorData = { ...vendorData };
        tempVendorData[e.target.name] = e.target.value.replace(/[^0-9]/g, "");
        setVendorData(tempVendorData);
        setIsUnsaved(true);
    };

    // function to validate business name
    const handleBusinessName = (e) => {
        let tempVendorData = { ...vendorData };
        tempVendorData[e.target.name] = e.target.value.replace(
            /[^a-zA-Z0-9 ']/g,
            ""
        );
        setVendorData(tempVendorData);
        setIsUnsaved(true);
    };

    const handleReelDescription = (e) => {
        let tempVendorData = { ...vendorData };
        tempVendorData[e.target.name] = e.target.value;
        if (tempVendorData[e.target.name].length <= 300) {
            setVendorData(tempVendorData);
            setIsUnsaved(true);
        }
    };

    useEffect(() => {
        const promoVideo = vendor_photos.filter((item) => item.type === "video");
        if (videos.length > 0) {
            setPromoVideoCount(videos.length);
        } else {
            setPromoVideoCount(promoVideo.length);
        }
    }, [vendor_photos]);
    return (
        <>
            {loading ? (
                <div className={"loader-item"}>
                    <Loader
                        type="spinner-circle"
                        bgColor={"#FFFFFF"}
                        color={"#FFFFFF"}
                        size={150}
                    />
                </div>
            ) : (
                <>
                    <div className="wrapper">
                        <VendorHeader />
                        <section className="sidebar-content-wr consumer-block">
                            <div className="container">
                                {vendorInfo.step === 0 ? <VendorSidebar /> : ""}
                                <div className="right-content-block">
                                    <form onSubmit={handleUpdateVendor}>
                                        {vendorInfo.step === 1 &&
                                        <>
                                            <a href="#" className="registered-vendor-btn fadeInUp wow ">
                                                <i className="fa fa-arrow-left"></i>
                                                <h3 className="">You need to setup your profile</h3>
                                            </a>
                                            
                                            <div className="top-btn">
                                                {(vendorInfo?.updated_at !== null) &&
                                                    // <Link className="btn btn-profile" to={isUnsaved && vendorInfo.step === 1 ? undefined : '/vendor-preview'} onClick={isUnsaved && vendorInfo.step === 1 ? () => ( isIos ? handlePageChange('/vendor-preview') : window.location.href = '/vendor-preview') : undefined}>
                                                    //     Preview Profile
                                                    // </Link>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-profile"
                                                        disabled={Object.keys(socialError).length > 0}
                                                        onClick={() => (isUnsaved && vendorInfo.step === 1) ? setActionType('preview') : previewRedirect()}
                                                    >
                                                        Preview Profile
                                                    </button>
                                                }
                                                
                                                <button
                                                    type="submit"
                                                    className="btn btn-profile"
                                                    disabled={Object.keys(socialError).length > 0}
                                                    onClick={() => setActionType('draft')}
                                                >
                                                    Save as Draft
                                                </button>
                                            </div>
                                        </>}
                                        <div className="recentSection">
                                            <div className="upload-profile-section">
                                                <div className="row">
                                                    <div className="col-md-2 col-12">
                                                        <div className="avatar-section">
                                                            <h4 className="common-label">Avatar</h4>
                                                            <div className="avatar-upload-sec">
                                                                <span>
                                                                    <img
                                                                        className="uploaded-img"
                                                                        src={
                                                                            profilePicture
                                                                                ? profilePicture
                                                                                : vendorInfo?.profile
                                                                                    ? vendorInfo?.profile
                                                                                    : ""
                                                                        }
                                                                        alt=""
                                                                    />
                                                                    <img
                                                                        src="./img/Download.svg"
                                                                        className="download-icon"
                                                                        alt=""
                                                                    />
                                                                </span>
                                                                <input
                                                                    type="file"
                                                                    name="profilePicture"
                                                                    accept=".jpeg,.png,.jpg,.gif,.svg"
                                                                    onChange={(e) => handelProfilePhoto(e)}
                                                                />
                                                            </div>
                                                            <p>Square format is recommended (png, jpeg or jpg)</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-10 col-12">
                                                        <div className="profile-cover-section">
                                                            <h4 className="common-label">Profile cover</h4>
                                                            <div className="avatar-upload-sec profile-avtar-sec">
                                                                <span className="profile-avtar-upload">
                                                                    <img
                                                                        className="cover-photo-section"
                                                                        src={
                                                                            cover_photo
                                                                                ? cover_photo
                                                                                : vendorInfo?.user_information
                                                                                    ?.cover_photo
                                                                                    ? vendorInfo?.user_information
                                                                                        ?.cover_photo
                                                                                    : "./img/default-cover.png"
                                                                        }
                                                                        alt=""
                                                                    />
                                                                    <div className="upload-icon">
                                                                        <img src={"./img/Download.svg"} alt="" />
                                                                        <span>Upload photo</span>
                                                                    </div>
                                                                </span>
                                                                <input
                                                                    type="file"
                                                                    accept=".jpeg,.png,.jpg,.gif,.svg"
                                                                    name="cover_photo"
                                                                    onChange={(e) => handelCoverPhoto(e)}
                                                                />
                                                            </div>
                                                            <p>
                                                                Recommended dimensions: 900 by 400 pixels (png,
                                                                jpeg, jpg)
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="name-sec fadeInUp wow ">
                                                        <div className="form-group">
                                                            <label className="common-label" htmlFor="">
                                                                First Name
                                                                <span className="text-danger star-required">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="first_name"
                                                                className="form-control"
                                                                placeholder="Enter First Name"
                                                                value={vendorData.first_name || ""}
                                                                onChange={handleChange}
                                                                readOnly
                                                            />
                                                            {error && !vendorData.first_name && (
                                                                <span
                                                                    style={{ color: "red" }}
                                                                    className="invalidInput"
                                                                >
                                                                    Required*
                                                                </span>
                                                            )}
                                                            <span className="small color">
                                                                Names cannot be changed once you’ve created your
                                                                profile.
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="name-sec  fadeInUp wow ">
                                                        <div className="form-group">
                                                            <label className="common-label" htmlFor="">
                                                                Last Name
                                                                <span className="text-danger star-required">
                                                                    *
                                                                </span>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="last_name"
                                                                className="form-control"
                                                                placeholder="Enter Last Name"
                                                                value={vendorData.last_name || ""}
                                                                onChange={handleChange}
                                                                readOnly
                                                            />
                                                            {error && !vendorData.last_name && (
                                                                <span
                                                                    style={{ color: "red" }}
                                                                    className="invalidInput"
                                                                >
                                                                    Required*
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="name-sec  fadeInUp wow ">
                                                <div className="form-group">
                                                    <label className="common-label" htmlFor="">
                                                        Business Name
                                                        <span className="text-danger star-required">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        name="business_name"
                                                        className="form-control"
                                                        placeholder="Business Name / Company Name"
                                                        value={vendorData.business_name || ""}
                                                        onChange={handleBusinessName}
                                                        readOnly={profileStep === 0}
                                                    />
                                                    {error && !vendorData.business_name && (
                                                        <span
                                                            ref={
                                                                error && !vendorData.business_name
                                                                    ? containerRef
                                                                    : null
                                                            }
                                                            style={{ color: "red" }}
                                                            className="invalidInput"
                                                        >
                                                            Required*
                                                        </span>
                                                    )}
                                                    <span className="small color">
                                                        Business name cannot be changed once you’ve published
                                                        your profile.
                                                    </span>
                                                </div>
                                            </div>
                                            
                                            <div className="desc-sec fadeInUp wow ">
                                                <div className="form-group">
                                                    <label className="common-label" htmlFor="">
                                                        Description
                                                        <span className="text-danger star-required">*</span>{" "}
                                                        <span className="small float-end">
                                                            {vendorData?.vendor_description?.length}/4000
                                                        </span>
                                                    </label>
                                                    <textarea
                                                        row="4"
                                                        className="form-control"
                                                        name="vendor_description"
                                                        placeholder="..."
                                                        value={vendorData.vendor_description || ""}
                                                        onChange={handleChange}
                                                    >
                                                        {" "}
                                                    </textarea>
                                                    {error && !vendorData.vendor_description && (
                                                        <span
                                                            ref={
                                                                error && !vendorData.vendor_description
                                                                    ? difContainerRef
                                                                    : null
                                                            }
                                                            style={{ color: "red" }}
                                                            className="invalidInput"
                                                        >
                                                            Required*
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="price-sec fadeInUp wow ">
                                                <div className="form-group">
                                                    <label className="common-label" htmlFor="">
                                                        Starting price{" "}
                                                        <span className="float-end">Optional</span>
                                                    </label>
                                                    <div className="startingPrice">
                                                        <span>$</span>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="starting_price"
                                                            value={vendorData.starting_price || ""}
                                                            onChange={handleChangePrice}
                                                        />
                                                    </div>
                                                    <span className="small color">
                                                        To be used as a guide only. Leave blank if you don’t
                                                        want your starting price displayed.
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="location-section fadeInUp wow ">
                                                <div className="form-group">
                                                    <label className="common-label" htmlFor="">
                                                        Locations
                                                        <span className="text-danger star-required">*</span>{" "}
                                                        <span className="float-end">
                                                            {selectedRegions?.length}/2
                                                        </span>
                                                    </label>
                                                    <div className="location">
                                                        <Select
                                                            options={regionList.map((data, index) => {
                                                                return {
                                                                    label: data.name,
                                                                    value: data.id,
                                                                };
                                                            })}
                                                            value={selectedRegions}
                                                            onChange={handleChangeDropdown}
                                                            isMulti
                                                        />
                                                        {error && selectedRegions.length <= 0 && (
                                                            <span
                                                                ref={
                                                                    error && !selectedRegions.length
                                                                        ? locationContainerRef
                                                                        : null
                                                                }
                                                                style={{ color: "red" }}
                                                                className="invalidInput"
                                                            >
                                                                Required*
                                                            </span>
                                                        )}
                                                    </div>
                                                    <span className="small color">
                                                        Please select the locations your business operates
                                                        in.
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="event-sec fadeInUp wow">
                                                <div className="form-group">
                                                    <h4 className="common-label">
                                                        EVENT TYPE
                                                        <span className="text-danger star-required">*</span>
                                                    </h4>
                                                    <ul>
                                                        {eventTypeList.map((x, i) => {
                                                            return (
                                                                <ul key={x.id}>
                                                                    <li>
                                                                        <div className="checkboxbtn cursor-pointer" 
                                                                            onClick={() => {
                                                                                checkEvent();
                                                                                eventTypeClick(x.id);
                                                                            }}>
                                                                            <input
                                                                                type="checkbox"
                                                                                id={`checkbox${x.id}`}
                                                                                onChange={() => {
                                                                                    checkEvent();
                                                                                    eventTypeClick(x.id);
                                                                                }}
                                                                                checked={
                                                                                    vendor_event_type.find(
                                                                                        (ele) => ele.event_type === x.id
                                                                                    )
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                value={vendor_event_type}
                                                                            />
                                                                            <label
                                                                                htmlFor={`checkbox${x.id}`}
                                                                                className="checkbox-label"
                                                                            >
                                                                                {x.event_type}
                                                                            </label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            );
                                                        })}
                                                    </ul>
                                                    {error && vendor_event_type.length <= 0 && (
                                                        <span
                                                            ref={
                                                                error && !vendor_event_type.length
                                                                    ? eventTypeContainerRef
                                                                    : null
                                                            }
                                                            style={{ color: "red" }}
                                                            className="invalidInput"
                                                        >
                                                            Required*
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="event-categories checkboxbtn fadeInUp wow ">
                                                <div className="event-payment-btn">
                                                    <h4 className="common-label">
                                                        VENDOR CATEGORIES
                                                        <span className="text-danger star-required">*</span>
                                                    </h4>
                                                    {/* <h4>{showPayment ? 'hello' : 'hi'}</h4> */}
                                                    {/* {(showPayment && profileStep === 0) ? <div className="accordion-btn-for-update">
                                                        <button className="btn" onClick={handleCategoryPlan}>Pay  $7.99 monthly to add 1 more category</button>
                                                    </div> : <></>} */}
                                                </div>
                                                <span className="small color">
                                                Select the service that your business provides. <br/>
                                                Note: You can only select one category. The category cannot be changed after your profile has been published.
                                                </span>
                                                <div className="categories-accordion">
                                                    <div className="accordion" id="accordionExample">
                                                        {categoryList.map((x, i) => {
                                                            let extraInfoCheck1 = [];
                                                            let extraInfoCheck12 = [];
                                                            let extraInfoMin = "";
                                                            let extraInfoMax = "";
                                                            let eIndex1 = cat.findIndex(
                                                                (x) => x.category_id === 1
                                                            );
                                                            let eIndex12 = cat.findIndex(
                                                                (x) => x.category_id === 12
                                                            );
                                                            if (
                                                                cat[eIndex1]?.extra_info &&
                                                                Array.isArray(cat[eIndex1].extra_info) &&
                                                                cat[eIndex1].extra_info.length > 0
                                                            ) {
                                                                extraInfoCheck1 = cat[eIndex1].extra_info;
                                                                extraInfoMin = cat[eIndex1].min;
                                                                extraInfoMax = cat[eIndex1].max;
                                                            }
                                                            if (
                                                                cat[eIndex12]?.extra_info &&
                                                                Array.isArray(cat[eIndex12].extra_info) &&
                                                                cat[eIndex12].extra_info.length > 0
                                                            ) {
                                                                extraInfoCheck12 = cat[eIndex12].extra_info;
                                                            }

                                                            let categoryCheckedRes = cat.find(
                                                                (ele) => ele.category_id === x.id
                                                            );
                                                            let disabledCat = selectDisabledCat.find(
                                                                (ele) => ele.category_id === x.id
                                                            );
                                                            return (
                                                                <>
                                                                    <div className="accordion-item " key={i}>
                                                                        <h2
                                                                            className="accordion-header"
                                                                            id="headingOne"
                                                                        >
                                                                            <button
                                                                                className="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target={"#collapse" + x.id}
                                                                                aria-expanded="true"
                                                                                aria-controls="collapseOne"
                                                                                // onClick={(e) => {
                                                                                //     if (!disabledCat) {
                                                                                //         handleSelectCategoryId(
                                                                                //             e,
                                                                                //             x.id,
                                                                                //             categoryCheckedRes
                                                                                //         );
                                                                                //     }
                                                                                // }}
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        categoryCheckedRes &&
                                                                                            categoryCheckedRes?.subcategory &&
                                                                                            categoryCheckedRes?.subcategory
                                                                                                .length < x.sub_category.length
                                                                                            ? "check-unfilled"
                                                                                            : categoryCheckedRes &&
                                                                                                categoryCheckedRes?.subcategory &&
                                                                                                categoryCheckedRes?.subcategory
                                                                                                    .length ==
                                                                                                x.sub_category.length
                                                                                                ? "check-filled"
                                                                                                : "check-unfilled"
                                                                                    }
                                                                                    onClick={(e) => {
                                                                                        if (!disabledCat) {
                                                                                            handleSelectCategoryId(
                                                                                                e,
                                                                                                x.id,
                                                                                                categoryCheckedRes
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={"checkbox" + x.id}
                                                                                        checked={
                                                                                            categoryCheckedRes ? true : false
                                                                                        }
                                                                                        value={cat}
                                                                                        onChange={(e) => {
                                                                                            // handle the change event here
                                                                                            if (!disabledCat) {
                                                                                                handleSelectCategoryId(
                                                                                                    e,
                                                                                                    x.id,
                                                                                                    categoryCheckedRes
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                        disabled={
                                                                                            categoryCheckedRes ? true : false
                                                                                        }
                                                                                    />
                                                                                    <label
                                                                                        className="checkbox-label"
                                                                                        onClick={(e) => {
                                                                                            if (!disabledCat) {
                                                                                                handleSelectCategoryId(
                                                                                                    e,
                                                                                                    x.id,
                                                                                                    categoryCheckedRes
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {x.category_name}
                                                                                    </label>
                                                                                </div>
                                                                            </button>
                                                                        </h2>

                                                                        <div
                                                                            id={"collapse" + x.id}
                                                                            className="accordion-collapse collapse"
                                                                            aria-labelledby="headingOne"
                                                                            data-bs-parent="#accordionExample"
                                                                        >
                                                                            <div className="accordion-body">
                                                                                <ul>
                                                                                    {x.sub_category.map((y, i) => {
                                                                                        let tempArr = [];
                                                                                        let sub = cat.map((x) => {
                                                                                            if (x.subcategory) {
                                                                                                tempArr = [
                                                                                                    ...tempArr,
                                                                                                    ...x.subcategory,
                                                                                                ];
                                                                                            }
                                                                                        });
                                                                                        return (
                                                                                            <>
                                                                                                <li
                                                                                                    className="cursor-pointer"
                                                                                                    key={i}
                                                                                                    onClick={() => {
                                                                                                        // if(!disabledCat){
                                                                                                        handleSelectSubCategory(
                                                                                                            x.id,
                                                                                                            y.id
                                                                                                        );
                                                                                                        // }
                                                                                                    }}
                                                                                                >
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        className="subcategory_check"
                                                                                                        value={y.id}
                                                                                                        id={"subcategory" + y.id}
                                                                                                        checked={tempArr.includes(
                                                                                                            y.id
                                                                                                        )}
                                                                                                        onChange={(e) => {
                                                                                                            // handle the change event here
                                                                                                        }}
                                                                                                    />
                                                                                                    <label
                                                                                                        htmlFor={
                                                                                                            "subcategory" + y.id
                                                                                                        }
                                                                                                        className="checkbox-label"
                                                                                                    >
                                                                                                        {y.subcategory_name}
                                                                                                    </label>
                                                                                                </li>
                                                                                            </>
                                                                                        );
                                                                                    })}
                                                                                </ul>
                                                                                {x.id === 1 ? (
                                                                                    <div className="extra-info fadeInUp wow ">
                                                                                        <h4 className="common-label text-center">
                                                                                            Extra INFO FOR{" "}
                                                                                            <span className="blue-heading">
                                                                                                VENUE
                                                                                            </span>
                                                                                        </h4>
                                                                                        <hr />
                                                                                        <h4 className="guess-heading">
                                                                                            GUESTS
                                                                                        </h4>
                                                                                        <div className="guess-section">
                                                                                            <div className="min-sec min-max-sec">
                                                                                                <span>min</span>
                                                                                                <input
                                                                                                    className="form-control"
                                                                                                    type="number"
                                                                                                    value={minimum || ''}
                                                                                                    onChange={(e) => {
                                                                                                        handleMinimum(
                                                                                                            e.target.value,
                                                                                                            x.id
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="max-sec min-max-sec">
                                                                                                <span>max</span>
                                                                                                <input
                                                                                                    className="form-control"
                                                                                                    type="number"
                                                                                                    // min={0}
                                                                                                    value={maximum || ''}
                                                                                                    onChange={(e) => {
                                                                                                        handleMaximum(
                                                                                                            e.target.value,
                                                                                                            x.id
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <ul className="guess-checkbox checkboxbtn">
                                                                                            <li>
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="1"
                                                                                                    value="vegan"
                                                                                                    checked={extraInfoCheck1.includes(
                                                                                                        "1"
                                                                                                    )}
                                                                                                    onChange={(e) => {
                                                                                                        handleExtraInfo(
                                                                                                            "1",
                                                                                                            e.target.value,
                                                                                                            x.id
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                                <label className="checkbox-label">
                                                                                                    Vegan
                                                                                                </label>
                                                                                            </li>
                                                                                            <li>
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="2"
                                                                                                    value="vegetarian option"
                                                                                                    checked={extraInfoCheck1.includes(
                                                                                                        "2"
                                                                                                    )}
                                                                                                    onChange={(e) => {
                                                                                                        handleExtraInfo(
                                                                                                            "2",
                                                                                                            e.target.value,
                                                                                                            x.id
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                                <label className="checkbox-label">
                                                                                                    Vegetarian option
                                                                                                </label>
                                                                                            </li>
                                                                                            <li>
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="3"
                                                                                                    value="child friendly"
                                                                                                    checked={extraInfoCheck1.includes(
                                                                                                        "3"
                                                                                                    )}
                                                                                                    onChange={(e) => {
                                                                                                        handleExtraInfo(
                                                                                                            "3",
                                                                                                            e.target.value,
                                                                                                            x.id
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                                <label className="checkbox-label">
                                                                                                    Child-friendly
                                                                                                </label>
                                                                                            </li>
                                                                                            <li>
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="4"
                                                                                                    value="wheelchair access"
                                                                                                    checked={extraInfoCheck1.includes(
                                                                                                        "4"
                                                                                                    )}
                                                                                                    onChange={(e) => {
                                                                                                        handleExtraInfo(
                                                                                                            "4",
                                                                                                            e.target.value,
                                                                                                            x.id
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                                <label className="checkbox-label">
                                                                                                    Wheelchair access
                                                                                                </label>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                                {x.id === 12 ? (
                                                                                    <div className="catering-sec fadeInUp wow ">
                                                                                        <h4 className="common-label text-center">
                                                                                            EXTRA INFO FOR{" "}
                                                                                            <span className="blue-heading">
                                                                                                Catering
                                                                                            </span>
                                                                                        </h4>
                                                                                        <hr />
                                                                                        <ul className="checkboxbtn">
                                                                                            <li>
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="1"
                                                                                                    value="vegan"
                                                                                                    checked={extraInfoCheck12.includes(
                                                                                                        "1"
                                                                                                    )}
                                                                                                    onChange={(e) => {
                                                                                                        handleExtraInfo(
                                                                                                            "1",
                                                                                                            e.target.value,
                                                                                                            x.id
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                                <label className="checkbox-label">
                                                                                                    Vegan
                                                                                                </label>
                                                                                            </li>
                                                                                            <li>
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="2"
                                                                                                    value="vegetarian option"
                                                                                                    checked={extraInfoCheck12.includes(
                                                                                                        "2"
                                                                                                    )}
                                                                                                    onChange={(e) => {
                                                                                                        handleExtraInfo(
                                                                                                            "2",
                                                                                                            e.target.value,
                                                                                                            x.id
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                                <label className="checkbox-label">
                                                                                                    Vegetarian option
                                                                                                </label>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })}
                                                        {error && !cat.length > 0 && (
                                                            <span
                                                                ref={
                                                                    error && !cat.length ? catContainerRef : null
                                                                }
                                                                style={{ color: "red" }}
                                                                className="invalidInput"
                                                            >
                                                                Required*
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="service-request">
                                                <h4 className="common-label">
                                                    Photos &nbsp;&nbsp;&nbsp; {promoPhotoCount}/10
                                                </h4>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="profile-cover-section">
                                                            <div className="avatar-upload-sec profile-avtar-sec">
                                                                <span>
                                                                    <p className="mt-2">Click to Upload or</p>
                                                                    <img src="/img/Download.svg" alt="" />
                                                                    <p> Drag & Drop Photos</p>
                                                                </span>
                                                                <input
                                                                    type="file"
                                                                    name="event_photos"
                                                                    accept=".jpeg,.png,.jpg"
                                                                    onChange={(e) => handleVendorPhotos(e)}
                                                                    multiple
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        {/* <output>
                                                            {vendor_photos.length > 0 &&
                                                                vendor_photos.map((photo, index) => {
                                                                    return (<>
                                                                        {(photo.type === 'image') ?
                                                                            <div className="image">
                                                                                {photo.photos ?
                                                                                    <img src={photo.photos} style={{ height: '100%', opacity: 0.7 }} alt="image" /> :
                                                                                    <img src={photo.selectedPhotos} style={{ height: '100%', opacity: 0.7 }} alt="image" />}
                                                                                <span onClick={() => deleteImage(photo, index)} >&times;</span>
                                                                            </div>
                                                                            : ''}
                                                                    </>)
                                                                })}
                                                            {photos.map((photo, index) => {
                                                                return (<>
                                                                    <div className="image">
                                                                        <img src={photo} style={{ height: '100%', opacity: 0.7 }} alt="image" />
                                                                        <span onClick={() => deleteCurrentImage(index)} >&times;</span>
                                                                    </div>
                                                                </>)
                                                            }
                                                            )}
                                                        </output> */}

                                                        <div className="vendor-photography portfolio-photos fadeIn wow">
                                                            {/* {vendor_photos?.length > 0 && ( */}
                                                            <div className="vendor-event-carousel fadeIn wow">
                                                                <OwlCarousel
                                                                    id="owl-carousel-one"
                                                                    className="owl-carousel owl-theme owl-loaded owl-drag"
                                                                    margin={20}
                                                                >
                                                                    {photos.map((photo, index) => {
                                                                        return (
                                                                            <>
                                                                                <div className="image" key={photo.id}>
                                                                                    <span className="portfolio-slider">
                                                                                        <img
                                                                                            src={photo}
                                                                                            style={{
                                                                                                height: "100%",
                                                                                                opacity: 0.7,
                                                                                            }}
                                                                                            alt="viewImage"
                                                                                        />
                                                                                        <i
                                                                                            className="fa fa-close"
                                                                                            onClick={() =>
                                                                                                deleteCurrentImage(index)
                                                                                            }
                                                                                        ></i>
                                                                                        {/* <span onClick={() => deleteCurrentImage(index)} >&times;</span> */}
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        );
                                                                    })}
                                                                    {vendor_photos?.map((photo, index) => {
                                                                        if (photo.type === "image") {
                                                                            return (
                                                                                <>
                                                                                    <div className="item" key={photo.id}>
                                                                                        <span className="portfolio-slider">
                                                                                            <img
                                                                                                src={photo.photos}
                                                                                                alt="vendorimage"
                                                                                            />
                                                                                            <i
                                                                                                className="fa fa-close"
                                                                                                onClick={() =>
                                                                                                    deleteImage(photo, index)
                                                                                                }
                                                                                            ></i>
                                                                                        </span>
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                        }
                                                                    })}
                                                                </OwlCarousel>
                                                            </div>
                                                            {/* )} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="videos-sec fadeInUp wow ">
                                                <div className="form-group">
                                                    <h4
                                                        className="common-label"
                                                        style={{ marginBottom: "0" }}
                                                    >
                                                        Reels
                                                    </h4>
                                                    <span className="small color">
                                                        Your reels are your primary promotional videos on
                                                        both desktop and mobile (up to 3.5 minute each
                                                        video).<br/>
                                                        Note: If you upload one video format only, the same video will be displayed on both desktop and mobile. 
                                                    </span>
                                                    {/* <div className="row">
                                                    
                                                    
                                                    </div> */}

                                                    <div className="row">
                                                        <div className="reels col-md-8 col-12">
                                                        <div className="video-Landscape">
                                                            <h5 style={{ marginTop: "20px" }}>
                                                                Landscape Reel
                                                            </h5>
                                                            <p>
                                                                This reel will be shown to consumers browsing on
                                                                a desktop.
                                                                <br />
                                                                Recommended dimensions, 1920x1080. Accepted
                                                                formats:
                                                                <br />
                                                                .mp4, .mov
                                                            </p>
                                                        </div>
                                                            {landscapeReel.length > 0 ? (
                                                                <div className="uploaded-video-sec reels-section">
                                                                    <span>
                                                                        <video
                                                                            className="video"
                                                                            controls
                                                                            controlsList="nodownload noplaybackrate"
                                                                            disablePictureInPicture
                                                                        >
                                                                            <source
                                                                                src={
                                                                                    landscapeReel[0].file
                                                                                        ? landscapeReel[0].file
                                                                                        : landscapeReel
                                                                                            ? landscapeReel
                                                                                            : ""
                                                                                }
                                                                                style={{ height: "100%", opacity: 0.7 }}
                                                                                alt="video"
                                                                                type="video/mp4"
                                                                            />
                                                                        </video>
                                                                        <span
                                                                            onClick={() =>
                                                                                deleteCurrentReel(
                                                                                    "landscape",
                                                                                    landscapeReel[0]
                                                                                )
                                                                            }
                                                                        >
                                                                            <i
                                                                                className="fa fa-times  fa-1"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div className="uploaded-video-sec">
                                                                    {loadingLandReelFile ? (
                                                                        <div>
                                                                            <span>
                                                                                <img src="./img/ajax-loader.gif" alt="" />
                                                                            <span className="progress">{landscapeReelProgress}</span>
                                                                            </span>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <span>+</span>
                                                                            <input
                                                                                type="file"
                                                                                name="vendor_reels"
                                                                                accept=".mp4,.mov"
                                                                                onChange={(e) =>
                                                                                    handleReels(e, "landscape")
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        
                                                        <div className="reels col-md-4 col-12">
                                                        <div className="video-Portrait">
                                                            <h5 style={{ marginTop: "20px" }}>
                                                                Portrait Reel
                                                            </h5>
                                                            <p>
                                                                This reel will be shown to consumers browsing on
                                                                mobile. Recommended dimensions, 1080x1920.
                                                                Accepted formats: .mp4, .mov
                                                            </p>
                                                        </div>
                                                            {portraitReel.length > 0 ? (
                                                                <div className="uploaded-video-sec reels-section">
                                                                    <span>
                                                                        <video
                                                                            className="video"
                                                                            controls
                                                                            controlsList="nodownload noplaybackrate"
                                                                            disablePictureInPicture
                                                                        >
                                                                            {/* <source src={(portraitReel[0]?.file) ? portraitReel[0]?.file : (portraitReel?.file) ? portraitReel?.file : ''} style={{ height: '100%', opacity: 0.7 }} alt="video" type="video/mp4" /> */}
                                                                            <source
                                                                                src={
                                                                                    portraitReel[0]?.file ||
                                                                                    portraitReel?.file
                                                                                }
                                                                                style={{ height: "100%", opacity: 0.7 }}
                                                                                alt="video"
                                                                                type="video/mp4"
                                                                            />
                                                                            <source
                                                                                src={
                                                                                    portraitReel[0]?.file ||
                                                                                    portraitReel?.file
                                                                                }
                                                                                style={{ height: "100%", opacity: 0.7 }}
                                                                                alt="video"
                                                                                type="video/quicktime"
                                                                            />
                                                                        </video>
                                                                        <span
                                                                            onClick={() =>
                                                                                deleteCurrentReel(
                                                                                    "portrait",
                                                                                    portraitReel[0]
                                                                                )
                                                                            }
                                                                        >
                                                                            <i
                                                                                className="fa fa-times  fa-1"
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div className="upload-video-sec">
                                                                    {/* <span>+</span>
                                                                    <input
                                                                        type="file"
                                                                        name="vendor_reels"
                                                                        accept=".mp4,.mov"
                                                                        onChange={(e) => handleReels(e, "portrait")}
                                                                    /> */}
                                                                    {loadingPotrateReelFile ? (
                                                                        <div>
                                                                            <span>
                                                                                <img src="./img/ajax-loader.gif" alt="" />
                                                                                <span className="progress">{portraitReelProgress}</span>
                                                                            </span>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <span>+</span>
                                                                            <input
                                                                                type="file"
                                                                                name="vendor_reels"
                                                                                accept=".mp4,.mov"
                                                                                onChange={(e) =>
                                                                                    handleReels(e, "portrait")
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {error && !reels.length > 0 && (
                                                        <span
                                                            style={{ color: "red" }}
                                                            className="invalidInput"
                                                        >
                                                            Required*
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="desc-sec fadeInUp wow ">
                                                    <div className="form-group">
                                                        <label className="common-label" htmlFor="">
                                                            Reel Description{" "}
                                                            <span className="small">
                                                                {vendorData?.reel_caption?.length}/300
                                                            </span>
                                                        </label>
                                                        <textarea
                                                            row="4"
                                                            className="reel-des form-control"
                                                            placeholder="..."
                                                            name="reel_caption"
                                                            value={vendorData.reel_caption || ""}
                                                            onChange={handleReelDescription}
                                                        >
                                                            {" "}
                                                        </textarea>
                                                        <span className="small color">
                                                            This description will be displayed underneath your desktop reel only.
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="promo-video-sec fadeInUp wow common-label-right">
                                                <div className="row">
                                                    <div className="col-md-8 col-sm-10 col-12">
                                                    <h4 className="common-label">
                                                        PROMO VIDEO <span className="small"> {promoVideoCount} / 2</span>
                                                    <span className="float-end small">OPTIONAL</span></h4>
                                                    <p>
                                                        Upload 2 long format promotional videos (up to 5
                                                        minutes each) to be displayed on your profile only{" "}
                                                        <br />
                                                        <br />
                                                        Landscape format only
                                                    </p>
                                                    </div>    
                                                </div>

                                            
                                                    {/* {!vendor_photos.length > 0 && (!videos.length > 0 && (
                                                        <div className="col-md-5 col-12">
                                                            <div className="upload-video-sec upload-pro-sec video-section">
                                                                <span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))} */}
                                                    {vendor_photos.length > 0 &&
                                                        vendor_photos.map((photo, index) => {
                                                            return (
                                                                <>
                                                                    {photo.type === "video" ? (
                                                                        <div className="row">
                                                                        <div
                                                                            className="col-md-8 col-12 vendor-video mb-3"
                                                                            key={photo.id}
                                                                        >
                                                                            <div className="upload-video-sec upload-pro-sec video-section">
                                                                                <span>
                                                                                    <video
                                                                                        className="video"
                                                                                        id="video-preview"
                                                                                        src={photo.photos}
                                                                                        style={{
                                                                                            height: "100%",
                                                                                            opacity: 0.7,
                                                                                        }}
                                                                                        alt="video"
                                                                                        type="video/mp4"
                                                                                        controls
                                                                                        controlsList="nodownload noplaybackrate"
                                                                                        disablePictureInPicture
                                                                                    >
                                                                                        {/* <source src={photo.photos} style={{ height: '100%', opacity: 0.7 }} alt="video" type="video/mp4" />
                                                                                    <source src={photo.photos} style={{ height: '100%', opacity: 0.7 }} alt="video" type="video/quicktime" /> */}
                                                                                    </video>
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            deleteVideo(photo, index)
                                                                                        }
                                                                                    >
                                                                                        &times;
                                                                                    </span>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </>
                                                            );
                                                        })}
                                                    
                                                    {videos.map((video, index) => {
                                                        return (
                                                            <div className="row">
                                                            <div className="reels col-md-8 col-12 vendor-video">
                                                                {video ? (
                                                                    <div className="upload-video-sec upload-pro-sec video-section">
                                                                        <span>
                                                                            <video
                                                                                className="video"
                                                                                id="video-preview"
                                                                                controls
                                                                                controlsList="nodownload noplaybackrate"
                                                                                disablePictureInPicture
                                                                                src={video.url}
                                                                                style={{
                                                                                    height: "100%",
                                                                                    opacity: 0.7,
                                                                                }}
                                                                                alt="video"
                                                                                type="video/mp4"
                                                                            >
                                                                                {/* <source
                                                                                    src={video.url}
                                                                                    style={{
                                                                                        height: "100%",
                                                                                        opacity: 0.7,
                                                                                    }}
                                                                                    alt="video"
                                                                                    type="video/mp4"
                                                                                    />
                                                                                    <source
                                                                                    src={video.url}
                                                                                    style={{
                                                                                        height: "100%",
                                                                                        opacity: 0.7,
                                                                                    }}
                                                                                    alt="video"
                                                                                    type="video/quicktime"
                                                                                    /> */}
                                                                            </video>
                                                                            <span
                                                                                onClick={() =>
                                                                                    deleteCurrentVideo(
                                                                                        video.url,
                                                                                        index,
                                                                                        video.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                &times;
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                    //   <div className="upload-video-sec upload-pro-sec video_input">
                                                                    //     {loadingFile ? (
                                                                    //       <div>
                                                                    //         <span>
                                                                    //           <img
                                                                    //             src="./img/ajax-loader.gif"
                                                                    //             alt=""
                                                                    //           />
                                                                    //         </span>
                                                                    //       </div>
                                                                    //     ) : (
                                                                    //       <>
                                                                    //         <span>+</span>
                                                                    //         <input
                                                                    //           type="file"
                                                                    //           name="event_photos"
                                                                    //           accept=".mp4,.mov"
                                                                    //           id="video-file-input"
                                                                    //           onChange={(e) =>
                                                                    //             handleVendorVideos(e)
                                                                    //           }
                                                                    //         />
                                                                    //       </>
                                                                    //     )}
                                                                    //   </div>
                                                                )}
                                                            </div>
                                                            </div>
                                                        );
                                                    })}
                                                
                                                    {promoVideoCount < 2 && (
                                                    <div className="row">
                                                        <div className="reels col-md-8 col-12">
                                                            <div className="upload-video-sec upload-pro-sec video_input">
                                                                {loadingVideoFile ? (
                                                                    <div>
                                                                        <span>
                                                                            <img src="./img/ajax-loader.gif" alt="" />
                                                                            <span className="progress">{videoProgress}</span>
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <span>+</span>
                                                                        <input
                                                                            type="file"
                                                                            name="event_photos"
                                                                            accept=".mp4,.mov"
                                                                            id="video-file-input"
                                                                            onChange={(e) => handleVendorVideos(e)}
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                </div>
                                                    )}
                                            </div>

                                            <div className="vendor-links-sec fadeInUp wow ">
                                                <h4 className="common-label">LINKS</h4>
                                                <div className="row">
                                                    {vendorLinks?.map((value, index) => {
                                                        const errorMessage = socialError[index];

                                                        return (
                                                            <div key={index} className="col-md-6 col-12">
                                                                <div className="links-inner-sec">
                                                                    <span className="social-icons">
                                                                        <img src={value.pictureURL} alt="" />
                                                                    </span>

                                                                    <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="website"
                                                                        placeholder={value?.placeholder}
                                                                        value={value?.links || ""}
                                                                        onChange={(e) => handleChangeURl(e, index)}
                                                                    />

                                                                    {error && websiteErr && (
                                                                        <span
                                                                            style={{ color: "red" }}
                                                                            className="invalidInput"
                                                                        >
                                                                            Please enter a valid URL
                                                                        </span>
                                                                    )}

                                                                    {errorMessage && (
                                                                        <span
                                                                            style={{ color: "red" }}
                                                                            className="invalidInput"
                                                                        >
                                                                            {errorMessage}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            <div className="next-btn-sec fadeInUp">
                                                <div className="">
                                                    {loadingForm ? (
                                                        <>
                                                            <div className={"loader-item vendor-update"}>
                                                                <Loader
                                                                    type="spinner-circle"
                                                                    bgColor={"#FFFFFF"}
                                                                    color={"#FFFFFF"}
                                                                    size={150}
                                                                />
                                                                <p>
                                                                    <span id="wordsss">{loaderNew}%</span><br/>
                                                                    Please wait... <br/>
                                                                    Your profile is being {(actionType === 'live') ? 'published' : 'saved'}. <br/>
                                                                    This may take a few minutes.<br/>
                                                                    Please do not refresh or leave this page.
                                                                </p> 
                                                            </div>
                                                            <button type="submit" className="btn" disabled>
                                                                <div className={"btn-loader"}>
                                                                    <img
                                                                        src="/img/loader.svg"
                                                                        className="loader-svg-img"
                                                                    />
                                                                </div>
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {(vendorInfo?.updated_at !== null) ?
                                                                // <Link className="btn btn-profile" to={isUnsaved && vendorInfo.step === 1 ? undefined : '/vendor-preview'} onClick={isUnsaved && vendorInfo.step === 1 ? () => ( isIos ? handlePageChange('/vendor-preview') : window.location.href = '/vendor-preview') : undefined}>
                                                                //     Preview Profile
                                                                // </Link>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-profile"
                                                                    disabled={Object.keys(socialError).length > 0}
                                                                    onClick={() => (isUnsaved && vendorInfo.step === 1) ? setActionType('preview') : previewRedirect()}
                                                                >
                                                                    Preview Profile
                                                                </button>
                                                            : ""}
                                                            
                                                            {(vendorInfo?.step === 1) ?
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-profile"
                                                                    disabled={Object.keys(socialError).length > 0}
                                                                    onClick={() => setActionType('draft')}
                                                                >
                                                                Save as Draft
                                                                </button>
                                                            : ""}

                                                            <Link  className="btn btn-profile" to={isUnsaved && vendorInfo.step === 1 ? undefined : '/vendor-dashbord'} onClick={isUnsaved && vendorInfo.step === 1 ? () => ( isIos ? handlePageChange('/vendor-dashboard') : window.location.href = '/vendor-dashboard') : undefined}>
                                                                Cancel Changes
                                                            </Link>
                                                            
                                                            <button
                                                                type="submit"
                                                                className="btn"
                                                                disabled={Object.keys(socialError).length > 0}
                                                                onClick={() => setActionType('live')}
                                                            >
                                                            Save and Publish
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                                <span className="small color">
                                                    Save as Draft to finish your setup later or Save and Publish to finish your profile now.
                                                </span>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                        <VendorFooter />
                    </div>
                </>
            )}
        </>
    );
};

export default VendorUpdate;
